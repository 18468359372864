import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

import { ReactComponent as SvgReceiptLongFilled } from '@/icons/svg/receipt_long_filled.svg'

export function ReceiptLongFilled(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <SvgReceiptLongFilled />
    </SvgIcon>
  )
}
