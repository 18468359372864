import type { ReactElement, ReactNode } from 'react'
import { useContext, createContext, useMemo } from 'react'

import type { Auth, UserProfile } from '@/hooks/auth'
import { useAuth, useProxy } from '@/hooks/auth'
import { usePermissions } from '@/hooks/graphql'
import { useUserByEmployeeId } from '@/hooks/graphql/page'

// CONTEXT
const AuthContext = createContext<Auth>({} as Auth)
const UserProfileContext = createContext<UserProfile>({} as UserProfile)

export const useAuthContext = () => useContext(AuthContext)
export const useUserProfileContext = () => useContext(UserProfileContext)

// COMPONENT
export function AuthContextProvider({ children }: { children: ReactNode }): ReactElement | null {
  const auth = useAuth()
  // loadingとかでもいいかも
  if (!auth) return null
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export function UserProfileContextProvider({
  children,
}: {
  children: ReactNode
}): ReactElement | null {
  const { loggedIn } = useAuthContext()

  const auth = useAuthContext()
  const proxyStatus = useProxy()
  const permissions = usePermissions(loggedIn)

  const actAsEmployeeId = proxyStatus?.currentProxyUser?.employeeId ?? auth.user?.employeeId
  if (!actAsEmployeeId) throw new Error('actAs no one.')
  const actAs = useUserByEmployeeId(actAsEmployeeId)
  const isRemoteWorkAllowanceTarget = actAs?.isRemoteWorkAllowanceTarget ?? false

  const division = actAs?.division?.name ?? undefined

  const value = useMemo<UserProfile | null>(
    () =>
      !!proxyStatus && !!permissions
        ? {
            division: division,
            proxyStatus: {
              ...proxyStatus,
              currentProxyUser: proxyStatus.currentProxyUser
                ? {
                    ...proxyStatus.currentProxyUser,
                    division: division ?? undefined,
                  }
                : undefined,
            },
            permissions,
            isRemoteWorkAllowanceTarget,
          }
        : null,
    [proxyStatus, permissions, division, isRemoteWorkAllowanceTarget]
  )

  if (!value) return null
  return <UserProfileContext.Provider value={value}>{children}</UserProfileContext.Provider>
}
