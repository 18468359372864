import type { ReactElement, ReactNode } from 'react'
import { useContext, createContext } from 'react'

import { useLocation } from 'react-router-dom'

import type { MogokPagePath } from '@/hooks/useSmartNavigationPathGenerator'
import { MogokPagePathByPathName } from '@/hooks/useSmartNavigationPathGenerator'

interface GlobalState {
  pagePath: MogokPagePath
}
// CONTEXT
const GlobalStateContext = createContext<GlobalState>({} as GlobalState)

export const useGlobalStateContext = () => useContext(GlobalStateContext)

// COMPONENT
export function GlobalStateContextProvider({
  children,
}: {
  children: ReactNode
}): ReactElement | null {
  const location = useLocation()
  const path = location.pathname
  const pagePath = MogokPagePathByPathName(path)
  return <GlobalStateContext.Provider value={{ pagePath }}>{children}</GlobalStateContext.Provider>
}
