import type { ReactNode, ReactElement } from 'react'

import { useLocation } from 'react-router-dom'

import { useUserPolicy } from '@/hooks/userProfile'
import AuthorizationRequired from '@/pages/AuthorizationRequired'

interface UserGuardProps {
  children: ReactNode
}

export function UserGuard({ children }: UserGuardProps): ReactElement | null {
  const { accessible } = useUserPolicy()
  const location = useLocation()

  if (!accessible(location.pathname)) return <AuthorizationRequired />

  return <>{children}</>
}
