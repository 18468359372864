import type { FC } from 'react'

import LogoutIcon from '@mui/icons-material/Logout'
import { Box, Button, Container, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Helmet } from 'react-helmet-async'

import { useSignOut } from '@/hooks/auth'

const ServerError: FC = () => {
  const theme = useTheme()
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'))

  const signOut = useSignOut()
  const handleLogout = async () => {
    await signOut()
  }

  return (
    <>
      <Helmet>
        <title>SAPPHIRE 経費精算 - 401エラー</title>
      </Helmet>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'background.paper',
          display: 'flex',
          minHeight: '100%',
          px: 3,
          py: '80px',
        }}
      >
        <Container maxWidth="lg">
          <Typography align="center" color="textPrimary" variant={mobileDevice ? 'h4' : 'h1'}>
            401: ページを表示する権限がありません。
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6,
            }}
          >
            <Box
              alt="Under development"
              component="img"
              src={`/static/error/error401_general.svg`}
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: 400,
              }}
            />
          </Box>
          <Typography align="center" color="textSecondary" sx={{ mt: 0.5 }} variant="subtitle2">
            あなたのログイン中のアカウントには、このページを表示する権限がありません。
            <br />
            お手数ですが、ログイン情報をご確認ください。
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6,
            }}
          >
            <Button color="primary" onClick={handleLogout} variant="outlined">
              <LogoutIcon sx={{ mr: 2 }} />
              ログアウト
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default ServerError
