import type { ReactElement } from 'react'

import numeral from 'numeral'

import { fromYYYYMMDD, toYYYYMMDD_slash } from '@/utils/date'

export const place = (name: string | null | undefined) => name || '自宅'
export const direction = (dep: string | null | undefined, arr: string | null | undefined) =>
  `${place(dep)} → ${place(arr)}`

export const fare = (fare: number | string | null | undefined) => {
  if (!fare) return `¥ 0`

  const f = typeof fare === 'string' ? parseFloat(fare) : fare
  return `¥ ${f}`
}
export const distance = (distance: string | number | null | undefined) => {
  if (!distance && distance !== 0) return `- km`

  const d = typeof distance === 'string' ? parseFloat(distance) : distance
  return `${distance ? (d / 1000).toFixed(1) : '-'} km`
}
export const period = (start: string, end: string | null | undefined) => {
  const s = toYYYYMMDD_slash(fromYYYYMMDD(start))
  const e = !end || end === '9999-12-31' ? '(未設定)' : toYYYYMMDD_slash(fromYYYYMMDD(end))
  return `${s} 〜 ${e}`
}
export const dateString = (date: string | null | undefined) => {
  return !date || date === '9999-12-31' ? '(未設定)' : toYYYYMMDD_slash(fromYYYYMMDD(date))
}

// -100,000とかも対応してます
export const yen = (amount: number | string | null | undefined) => {
  if (!amount) return `0`
  const amountNum = typeof amount === 'number' ? amount : Number(amount)
  return `${numeral(amountNum).format(`0,0`)}`
}

const REG = /(\r\n|\r|\n)/g
export const nl2br = (str: string | null | undefined): ReactElement | null => {
  if (!str) return null
  return (
    <>
      {/* eslint-disable-next-line react/no-array-index-key */}
      {str.split(REG).map((s, i) => (s.match(REG) ? <br key={i} /> : s))}
    </>
  )
}

export const joinComments = (prev: string | null | undefined, current: string) => {
  if (!current) return prev || ''

  const ymd = toYYYYMMDD_slash(new Date())
  const newLine = `(${ymd}) ${current}`
  return prev ? `${prev}\r\n${newLine}` : newLine
}

export const dateToYearMonth = (date: Date): string => {
  const year = `${date.getFullYear()}`.padStart(4, '0')
  const month = `${date.getMonth() + 1}`.padStart(2, '0')
  return `${year}年${month}月度`
}

export const toYenWithSymbol = (amount: string | number | null | undefined) => {
  if (!amount) return '¥0'
  return `¥${yen(amount)}`
}
