import type { CSSProperties } from 'react'

import type { Direction, PaletteColor, PaletteOptions, Theme, ThemeOptions } from '@mui/material'
import {
  filledInputClasses,
  inputLabelClasses,
  outlinedInputClasses,
  tableCellClasses,
} from '@mui/material'
import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import type { ColorRange } from '@mui/material/styles/createPalette'
import { merge } from 'lodash'

import { main, L_C1, accent, black, heavyBlack, grey, other } from '@/lib/colors'

import { lightShadows } from './shadows'

declare module '@mui/material/styles/createPalette' {
  interface ColorRange {
    50: string
    100: string
    200: string
    300: string
    400: string
    500: string
    600: string
    700: string
    800: string
    900: string
  }

  interface Palette {
    neutral: ColorRange
  }

  interface PaletteColor {
    lightest?: string
    darkest?: string
    alpha4?: string
    alpha8?: string
    alpha12?: string
    alpha30?: string
    alpha50?: string
  }

  interface PaletteOptions {
    neutral?: ColorRange
  }
}
// Typographyに新しい項目の追加
declare module '@mui/material/styles' {
  interface TypographyVariants {
    title1: CSSProperties
    body1_1: CSSProperties
    body1_2: CSSProperties
    body2_2: CSSProperties
    body3_1: CSSProperties
    body3_2: CSSProperties
    body3_3: CSSProperties
    // 現状使用率が高いが、定義されていないもの
    // 後ほど変更する可能性が高いため、どこで使われているかわかりやすい命名にしている
    heading_in_card: CSSProperties
    caption_in_card: CSSProperties
    amount_in_card: CSSProperties
    drawer_when_mobile: CSSProperties
    heading_in_commuting_path: CSSProperties
    body_in_commuting_path: CSSProperties
    header_in_commuting: CSSProperties
    page_title: CSSProperties
  }

  interface TypographyVariantsOptions {
    title1?: CSSProperties
    body1_1?: CSSProperties
    body1_2?: CSSProperties
    body2_2?: CSSProperties
    body3_1?: CSSProperties
    body3_2?: CSSProperties
    body3_3?: CSSProperties
    heading_in_card?: CSSProperties
    caption_in_card?: CSSProperties
    amount_in_card?: CSSProperties
    drawer_when_mobile?: CSSProperties
    heading_in_commuting_path?: CSSProperties
    body_in_commuting_path?: CSSProperties
    header_in_commuting?: CSSProperties
    page_title?: CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title1: true
    body1_1: true
    body1_2: true
    body2_2: true
    body3_1: true
    body3_2: true
    body3_3: true
    heading_in_card: true
    caption_in_card: true
    amount_in_card: true
    drawer_when_mobile: true
    heading_in_commuting_path: true
    body_in_commuting_path: true
    header_in_commuting: true
    page_title: true
  }
}

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    no_outline: true
    appbarMain: true
    appbarProxy: true
  }
}

interface ThemeConfig {
  direction?: Direction
  responsiveFontSizes?: boolean
  roundedCorners?: boolean
  theme?: string
}
export const neutral: ColorRange = {
  50: '#F8F9FA',
  100: '#F3F4F6',
  200: '#E5E7EB',
  300: '#D2D6DB',
  400: '#9DA4AE',
  500: '#6C737F',
  600: '#4D5761',
  700: '#2F3746',
  800: '#1C2536',
  900: '#111927',
}

const palette: PaletteOptions = {
  neutral,
  mode: 'light',
  action: {
    active: '#6b778c',
  },
  background: {
    default: L_C1['90'],
    paper: '#ffffff',
  },
  error: {
    contrastText: '#ffffff',
    main: accent['S_C1'],
  },
  primary: {
    contrastText: '#ffffff',
    main: main['L_C1'],
  },
  success: {
    contrastText: '#ffffff',
    main: accent['S_C3'],
  },
  text: {
    primary: '#172b4d',
    secondary: '#6b778c',
  },
  warning: {
    contrastText: '#ffffff',
    main: accent['S_C1_2'],
  },
}

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  components: {
    MuiTypography: {
      defaultProps: {
        variant: 'body1_1',
      },
    },
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          textTransform: 'none',
          boxShadow: 'none',
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: other['4654B9'],
          },
        },
        sizeSmall: {
          padding: '6px 16px',
        },
        sizeMedium: {
          padding: '8px 20px',
        },
        sizeLarge: {
          padding: '11px 24px',
        },
        textSizeSmall: {
          padding: '7px 12px',
        },
        textSizeMedium: {
          padding: '9px 16px',
        },
        textSizeLarge: {
          padding: '12px 16px',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100%',
        },
        body: {
          height: '100%',
        },
        '#root': {
          height: '100%',
        },
        '#nprogress .bar': {
          zIndex: '2000 !important',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: `1px solid ${grey[20]}`,
          borderRadius: '6px',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        avatar: {
          marginRight: '8px',
        },
      },
      defaultProps: {
        titleTypographyProps: {
          variant: 'title1',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomColor: palette.divider,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          [`& .${tableCellClasses.root}`]: {
            backgroundColor: palette.neutral![50],
            color: palette.neutral![900],
            fontWeight: 'bold',
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          backgroundColor: grey[10],
          button: {
            fontWeight: 500,
            fontSize: '13px',
          },
          a: {
            span: {
              fontWeight: 500,
              fontSize: '13px',
              color: main['L_C1'],
            },
          },
          span: {
            fontWeight: 500,
            fontSize: '13px',
            color: main['L_C1'],
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderTopWidth: 1,
          borderColor: grey[50],
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          '.MuiStepLabel-label.Mui-active': {
            fontSize: '12px',
            lineHeight: '140%',
            color: black,
          },
        },
        label: {
          fontSize: '12px',
          lineHeight: '140%',
          color: grey[50],
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: accent['S_C1'],
        },
        root: {
          fontSize: 14,
          fontWeight: 500,
          [`&.${inputLabelClasses.filled}`]: {
            transform: 'translate(12px, 18px) scale(1)',
          },
          [`&.${inputLabelClasses.shrink}`]: {
            [`&.${inputLabelClasses.standard}`]: {
              transform: 'translate(0, -1.5px) scale(0.85)',
            },
            [`&.${inputLabelClasses.filled}`]: {
              transform: 'translate(12px, 6px) scale(0.85)',
            },
            [`&.${inputLabelClasses.outlined}`]: {
              transform: 'translate(14px, -9px) scale(0.85)',
            },
          },
        },
      },
    },
  },
  typography: {
    button: {
      fontWeight: 600,
    },
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    title1: {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '140%',
      color: black,
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '140%',
      color: heavyBlack,
    },
    h1: {
      fontWeight: 700,
      fontSize: '32px',
      lineHeight: '140%',
      color: black,
    },
    h2: {
      fontWeight: 700,
      fontSize: '28px',
      lineHeight: '140%',
      color: heavyBlack,
    },
    h3: {
      fontWeight: 700,
      fontSize: '24px',
      color: heavyBlack,
    },
    h4: {
      fontWeight: 600,
      fontSize: '2rem',
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem',
    },
    body1_1: {
      fontSize: '14px',
      lineHeight: '120%',
      color: black,
    },
    body1_2: {
      fontSize: '14px',
      lineHeight: '120%',
      color: grey['80'],
    },
    body2_2: {
      fontSize: '13px',
      color: grey['80'],
    },
    body3_1: {
      fontSize: '12px',
      lineHeight: '120%',
      color: heavyBlack,
    },
    body3_2: {
      fontSize: '12px',
      lineHeight: '140%',
      color: grey['80'],
    },
    body3_3: {
      fontSize: '12px',
      lineHeight: '140%',
      color: grey['50'],
    },
    heading_in_card: {
      display: 'block',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '22px',
      color: '#333333',
    },
    amount_in_card: {
      display: 'block',
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '26px',
      color: '#333333',
    },
    drawer_when_mobile: {
      fontSize: '16px',
      lineHeight: '150%',
      color: 'rgba(0, 0, 0, 0.8)',
    },
    caption_in_card: {
      fontSize: '12px',
      lineHeight: '143%',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    overline: {
      fontWeight: 600,
    },
    body_in_commuting_path: {
      fontSize: '14px',
      lineHeight: '150%',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    header_in_commuting: {
      display: 'block',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '140%',
      color: 'rgba(0, 0, 0, 0.8)',
    },
    page_title: {
      display: 'block',
      fontSize: '28px',
      fontWeight: 700,
      lineHeight: '140%',
      color: 'rgba(0, 0, 0, 0.8)',
    },
  },
}

export const createCustomTheme = (config: ThemeConfig = {}): Theme => {
  const themeOptions = {
    components: {
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: '#E5E7EB',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 0.86,
              color: '#42526e',
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          input: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '24px',
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          checkedIcon: (
            <svg
              fill="none"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M9 3.5C5.68629 3.5 3 6.18629 3 9.5V15.5C3 18.8137 5.68629 21.5 9 21.5H15C18.3137 21.5 21 18.8137 21 15.5V9.5C21 6.18629 18.3137 3.5 15 3.5H9ZM16.7179 10.1961C17.1024 9.79966 17.0926 9.16657 16.6961 8.7821C16.2997 8.39763 15.6666 8.40737 15.2821 8.80385L10.6667 13.5635L8.7179 11.5539C8.33343 11.1574 7.70034 11.1476 7.30385 11.5321C6.90737 11.9166 6.89763 12.5497 7.2821 12.9461L9.94877 15.6961C10.1371 15.8904 10.3961 16 10.6667 16C10.9372 16 11.1962 15.8904 11.3846 15.6961L16.7179 10.1961Z"
                fill="currentColor"
                fillRule="evenodd"
              />
            </svg>
          ),
          color: 'primary',
          icon: (
            <svg
              fill="none"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                height="16"
                rx="5"
                stroke="currentColor"
                strokeWidth="2"
                width="16"
                x="4"
                y="4.5"
              />
            </svg>
          ),
          indeterminateIcon: (
            <svg
              fill="none"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M9 5.5H15C17.2091 5.5 19 7.29086 19 9.5V15.5C19 17.7091 17.2091 19.5 15 19.5H9C6.79086 19.5 5 17.7091 5 15.5V9.5C5 7.29086 6.79086 5.5 9 5.5ZM3 9.5C3 6.18629 5.68629 3.5 9 3.5H15C18.3137 3.5 21 6.18629 21 9.5V15.5C21 18.8137 18.3137 21.5 15 21.5H9C5.68629 21.5 3 18.8137 3 15.5V9.5ZM8 11.5C7.44772 11.5 7 11.9477 7 12.5C7 13.0523 7.44772 13.5 8 13.5H16C16.5523 13.5 17 13.0523 17 12.5C17 11.9477 16.5523 11.5 16 11.5H8Z"
                fill="currentColor"
                fillRule="evenodd"
              />
            </svg>
          ),
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontWeight: 500,
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            borderColor: palette.neutral![200],
            '&:hover': {
              backgroundColor: palette.action!.hover,
            },
            [`&.${filledInputClasses.disabled}`]: {
              backgroundColor: 'transparent',
            },
            [`&.${filledInputClasses.focused}`]: {
              backgroundColor: 'transparent',
              borderColor: (palette.primary as PaletteColor).main,
              boxShadow: `${(palette.primary as PaletteColor).main} 0 0 0 2px`,
            },
            [`&.${filledInputClasses.error}`]: {
              borderColor: (palette.error as PaletteColor).main,
              boxShadow: `${(palette.error as PaletteColor).main} 0 0 0 2px`,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: palette.action!.hover,
              [`& .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: palette.neutral![200],
              },
            },
            [`&.${outlinedInputClasses.focused}`]: {
              backgroundColor: 'transparent',
              [`& .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: (palette.primary as PaletteColor).main,
                borderWidth: '3px',
              },
            },
            [`&.${filledInputClasses.error}`]: {
              [`& .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: (palette.error as PaletteColor).main,
                borderWidth: '3px',
              },
            },
          },
          notchedOutline: {
            borderColor: palette.neutral![200],
          },
        },
      },
      MuiCard: {
        variants: [
          {
            props: { variant: 'no_outline' },
            style: {
              borderRadius: '16px',
              boxShadow:
                '0px 5px 22px 0px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)',
              outline: 'none',
              border: 'none',
            },
          },
        ],
      },
      MuiAppBar: {
        variants: [
          {
            props: { variant: 'appbarProxy' },
            style: {
              backgroundColor: '#998CEA',
              boxShadow: 'none',
              color: '#fff',
            },
          },
          {
            props: { variant: 'appbarMain' },
            style: {
              backgroundColor: main.L_C1,
              boxShadow: 'none',
              color: '#fff',
            },
          },
        ],
      },
      MuiTypography: {
        variants: [
          {
            // mb を有効するためには paragraph を true にする必要があるため、こちらに記載
            // baseOptions では span タグになる
            props: { variant: 'heading_in_commuting_path', paragraph: true },
            style: {
              fontWeight: 700,
              fontSize: '14px',
              lineHeight: '150%',
              color: '#333333',
              marginBottom: '6px',
            },
          },
        ],
      },
    },
    palette: palette,
    shadows: lightShadows,
  }

  let theme = createTheme(
    merge(
      {},
      baseOptions,
      themeOptions,
      {
        ...(config.roundedCorners && {
          shape: {
            borderRadius: 16,
          },
        }),
      },
      {
        direction: config.direction,
      }
    )
  )

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  return theme
}
