import type { DependencyList, MouseEvent } from 'react'
import { useState, useCallback, useEffect, useRef } from 'react'

export const useAsyncEffect = (callback: () => Promise<void>, deps: DependencyList): void => {
  return useEffect(() => {
    const f = async () => callback()
    f()
  }, deps) // eslint-disable-line react-hooks/exhaustive-deps
}

export const useBool = (initialValue: boolean = false): [boolean, () => void, () => void] => {
  const [b, setB] = useState(initialValue)
  const t = useCallback(() => setB(true), [])
  const f = useCallback(() => setB(false), [])
  return [b, t, f]
}

export function usePreviousValue<T>(value: T, initialValue?: T) {
  const ref = useRef(initialValue)
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export const useAnchor = (): [
  HTMLButtonElement | null,
  (event: MouseEvent<HTMLButtonElement>) => void,
  () => void
] => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const open = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const close = () => {
    setAnchorEl(null)
  }

  return [anchorEl, open, close]
}

export const useMounted = (fn: () => void) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fn, [])
}
