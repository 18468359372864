import type { FC } from 'react'

import { Box, Button, Container, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Helmet } from 'react-helmet-async'
import { Link as RouterLink } from 'react-router-dom'

const AuthorizationRequired: FC = () => {
  const theme = useTheme()
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <Helmet>
        <title>SAPPHIRE 経費精算 - 401エラー</title>
      </Helmet>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          minHeight: '100%',
          px: 3,
          py: '80px',
          marginTop: 6,
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Box
              alt="Under development"
              component="img"
              src={`/static/error/401.svg`}
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: 344,
              }}
            />
          </Box>
          <Typography
            align="center"
            color="textPrimary"
            variant={mobileDevice ? 'h4' : 'h1'}
            sx={{ mt: 5, fontSize: '24px' }}
          >
            401: ページを表示する権限がありません。
          </Typography>
          <Typography align="center" color="textSecondary" sx={{ mt: 3 }} variant="subtitle2">
            あなたのログイン中のアカウントには、このページを表示する権限がありません。
            <br />
            お手数ですが、ログイン情報をご確認ください。
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 4,
            }}
          >
            <Button color="primary" component={RouterLink} to="/" variant="outlined">
              トップに戻る
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default AuthorizationRequired
