import { useState } from 'react'
import type { FC, ReactNode } from 'react'

import { Badge, Box, Button, Collapse, ListItem } from '@mui/material'
import type { ListItemProps } from '@mui/material'
import { NavLink as RouterLink } from 'react-router-dom'

import { main, L_C1_60 } from '@/lib/colors'

import ChevronDownIcon from '../icons/ChevronDown'
import ChevronRightIcon from '../icons/ChevronRight'

interface NavItemProps extends ListItemProps {
  active?: boolean
  children?: ReactNode
  depth: number
  icon?: ReactNode
  info?: ReactNode
  open: boolean
  path: string
  hasNotifications: boolean
  title: string
  badgeCount: number
  subTitle?: string
}

const NavItem: FC<NavItemProps> = (props) => {
  const {
    active,
    children,
    depth,
    icon,
    info,
    open: openProp,
    path,
    hasNotifications,
    title,
    badgeCount,
    subTitle,
    ...other
  } = props
  const [open, setOpen] = useState<boolean>(openProp)

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen)
  }

  let paddingLeft = 16

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth
  }

  // Branch
  if (children) {
    return (
      <ListItem
        data-cy="cy-nav-section-item"
        disableGutters
        sx={{
          display: 'block',
          py: 0,
        }}
        {...other}
      >
        <Button
          endIcon={
            !open ? <ChevronRightIcon fontSize="small" /> : <ChevronDownIcon fontSize="small" />
          }
          onClick={handleToggle}
          startIcon={icon}
          sx={{
            color: 'text.secondary',
            fontWeight: 'fontWeightMedium',
            justifyContent: 'flex-start',
            pl: `${paddingLeft}px`,
            pr: '8px',
            py: '12px',
            textAlign: 'left',
            textTransform: 'none',
            width: '100%',
          }}
          variant="text"
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {subTitle && <Box sx={{ fontSize: '12px' }}>{subTitle}</Box>}
          {info}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    )
  }

  // Leaf
  return (
    <ListItem
      data-cy="cy-nav-section-item"
      disableGutters
      sx={{
        display: 'flex',
        py: 0,
        position: 'relative',
        '&:hover': {
          backgroundColor: '#F8F9FD', // FIXME: 現在この色の定義がないので直接指定
          borderRadius: '6px',
        },
      }}
    >
      <Button
        component={RouterLink}
        startIcon={icon}
        sx={{
          color: L_C1_60[40],
          fontWeight: '400',
          fontSize: '15px',
          justifyContent: 'flex-start',
          textAlign: 'left',
          pl: `${paddingLeft}px`,
          pr: '8px',
          py: '12px',
          textTransform: 'none',
          width: '100%',
          ...(active && {
            fontWeight: 'bold',
            color: '#111927',
            backgroundColor: '#11192714',
            fontSize: '15px',
            '& svg': {
              color: main['L_C1'],
            },
          }),
        }}
        variant="text"
        to={path}
      >
        {hasNotifications ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Badge
              color="secondary"
              variant="dot"
              sx={{
                '& .MuiBadge-badge': {
                  right: -6,
                  top: 6,
                },
              }}
            >
              <Box sx={{ flexGrow: 1 }}>{title}</Box>
              {subTitle && <Box sx={{ fontSize: '12px' }}>{subTitle}</Box>}
            </Badge>
            <Badge
              badgeContent={badgeCount}
              color="warning"
              sx={{
                pr: '30px',
              }}
            />
          </Box>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ flexGrow: 1, mr: '15px' }}>{title}</Box>
            {subTitle && <Box sx={{ fontSize: '12px' }}>{subTitle}</Box>}
            <Badge badgeContent={badgeCount} color="warning" />
          </Box>
        )}
        {info}
      </Button>
    </ListItem>
  )
}

export default NavItem
