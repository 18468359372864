import type { ElementType, MouseEvent, ReactElement } from 'react'
import { useState } from 'react'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import type { IconButtonProps, MenuItemProps, IconProps } from '@mui/material'
import {
  IconButton,
  Box,
  Popper,
  Fade,
  Typography,
  Menu,
  MenuItem,
  Drawer,
  List,
  Grid,
} from '@mui/material'

import { useBool } from '@/hooks'
import { useBrowserState } from '@/hooks/useBrowserState'
import { grey } from '@/lib/colors'

const fadeBoxSx = {
  border: `1px solid ${grey[20]}`,
  p: 1,
  borderRadius: '4px',
  bgcolor: 'background.paper',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const fadeTextSx = {
  fontSize: '12px',
  lineHeight: '16px',
  color: grey[50],
}

interface StyledIconProps {
  IconComponent: ElementType
  sx?: IconProps['sx']
}

function StyledIcon({ IconComponent, sx }: StyledIconProps) {
  return (
    <IconComponent
      sx={{
        color: 'rgba(0, 0, 0, 0.5)',
        ...sx,
      }}
    />
  )
}

function StyledMenuIcon({ IconComponent, sx }: StyledIconProps) {
  return (
    <IconComponent
      fontSize="small"
      sx={{
        color: 'rgba(0, 0, 0, 0.5)',
        mr: '8px',
        ...sx,
      }}
    />
  )
}

interface IconButtonForSelectActionsProps extends IconButtonProps {
  icon: ElementType
  showPopper?: boolean
}

/**
 * `IconButtonForSelectActions` は、`SelectActions` で使用するアイコンボタンです。
 * showPopper を true にすると、アイコンをホバーした際にポップアップメニューを表示します。
 *
 * @param {ElementType} icon アイコン
 * @param {boolean | undefined} showPopper ポップアップメニューを表示するかどうか
 * @param {JSX.Element} children ポップアップメニューの中身
 *
 */
export function IconButtonForSelectActions({
  icon: IconComponent,
  showPopper = false,
  children,
  sx,
  onClick,
  ...props
}: IconButtonForSelectActionsProps) {
  const { isMobile } = useBrowserState()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [opened, open, close] = useBool(false)

  const isStringChild = typeof children === 'string'

  const id = opened ? 'icon-button-popper' : undefined

  const handleEnter = (e: MouseEvent<HTMLButtonElement>) => {
    if (!showPopper || isMobile) return
    setAnchorEl(e.currentTarget)
    setTimeout(() => {
      open()
    }, 500)
  }

  const handleLeave = () => {
    if (!showPopper || isMobile) return
    close()
    setAnchorEl(null)
  }

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    close()
    setAnchorEl(null)
    onClick && onClick(e)
  }

  return (
    <>
      <IconButton
        size="small"
        onClick={handleClick}
        sx={{
          p: 0,
          ...sx,
        }}
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}
        {...props}
      >
        <StyledIcon IconComponent={IconComponent} />
      </IconButton>

      {showPopper && children && anchorEl && (
        <Popper id={id} open={opened} anchorEl={anchorEl} placement="top" transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Box sx={fadeBoxSx}>
                {isStringChild && <Typography sx={fadeTextSx}>{children}</Typography>}
                {!isStringChild && children}
              </Box>
            </Fade>
          )}
        </Popper>
      )}
    </>
  )
}

interface ActionMenuItemProps extends MenuItemProps {
  icon?: ElementType
}
/**
 * `ActionMenuItem` は、 `SelectActions` で使用するメニューアイテムです。
 *
 * @param {ElementType | undefined} icon アイコン
 * @param {JSX.Element} children メニューアイテムの中身
 */
export function ActionMenuItem({ icon: IconComponent, children, ...props }: ActionMenuItemProps) {
  const isStringChild = typeof children === 'string'

  return (
    <MenuItem data-type="action-menu-item" {...props} sx={{ minWidth: '136px', height: '36px' }}>
      <Grid container direction="row" justifyContent="flex-start" width={'100%'}>
        {IconComponent && (
          <Grid item xs={5}>
            <StyledMenuIcon IconComponent={IconComponent} />
          </Grid>
        )}
        <Grid item xs={7}>
          {isStringChild && <Typography variant="drawer_when_mobile">{children}</Typography>}
          {!isStringChild && children}
        </Grid>
      </Grid>
    </MenuItem>
  )
}

interface SelectActionButtonProps extends IconButtonProps {
  actions: JSX.Element[]
}
/**
 * `SelectActionButton` は、ポップアップメニューを表示するアイコンボタンです。
 * @param {JSX.Element[]} actions ポップアップメニューの中身
 */
export function SelectActionButton({ actions, sx, ...props }: SelectActionButtonProps) {
  const { isMobile } = useBrowserState()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  if (!actions.length) return null

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        size="small"
        id="open-action-button"
        data-cy="open-action-button"
        aria-controls={open ? 'action-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{
          color: 'rgba(0, 0, 0, 0.5)',
          p: 0,
          ...sx,
        }}
        onClick={handleClick}
        {...props}
      >
        <MoreVertIcon />
      </IconButton>
      {isMobile && (
        <Drawer anchor="bottom" open={open} onClose={handleClose}>
          <List>{actions}</List>
        </Drawer>
      )}
      {!isMobile && (
        <Menu
          id="action-menu"
          data-cy="action-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'open-action-button',
          }}
        >
          {actions}
        </Menu>
      )}
    </>
  )
}

interface SelectActionsProps {
  actions?: ReactElement[]
}

/**
 * SelectActions は、アクションボタンを選択するコンポーネントです。
 * 単一の場合、そのまま表示されます。
 * 複数の場合、SelectActionButton に渡され、popup で表示されます。
 */
export function SelectActions({ actions }: SelectActionsProps) {
  const hasManyActions = actions && actions.length > 1

  // カード上に設置することを想定しているため、カードのアクションとバブリングを防ぐ
  const handleClick = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <Box display="flex" alignItems="center" onClick={handleClick}>
      {!hasManyActions && <>{actions}</>}
      {hasManyActions && <SelectActionButton actions={actions} />}
    </Box>
  )
}
