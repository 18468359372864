import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Tooltip } from '@mui/material'

import { colorMap, colorStatus } from '@/components/StatusChip'

const CheckIcon = () => (
  <Tooltip title="異常なし">
    <CheckCircleIcon
      fontSize="small"
      sx={{
        color: colorMap[colorStatus.accept].accent,
      }}
    />
  </Tooltip>
)

const InformationIcon = () => (
  <Tooltip title="情報あり">
    <InfoOutlinedIcon
      fontSize="small"
      sx={{
        color: colorMap[colorStatus.information].accent,
      }}
    />
  </Tooltip>
)

const WarningIcon = () => (
  <Tooltip title="警告あり">
    <WarningAmberIcon
      fontSize="small"
      sx={{
        color: colorMap[colorStatus.warning].accent,
      }}
    />
  </Tooltip>
)

const CancelIcon = () => (
  <Tooltip title="エラーあり">
    <CancelOutlinedIcon
      fontSize="small"
      sx={{
        color: colorMap[colorStatus.error].accent,
      }}
    />
  </Tooltip>
)

export const ALL_VALIDATION_STATUSES = ['check', 'info', 'warning', 'error'] as const
export type ValidationStatus = typeof ALL_VALIDATION_STATUSES[number]

export const ValidationIcon = ({ status }: { status: ValidationStatus }) => {
  switch (status) {
    case 'check':
      return <CheckIcon />
    case 'info':
      return <InformationIcon />
    case 'warning':
      return <WarningIcon />
    case 'error':
      return <CancelIcon />
    default:
      return null
  }
}
