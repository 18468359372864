import { Box, CircularProgress, Stack, Typography } from '@mui/material'

export function LoadingPage() {
  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      height={'100%'}
      width={'100%'}
    >
      <Stack alignItems={'center'} spacing={2}>
        <CircularProgress size={24} />
        <Typography textAlign={'center'} color={'primary'} variant={'title1'} fontWeight={500}>
          読み込み中...
        </Typography>
      </Stack>
    </Box>
  )
}
