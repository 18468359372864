import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { Autocomplete, CircularProgress, InputAdornment, Popper, TextField } from '@mui/material'

import type { SystemCode } from '@/gen/graphql'
import { useExportApplicationTypeList } from '@/hooks/graphql/admin'

interface ApplicationType {
  name: string
  code: string
  isActive: boolean
  hidden: boolean
  systemCode: SystemCode
}

interface ApplicationTypeAutoCompleteProps {
  value?: string
  defaultValue?: ApplicationType
  onSelect: (ut: ApplicationType | null) => void
}
export function ApplicationTypeAutoComplete({
  value,
  defaultValue,
  onSelect,
}: ApplicationTypeAutoCompleteProps) {
  const types = useExportApplicationTypeList()

  return (
    <Autocomplete
      disablePortal
      options={types?.data || []}
      defaultValue={defaultValue}
      PopperComponent={(props) => <Popper {...props} disablePortal={false} />}
      sx={{ width: '100%' }}
      onChange={(_e, v) => {
        onSelect(v)
      }}
      getOptionLabel={(opt) => `${opt.name}  (Code: ${opt.code})`}
      isOptionEqualToValue={(opt) => opt.code === value}
      noOptionsText="経費タイプがありません"
      renderInput={(params) => (
        <TextField
          {...params}
          data-cy="cy-applicationType-popover-select-input"
          placeholder="経費タイプを選択"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {!defaultValue && types?.isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  )
}
