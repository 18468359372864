import type { ReactElement, ReactNode } from 'react'
import { createContext, useState, useEffect } from 'react'

interface NativeMode {
  mode: 'browser' | 'android' | 'ios'
}

export const NativeModeContext = createContext<NativeMode>({ mode: 'browser' })

export function NativeModeContextProvider({
  children,
}: {
  children: ReactNode
}): ReactElement | null {
  const [nativeMode, setNativeMode] = useState<NativeMode>({ mode: 'browser' })

  useEffect(() => {
    const w = window as any | undefined
    const callback = (e: any) => {
      setNativeMode(e.detail as NativeMode)
    }
    w?.addEventListener('nativeMode', callback)
    return () => w?.removeEventListener('nativeMode', callback)
  }, [nativeMode])

  return <NativeModeContext.Provider value={nativeMode}>{children}</NativeModeContext.Provider>
}
