import { useMemo } from 'react'

import { sub } from 'date-fns'
import { parse } from 'qs'
import { useLocation, useParams } from 'react-router-dom'

import { read } from '@/lib/cookie'
import type { Month } from '@/lib/month'
import { parseMonth } from '@/lib/month'
import type { RouteInputType } from '@/lib/paths'
import { fromYYYYMMDD } from '@/utils/date'

export function useQueryParams() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

export const useProvider = (): string | undefined => {
  const q = useQueryParams()
  return q.get('provider') || read('provider') || undefined
}

export const useMonth = (): Month => {
  const q = useQueryParams()

  const m = q.get('month')
  if (m) {
    const parsed = parseMonth(m)
    if (parsed) return parsed
  }
  const today = new Date()
  return {
    year: today.getFullYear(),
    month: today.getMonth() + 1,
  }
}

export const useMonthOrPrevious = () => {
  const q = useQueryParams()

  const m = q.get('month')
  if (m) {
    const parsed = parseMonth(m)
    if (parsed) return parsed
  }
  const today = new Date()
  const previousMonth = sub(today, { months: 1 })
  return {
    year: previousMonth.getFullYear(),
    month: previousMonth.getMonth() + 1,
  }
}

export const useEmployeeId = (): string | undefined => {
  const q = useQueryParams()
  return q.get('employeeId') || undefined
}

export const useArrivalId = () => {
  const q = useQueryParams()
  return q.get('arrivalId') || undefined
}

export const useCp = (): string | undefined => {
  const q = useQueryParams()
  return (q.get('cp') as string) || undefined
}

export const useInputType = (): RouteInputType | undefined => {
  const q = useQueryParams()
  return (q.get('inputType') as RouteInputType) || undefined
}

export const useWorkplaceId = (): string | undefined => {
  const q = useQueryParams()
  return (q.get('workplaceId') as string) || undefined
}

export const useCurrentWeek = (): string | undefined => {
  const q = useQueryParams()
  return (q.get('currentWeek') as string) || undefined
}

export const useApplicationId = (): string | undefined => {
  const q = useQueryParams()
  return (q.get('applicationId') as string) || undefined
}

export const usePage = (): string | undefined => {
  const q = useQueryParams()
  return q.get('page') || undefined
}

export const useKind = (): string | undefined => {
  const q = useQueryParams()
  return q.get('kind') || undefined
}

export const useParsedQuery = () => {
  const query = useQueryParams()
  return parse(query.toString(), { ignoreQueryPrefix: true })
}

export const useNext = (): boolean => {
  const q = useQueryParams()
  const next = q.get('next')
  return next === 'true'
}

export const useYear = (): number => {
  const { year } = useParams()
  return parseInt(year || '')
}

export const useYearMonth = (): Month | undefined => {
  const obj = useParams()
  const { year, month } = obj
  if (year === undefined || month === undefined) return
  return { year: parseInt(year), month: parseInt(month) }
}

export const useYearMonthDate = (): Date => {
  const { year, month, date } = useParams()
  return fromYYYYMMDD(`${year}-${month}-${date}`)
}

export const useDate = (): Date => {
  const { date } = useParams()
  return fromYYYYMMDD(date!)
}
