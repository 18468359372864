import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

import { ReactComponent as SvgRequestQuote } from '@/icons/svg/request_quote.svg'

export function RequestQuote(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <SvgRequestQuote />
    </SvgIcon>
  )
}
