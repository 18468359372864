import type { ReactElement, Dispatch } from 'react'

import { Autocomplete, TextField, Stack, createFilterOptions } from '@mui/material'
import { compact } from 'lodash'

import type { DivisionDetailFragment } from '@/gen/graphql'
import { useDivisions } from '@/hooks/graphql'

const getOptionLabel = (division: DivisionDetailFragment) =>
  division.code ? `${division.code}: ${division.name}` : ''
const filterOptions = createFilterOptions({
  stringify: (option: DivisionDetailFragment) => getOptionLabel(option),
})

interface SelectDivisionProps {
  depth: number
  ancestor: DivisionDetailFragment | null
  value: DivisionDetailFragment | null
  onSelect: (v: DivisionDetailFragment | null) => void
}

function SelectDivision({ depth, ancestor, value, onSelect }: SelectDivisionProps): ReactElement {
  const descendants = useDivisions(ancestor?.id, ancestor ? ancestor.hasDescendants : true)
  return (
    <Autocomplete
      key={`cy-select-division-${depth}`}
      sx={{ pt: 1 }} // labelが隠れるので
      fullWidth
      options={descendants || []}
      filterOptions={filterOptions}
      autoComplete={true}
      getOptionLabel={getOptionLabel}
      noOptionsText="検索結果がありません"
      onChange={(_e, value) => onSelect(value)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          data-cy={`cy-select-division-${depth}-text-field`}
          required
          label={`組織階層${depth + 1}`}
          variant="outlined"
        />
      )}
    />
  )
}

interface NestedSelectDivisionFormProps {
  value: DivisionDetailFragment[]
  spacing?: number
  onChange: Dispatch<DivisionDetailFragment[]>
}

export function NestedSelectDivisionForm({
  value,
  spacing = 2,
  onChange,
}: NestedSelectDivisionFormProps): ReactElement {
  const divisionsForForm = [null, ...value]

  const handleChangeDivisions = (depth: number, v: DivisionDetailFragment | null) => {
    if (v === null) {
      const newDivisions = [...divisionsForForm]
      newDivisions.splice(depth, newDivisions.length)
      onChange(compact(newDivisions))
      return
    }

    const newDivisions = [...divisionsForForm]
    newDivisions.splice(depth, newDivisions.length, v)
    onChange(compact(newDivisions))
  }

  return (
    <Stack spacing={spacing}>
      {divisionsForForm.map((div, depth) => {
        if (!!div && !div.hasDescendants) return null

        return (
          <SelectDivision
            key={div?.id || `depth-${depth}`}
            depth={depth}
            ancestor={divisionsForForm[depth]}
            value={divisionsForForm[depth + 1] || null}
            onSelect={(v) => {
              handleChangeDivisions(depth + 1, v)
            }}
          />
        )
      })}
    </Stack>
  )
}
