import { useState } from 'react'
import type { FC, ReactNode } from 'react'

import { Grid, Box } from '@mui/material'
import { ErrorBoundary } from 'react-error-boundary'
import { Outlet } from 'react-router-dom'

import { ErrorFallbackContent } from '@/components/ErrorFallbackContent'
import { UserGuard } from '@/components/UserGuard'

import DashboardNavbar from './DashboardNavbar'
import DashboardSidebar from './DashboardSidebar'

interface DashboardLayoutProps {
  children?: ReactNode
}

const DashboardLayout: FC<DashboardLayoutProps> = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false)
  return (
    <Grid
      container
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <DashboardNavbar onSidebarMobileOpen={() => setIsSidebarMobileOpen(!isSidebarMobileOpen)} />
      <DashboardSidebar
        onMobileClose={(): void => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
      />
      <Grid item xs={12} lg={10} sx={{ pt: '64px', WebkitOverflowScrolling: 'touch' }}>
        <Box sx={{ height: '1px', minHeight: '100%', p: { xs: 2, sm: 3 } }}>
          <ErrorBoundary FallbackComponent={ErrorFallbackContent}>
            <UserGuard>
              <Outlet />
            </UserGuard>
          </ErrorBoundary>
        </Box>
      </Grid>
    </Grid>
  )
}

export default DashboardLayout
