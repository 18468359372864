import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

import { ReactComponent as SvgAssociatedJrEx } from '@/icons/svg/associated_jr_ex.svg'

export function AssociatedJrEx(props: SvgIconProps) {
  const { sx, ...rest } = props
  return (
    <SvgIcon
      viewBox="0 0 35 10"
      sx={{
        width: '35px',
        height: '10px',
        ...sx,
      }}
      {...rest}
    >
      <SvgAssociatedJrEx />
    </SvgIcon>
  )
}
