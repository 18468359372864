import type { DialogActionsProps, ButtonProps } from '@mui/material'
import { DialogActions as MuiDialogActions, Button } from '@mui/material'

export function DialogActions({ sx, ...props }: DialogActionsProps) {
  return (
    <MuiDialogActions
      sx={{
        bgcolor: '#F9F8FA',
        p: 2,
        ...sx,
      }}
      {...props}
    />
  )
}

export function ActionButton({ sx, ...props }: ButtonProps) {
  return (
    <Button
      sx={{
        fontWeight: 500,
        fontSize: '15px',
        lineHeight: '26px',
        ...sx,
      }}
      {...props}
    />
  )
}
