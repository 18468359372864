import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

import { ReactComponent as BulletTrainIcon } from '@/icons/svg/bullet_train.svg'

export function BulletTrain(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <BulletTrainIcon />
    </SvgIcon>
  )
}
