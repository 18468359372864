import { useCallback } from 'react'

import { stringify } from 'qs'

import { useGlobalStateContext } from '@/contexts/GlobalState'
import { useParsedQuery } from '@/hooks/query'
import { ApplicationPageKind } from '@/lib/types'

export enum BrowserPath {
  EXPENSE = '/expense',
  REQUEST = '/request',

  COMMUTING_PAST = '/commuting/past',
  COMMUTING = '/commuting',
  ROUTE = '/route',

  PAYMENT = '/payment',
  RECEIPT = '/receipt',

  APPROVE_COMMUTING = '/approve/commuting',
  APPROVE_ROUTE = '/approve/route',
  APPROVE_EXPENSE = '/approve/expense',
  APPROVE_REQUEST = '/approve/request',

  ACTIVITY_EXPENSE = '/activity/expense',
  ACTIVITY_REQUEST = '/activity/request',
  ACTIVITY_RECEIPT = '/activity/receipt',
  ACTIVITY_PAYMENT = '/activity/payment',

  ADMIN_USER_CREATE = '/admin/user/create',
  ADMIN_USER_EDIT = '/admin/user/edit',
  ADMIN_USER = '/admin/user',
  ADMIN_APPLICATION_TYPE = '/admin/type',

  OTHER = '/',
}

export enum BaseBrowserPath {
  EXPENSE = '/expense',
  REQUEST = '/request',
  COMMUTING_PAST = '/commuting/past',
  COMMUTING = '/commuting',
  ROUTE = '/route',
  PAYMENT = '/payment',
  RECEIPT = '/receipt',
}

export enum MogokPagePath {
  COMMUTING = 'commuting',
  COMMUTING_PAST = 'commutingPast',
  ROUTE = 'route',
  EXPENSE = 'expense',
  REQUEST = 'request',
  PAYMENT = 'payment',
  RECEIPT = 'receipt',
  APPROVE_COMMUTING = 'approveCommuting',
  APPROVE_ROUTE = 'ApproveRoute',
  APPROVE_REQUEST = 'ApproveRequest',
  APPROVE_EXPENSE = 'ApproveExpense',
  ACTIVITY_EXPENSE = 'ActivityExpense',
  ACTIVITY_REQUEST = 'ActivityRequest',
  ACTIVITY_RECEIPT = 'ActivityReceipt',
  ACTIVITY_PAYMENT = 'ActivityPayment',
  ADMIN_USER = 'AdminUser',
  ADMIN_USER_CREATE = 'AdminUserCreate',
  ADMIN_USER_EDIT = 'AdminUserEdit',
  ADMIN_APPLICATION_TYPE = 'AdminApplicationType',
  OTHER = 'other',
}

export const MogokPagePathByPathName = (pathName: string): MogokPagePath => {
  if (pathName.startsWith('/expense')) return MogokPagePath.EXPENSE
  if (pathName.startsWith('/request')) return MogokPagePath.REQUEST

  if (pathName.startsWith('/commuting/past')) return MogokPagePath.COMMUTING_PAST
  if (pathName.startsWith('/commuting')) return MogokPagePath.COMMUTING
  if (pathName.startsWith('/route')) return MogokPagePath.ROUTE

  if (pathName.startsWith('/payment')) return MogokPagePath.PAYMENT
  if (pathName.startsWith('/receipt')) return MogokPagePath.RECEIPT

  if (pathName.startsWith('/approve/commuting')) return MogokPagePath.APPROVE_COMMUTING
  if (pathName.startsWith('/approve/route')) return MogokPagePath.APPROVE_ROUTE
  if (pathName.startsWith('/approve/expense')) return MogokPagePath.APPROVE_EXPENSE
  if (pathName.startsWith('/approve/request')) return MogokPagePath.APPROVE_REQUEST

  if (pathName.startsWith('/activity/expense')) return MogokPagePath.ACTIVITY_EXPENSE
  if (pathName.startsWith('/activity/request')) return MogokPagePath.ACTIVITY_REQUEST
  if (pathName.startsWith('/activity/receipt')) return MogokPagePath.ACTIVITY_RECEIPT
  if (pathName.startsWith('/activity/payment')) return MogokPagePath.ACTIVITY_PAYMENT

  if (pathName.startsWith('/admin/user/create')) return MogokPagePath.ADMIN_USER_CREATE
  if (pathName.startsWith('/admin/user/edit')) return MogokPagePath.ADMIN_USER_EDIT
  if (pathName.startsWith('/admin/user')) return MogokPagePath.ADMIN_USER
  if (pathName.startsWith('/admin/type')) return MogokPagePath.ADMIN_APPLICATION_TYPE

  return MogokPagePath.OTHER
}

export const useMogokPagePath = () => {
  const pageKind = useGlobalStateContext().pagePath
  return pageKind
}

enum NavigationPageKind {
  NORMAL = '',
  APPROVE = '/approve',
  ACTIVITY = '/activity',
  ADMIN = '/admin',
}

export const useNavigationPageKindFromPath = (): NavigationPageKind => {
  const pageKind = useMogokPagePath()

  switch (pageKind) {
    case MogokPagePath.EXPENSE:
    case MogokPagePath.REQUEST:
    case MogokPagePath.COMMUTING:
    case MogokPagePath.COMMUTING_PAST:
    case MogokPagePath.PAYMENT:
    case MogokPagePath.RECEIPT:
    case MogokPagePath.ROUTE:
      return NavigationPageKind.NORMAL
    case MogokPagePath.ADMIN_USER:
    case MogokPagePath.ADMIN_USER_CREATE:
    case MogokPagePath.ADMIN_USER_EDIT:
    case MogokPagePath.ADMIN_APPLICATION_TYPE:
      return NavigationPageKind.ADMIN
    case MogokPagePath.ACTIVITY_EXPENSE:
    case MogokPagePath.ACTIVITY_REQUEST:
    case MogokPagePath.ACTIVITY_PAYMENT:
    case MogokPagePath.ACTIVITY_RECEIPT:
      return NavigationPageKind.ACTIVITY
    case MogokPagePath.APPROVE_EXPENSE:
    case MogokPagePath.APPROVE_REQUEST:
    case MogokPagePath.APPROVE_ROUTE:
    case MogokPagePath.APPROVE_COMMUTING:
      return NavigationPageKind.APPROVE
    case MogokPagePath.OTHER:
      throw new Error('other pages are not planed to be navigated')
  }
}

const generatePath = (
  navigationPageKind: NavigationPageKind,
  baseBrowserPath: BaseBrowserPath,
  id: string
) => {
  return `${navigationPageKind}${baseBrowserPath}/${id}`
}

/**
 * 呼ぶページに応じて自動でPathを生成する
 *
 * ## Usage Example
 * ```ts
 * pathGen = useSmartNavigationPathGenerator(BaseBrowserPath.EXPENSE)
 * ```
 *
 * #### case.1: /approve/expense で呼び出し
 * ```ts
 * console.log(pathGen(expenseId))
 * // `/approve/expense/${expenseId}`
 * ```
 * #### case.2: /expense で呼び出し
 * ```ts
 * console.log(pathGen(expenseId))
 * // `/expense/${expenseId}`
 * ```
 **/
export const useSmartNavigationPathGenerator = (baseBrowserPath: BaseBrowserPath) => {
  const navigationPageKind = useNavigationPageKindFromPath()
  const query = useParsedQuery()
  const queryString = query ? `?${stringify(query, { arrayFormat: 'brackets' })}` : ``

  const pathGenerator = useCallback(
    (id: string) => {
      return `${generatePath(navigationPageKind, baseBrowserPath, id)}${queryString}`
    },
    [baseBrowserPath, navigationPageKind, queryString]
  )
  return pathGenerator
}

export const browserPathByPageKind = (pageKind: ApplicationPageKind): BaseBrowserPath => {
  switch (pageKind) {
    case ApplicationPageKind.Expense:
      return BaseBrowserPath.EXPENSE
    case ApplicationPageKind.Request:
      return BaseBrowserPath.REQUEST
    case ApplicationPageKind.Commuting:
      return BaseBrowserPath.ROUTE
    case ApplicationPageKind.CommutingPath:
      return BaseBrowserPath.ROUTE
  }
}
