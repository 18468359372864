import type { ReactElement } from 'react'

import { WarningAmber } from '@mui/icons-material'
import { Avatar } from '@mui/material'

import type { ConfirmActionDialogProps } from '@/components/dialog/ConfirmActionDialog'
import { ConfirmActionDialog } from '@/components/dialog/ConfirmActionDialog'

export function WarningDialog({
  onCancel,
  onConfirm,
  open,
  text,
  title,
}: Omit<ConfirmActionDialogProps, 'icon'>): ReactElement {
  return (
    <ConfirmActionDialog
      onCancel={onCancel}
      onConfirm={onConfirm}
      open={open}
      text={text}
      icon={
        <Avatar sx={{ bgcolor: '#FEE4E2', mr: 2 }}>
          <WarningAmber sx={{ color: '#E55037' }} />
        </Avatar>
      }
      title={title}
    />
  )
}
