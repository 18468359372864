import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

import { ReactComponent as SvgShoninRouteCheck } from '@/icons/svg/shonin_route_check.svg'

export function ShoninRouteCheck(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <SvgShoninRouteCheck />
    </SvgIcon>
  )
}
