import { stringify } from 'qs'

import { InternalServerError, UnauthorizedError } from '@/api/config'
import type {
  SearchCondition,
  ExpenseListSearchCondition,
  RequestListSearchCondition,
  ApproveExpenseListSearchCondition,
  ApproveRequestListSearchCondition,
  ApproveRoutesListSearchCondition,
  FilterPaymentSearchCondition,
  ReceiptListSearchCondition,
  RoutesOfficesAddPageSearchCondition,
  ApproveCommutingListSearchCondition,
  ApproveCommutingWeeklyListSearchCondition,
  UserAdminSearchCondition,
} from '@/components/search/types'
import type {
  ApplicationCardFragment,
  BuildApplicationPresetInput,
  CommutingPathInputType,
} from '@/gen/graphql'
import { read } from '@/lib/cookie'
import { ApplicationPageKind } from '@/lib/types'
import { toYYYYMM, toYYYYMMDD, toYYYYMMDD_slash, toYYYYMM_slash } from '@/utils/date'

import { formatMonth, type Month } from './month'

type ListPageSearchCondition = SearchCondition

type ListPageQuery<T extends ListPageSearchCondition> = T & {
  page?: number
}

const convertListPageQueryToUrlString = (query?: ListPageQuery<ListPageSearchCondition>) => {
  return query ? `?${stringify(query, { arrayFormat: 'brackets' })}` : ``
}

export const _errorPage = (error: Error) => {
  if (error instanceof InternalServerError) {
    return '/500'
  } else if (error instanceof UnauthorizedError) {
    // パス401はすでに利用されている
    return '/unauthorized'
  } else {
    return '/404'
  }
}

export const _login = () => {
  // FIXME: cookieから取得してprovider渡す
  const provider = read('provider')
  return provider ? `/login?provider=${provider}` : `/login`
}

export const _expense = (query?: ListPageQuery<ExpenseListSearchCondition>) => {
  const queryStr = convertListPageQueryToUrlString(query)
  return `/expense${queryStr}`
}

export const _payment = (query?: ListPageQuery<FilterPaymentSearchCondition>) => {
  const queryStr = convertListPageQueryToUrlString(query)
  return `/payment${queryStr}`
}

export const _receipt = (query?: ListPageQuery<ReceiptListSearchCondition>) => {
  const queryStr = convertListPageQueryToUrlString(query)
  return `/receipt${queryStr}`
}

export const _application = (
  kind: ApplicationPageKind,
  query?: ListPageQuery<ExpenseListSearchCondition | RequestListSearchCondition>
) => {
  switch (kind) {
    case ApplicationPageKind.Expense:
      return _expense(query)
    case ApplicationPageKind.Request:
      return _request(query)
    case ApplicationPageKind.CommutingPath:
      return 'TODO'
    case ApplicationPageKind.Commuting:
      return 'TODO'
  }
}

export const _applicationCreate = (kind: ApplicationPageKind, cp?: string) => {
  switch (kind) {
    case ApplicationPageKind.Expense:
      return _expenseCreate({ cp })
    case ApplicationPageKind.Request:
      return _requestCreate()
    case ApplicationPageKind.CommutingPath:
      return 'TODO'
    case ApplicationPageKind.Commuting:
      return 'TODO'
  }
}

export const _applicationDetail = (
  id: string,
  kind: ApplicationPageKind,
  query?: ListPageQuery<ExpenseListSearchCondition | RequestListSearchCondition>
) => {
  switch (kind) {
    case ApplicationPageKind.Expense:
      return _expenseDetail(id, query)
    case ApplicationPageKind.Request:
      return _requestDetail(id, query)
    case ApplicationPageKind.CommutingPath:
      return 'TODO'
    case ApplicationPageKind.Commuting:
      return 'TODO'
  }
}

export const _applicationEdit = (
  application: ApplicationCardFragment,
  kind: ApplicationPageKind
) => {
  switch (kind) {
    case ApplicationPageKind.Expense:
      return _expenseEdit(application.id)
    case ApplicationPageKind.Request:
      return _requestEdit(application.id)
    case ApplicationPageKind.CommutingPath:
      return _routes({
        applicationId: application.id,
        workplaceId: application.commutingPath?.workplaceAddress.id,
        inputType: application.commutingPath?.inputType,
      })
    case ApplicationPageKind.Commuting:
      return 'TODO'
  }
}

export const _applicationEditForApprover = (
  application: ApplicationCardFragment,
  kind: ApplicationPageKind
) => {
  switch (kind) {
    case ApplicationPageKind.Expense:
      return _expenseEditForApprover(application.id)
    case ApplicationPageKind.Request:
      return _requestEditForApprover(application.id)
    case ApplicationPageKind.CommutingPath:
      return 'TODO'
    case ApplicationPageKind.Commuting:
      return 'TODO'
  }
}

export const _applicationDetailForApprover = (
  id: string,
  kind: ApplicationPageKind,
  query?: ListPageQuery<ExpenseListSearchCondition | RequestListSearchCondition>
) => {
  switch (kind) {
    case ApplicationPageKind.Expense:
      return _expenseDetailForApprover(id, query)
    case ApplicationPageKind.Request:
      return _requestDetailForApprover(id, query)
    case ApplicationPageKind.CommutingPath:
      return 'TODO'
    case ApplicationPageKind.Commuting:
      return 'TODO'
  }
}

const toExpenseCreateParam = (input: BuildApplicationPresetInput) => {
  // 一番最初に見つかったものを優先的に使う（複数入ってくることないので）
  const k = Object.keys(input).find((k) => !!k) as keyof BuildApplicationPresetInput
  if (!k) return ''

  const v = input[k]
  if (!v) return ''

  return new URLSearchParams({ [k]: typeof v === 'boolean' ? 'true' : v }).toString()
}

export const _expenseCreate = (
  input?: BuildApplicationPresetInput & { divisionId?: string; cp?: string }
) => {
  if (!input) return '/expense/create'

  return `/expense/create?${toExpenseCreateParam(input)}`
}

export const _expenseDetail = (
  id: string,
  query?: ListPageQuery<ApproveExpenseListSearchCondition>
) => {
  const queryStr = convertListPageQueryToUrlString(query)
  return `/expense/${id}${queryStr}`
}

export const _expenseDetailForApprover = (
  id: string,
  query?: ListPageQuery<ApproveExpenseListSearchCondition>
) => {
  const queryStr = convertListPageQueryToUrlString(query)
  return `/approve/expense/${id}${queryStr}`
}

export const _expenseActivityDetail = (id: string) => {
  return `/activity/expense/${id}`
}

export const _expenseEdit = (id: string) => {
  return `/expense/${id}/edit`
}

export const _expenseEditForApprover = (id: string) => {
  return `/approve/expense/${id}/edit`
}

export const _request = (query?: ListPageQuery<RequestListSearchCondition>) => {
  const queryStr = convertListPageQueryToUrlString(query)
  return `/request${queryStr}`
}

export const _requestDetail = (id: string, query?: ListPageQuery<RequestListSearchCondition>) => {
  const queryStr = convertListPageQueryToUrlString(query)
  return `/request/${id}${queryStr}`
}

export const _requestDetailForApprover = (
  id: string,
  query?: ListPageQuery<ApproveRequestListSearchCondition>
) => {
  const queryStr = convertListPageQueryToUrlString(query)
  return `/approve/request/${id}${queryStr}`
}

export const _requestActivityDetail = (id: string) => {
  return `/activity/request/${id}`
}

export const _requestCreate = (date?: Date) => {
  return '/request/create'
}

export const _requestEdit = (id: string) => {
  return `/request/${id}/edit`
}

export const _requestEditForApprover = (id: string) => {
  return `/approve/request/${id}/edit`
}

export const _commutingAllowance = (query?: { currentWeek?: string; next?: string }) => {
  const q: { currentWeek?: string; next?: string } = {}
  attachCurrentWeek(q, query?.currentWeek)
  attachNext(q, query?.next)
  return `/commuting?${stringify(q)}`
}

export const _commutingAllowanceDate = (
  date: Date,
  query?: { currentWeek?: string; next?: string }
) => {
  const q: { currentWeek?: string; next?: string } = {}
  attachCurrentWeek(q, query?.currentWeek)
  attachNext(q, query?.next)
  return `/commuting/${toYYYYMMDD(date)}?${stringify(q)}`
}

export const _commutingAllowanceEdit = (
  date: Date,
  query?: { currentWeek?: string; next?: string }
) => {
  const q: { currentWeek?: string; next?: string } = {}
  attachCurrentWeek(q, query?.currentWeek)
  attachNext(q, query?.next)
  return `/commuting/${toYYYYMMDD(date)}/edit?${stringify(q)}`
}

export const _commutingAllowancePast = (date: Date) => {
  return `/commuting/past/${date.getFullYear()}`
}

export const _commutingAllowancePastYearMonth = (date: Date, query?: { currentWeek?: string }) => {
  const q: { currentWeek?: string } = {}
  attachCurrentWeek(q, query?.currentWeek)
  return `/commuting/past/${toYYYYMM_slash(date)}?${stringify(q)}`
}

export const _commutingAllowancePastYearMonthDate = (
  date: Date,
  query?: { currentWeek?: string }
) => {
  const q: { currentWeek?: string } = {}
  attachCurrentWeek(q, query?.currentWeek)
  return `/commuting/past/${toYYYYMMDD_slash(date)}?${stringify(q)}`
}

export const _commutingAllowancePastYearMonthDateEdit = (
  date: Date,
  query?: { currentWeek?: string }
) => {
  const q: { currentWeek?: string } = {}
  attachCurrentWeek(q, query?.currentWeek)
  return `/commuting/past/${toYYYYMMDD_slash(date)}/edit?${stringify(q)}`
}

export const _allowance = (date?: Date) => {
  return date ? `/allowance?month=${toYYYYMM(date)}` : `/allowance`
}

export const _allowanceCreate = (date: Date) => {
  return `/allowance/create/${toYYYYMMDD(date)}`
}

const attachEmployeeId = (q: Record<string, unknown>, employeeId?: string) => {
  if (!employeeId) return
  q['employeeId'] = employeeId
}

const attachMonth = (q: Record<string, unknown>, month: string) => {
  if (!month) return
  q['month'] = month
}

const attachCurrentWeek = (q: Record<string, unknown>, currentWeek?: string) => {
  if (!currentWeek) return
  q['currentWeek'] = currentWeek
}

const attachNext = (q: Record<string, unknown>, next?: string) => {
  if (!next) return
  q['next'] = next
}

export const _approveCommuting = (
  month: Month,
  query?: ListPageQuery<ApproveCommutingListSearchCondition>
) => {
  const q = { month: formatMonth(month) }
  const queryStr = convertListPageQueryToUrlString({ ...query, ...q })
  return `/approve/commuting${queryStr}`
}

export const _approveCommutingList = (
  arg: { employeeId?: string; month: Month; currentWeek?: string },
  searchCondition?: ListPageQuery<ApproveCommutingWeeklyListSearchCondition>
) => {
  const q: { employeeId?: string } = {}
  attachEmployeeId(q, arg.employeeId)
  attachMonth(q, formatMonth(arg.month))
  attachCurrentWeek(q, arg.currentWeek)
  const queryStr = convertListPageQueryToUrlString({ ...searchCondition, ...q })
  return `/approve/commuting/list/${queryStr}`
}

export const _approveCommutingDetail = (arg: {
  date: Date
  employeeId?: string
  currentWeek?: string
}) => {
  const q: { employeeId?: string } = {}
  attachEmployeeId(q, arg.employeeId)
  attachCurrentWeek(q, arg.currentWeek)
  return Object.values(q).length > 0
    ? `/approve/commuting/detail/${toYYYYMMDD(arg.date)}?${stringify(q)}`
    : `/approve/commuting/detail/${toYYYYMMDD(arg.date)}`
}

export const _approveExpense = (query?: ListPageQuery<ApproveExpenseListSearchCondition>) => {
  const queryStr = convertListPageQueryToUrlString(query)
  return `/approve/expense${queryStr}`
}

export const _approveRequest = (query?: ListPageQuery<ApproveRequestListSearchCondition>) => {
  const queryStr = convertListPageQueryToUrlString(query)
  return `/approve/request${queryStr}`
}

export const _approveRoutes = (query?: ListPageQuery<ApproveRoutesListSearchCondition>) => {
  const queryStr = convertListPageQueryToUrlString(query)
  return `/approve/route${queryStr}`
}

export const _approveApplication = (
  kind: ApplicationPageKind,
  query?: ListPageQuery<ApproveExpenseListSearchCondition | ApproveRequestListSearchCondition>
) => {
  switch (kind) {
    case ApplicationPageKind.Expense:
      return _approveExpense(query)
    case ApplicationPageKind.Request:
      return _approveRequest(query)
    case ApplicationPageKind.CommutingPath:
      return 'TODO'
    case ApplicationPageKind.Commuting:
      return 'TODO'
  }
}

export type RouteInputType = 'ROOT' | CommutingPathInputType

export const _routes = (q?: {
  // 勤務地データというのは存在せず経路の終端を勤務地としているため、
  // overviewに経路が存在しない勤務地を表示をしたい時にはこれで指定する
  arrivalId?: string
  workplaceId?: string // 選択中の勤務地ID
  inputType?: RouteInputType // dialog制御用
  applicationId?: string
}) => {
  if (!q) return `/route`
  return `/route?${stringify(q)}`
}

export const _routesOfficesAdd = (query?: ListPageQuery<RoutesOfficesAddPageSearchCondition>) => {
  const queryStr = convertListPageQueryToUrlString(query)
  return `/route/office/add${queryStr}`
}

export const _adminUser = (query?: ListPageQuery<UserAdminSearchCondition>) => {
  const queryStr = convertListPageQueryToUrlString(query)
  return `/admin/user${queryStr}`
}

export const _adminUserCreate = () => {
  return '/admin/user/create'
}

export const _adminCustomFormCreate = () => {
  return '/admin/customform/create'
}

export const _adminUserEdit = (id: string) => {
  return `/admin/user/edit/${id}`
}

export const _adminApproverGroup = (query?: ListPageQuery<SearchCondition>) => {
  const queryStr = convertListPageQueryToUrlString(query)
  return `/admin/approvergroup${queryStr}`
}

export const _adminAuditRule = (query?: ListPageQuery<SearchCondition>) => {
  const queryStr = convertListPageQueryToUrlString(query)
  return `/admin/audit${queryStr}`
}

// FIXME: b+plusのロードが切り替わらない際のURLを設定する
export const guidanceUrl = '#'
