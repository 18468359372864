import type { ChipProps } from '@mui/material'
import { Chip } from '@mui/material'

import { ApplicationStatusLabel, CounterbalanceStatus } from '@/gen/graphql'
import type { ProcessRole } from '@/lib/types'
import { AppProcessRoles, ApproverProcessStatus } from '@/lib/types'

export type StatusVariant =
  | ApplicationStatusLabel
  | CounterbalanceStatus
  | ApproverProcessStatus
  | 'isDeletedReceipt'

// 既存の StatusLabel から移行中のため、一旦新しく作成する
// 今後色の定義がどうなるかわからないため色は今使用するものを定義
// FIXME: Figma の色が落ち着き次第 lib/colors.ts に移動する。
// FYI: Figma レイアウト アプリ版 - 2023/03/23

export enum colorStatus {
  main = 'main',
  error = 'error',
  warning = 'warning',
  accept = 'accept',
  information = 'information',
  disabled = 'disabled',
}

type StatusColorMap = {
  [key in colorStatus]: {
    accent: string
    light: string
  }
}

export const colorMap: StatusColorMap = {
  [colorStatus.main]: {
    accent: '#3144D2',
    light: 'rgba(80, 95, 210, 0.16)',
  },
  [colorStatus.error]: {
    accent: '#DD4A32',
    light: 'rgba(230, 88, 64, 0.16)',
  },
  [colorStatus.warning]: {
    accent: '#DC5200',
    light: 'rgba(226, 122, 66, 0.16)',
  },
  [colorStatus.accept]: {
    accent: '#009A5D',
    light: 'rgba(43, 199, 137, 0.16)',
  },
  [colorStatus.information]: {
    accent: '#3C9EEB',
    light: '#E0EFFC',
  },
  [colorStatus.disabled]: {
    accent: '#666',
    light: 'rgba(0, 0, 0, 0.16)',
  },
}

const applyAcceptInfo = (color: colorStatus) => ({
  color: color,
  text: '承認済み',
})

const applyUnappliedInfo = (color: colorStatus) => ({
  color: color,
  text: '未申請',
})

const applySubmittedInfo = (color: colorStatus) => ({
  color: color,
  text: '申請中',
})

const applyRejectedInfo = (color: colorStatus) => ({
  color: color,
  text: '差し戻し',
})

const applyWithdrawnInfo = (color: colorStatus) => ({
  color: color,
  text: '取り下げ',
})

const applyDeletedInfo = (color: colorStatus) => ({
  color: color,
  text: '削除済み',
})

const applyUnprocessedInfo = (color: colorStatus) => ({
  color: color,
  text: '未処理',
})

const applyProcessingInfo = (color: colorStatus) => ({
  color: color,
  text: '処理中',
})

const applyProcessedInfo = (color: colorStatus) => ({
  color: color,
  text: '処理済み',
})

const applyExceededInfo = (color: colorStatus) => ({
  color: color,
  text: '超過',
})

const applyHiddenInfo = (color: colorStatus) => ({
  color: color,
  text: '非表示',
})

type DisplayInfo = {
  color: colorStatus
  text: string
}

type MapStatusToRole = {
  [key in ApplicationStatusLabel.Unapplied | ApplicationStatusLabel.Rejected]: {
    [role in ProcessRole]: DisplayInfo
  }
}

const mapStatusToRole: MapStatusToRole = {
  [ApplicationStatusLabel.Unapplied]: {
    Applicant: applyUnappliedInfo(colorStatus.warning),
    Approver: applyUnappliedInfo(colorStatus.disabled),
  },
  [ApplicationStatusLabel.Rejected]: {
    Applicant: applyRejectedInfo(colorStatus.error),
    Approver: applyRejectedInfo(colorStatus.accept),
  },
}

const generateStatusInfo = (status: StatusVariant, role: ProcessRole) => {
  switch (status) {
    case ApplicationStatusLabel.Unapplied:
      return mapStatusToRole[status][role]
    case ApplicationStatusLabel.Rejected:
      return mapStatusToRole[status][role]
    case ApplicationStatusLabel.Approved:
      return applyAcceptInfo(colorStatus.accept)
    case ApplicationStatusLabel.Submitted:
      return applySubmittedInfo(colorStatus.main)
    case ApplicationStatusLabel.Withdrawn:
      return applyWithdrawnInfo(colorStatus.disabled)
    case ApplicationStatusLabel.Deleted:
      return applyDeletedInfo(colorStatus.disabled)
    case ApproverProcessStatus.Unapprovable:
      return applySubmittedInfo(colorStatus.disabled)
    case ApproverProcessStatus.Approvable:
      return applySubmittedInfo(colorStatus.warning)
    case 'isDeletedReceipt':
      return applyHiddenInfo(colorStatus.disabled)
    case ApproverProcessStatus.Unprocessed:
    case CounterbalanceStatus.NotYet:
    case CounterbalanceStatus.Partial:
      return applyUnprocessedInfo(colorStatus.warning)

    case CounterbalanceStatus.Processing:
      return applyProcessingInfo(colorStatus.main)
    case CounterbalanceStatus.Exceeded:
      return applyExceededInfo(colorStatus.error)
    case ApproverProcessStatus.Processed:
    case CounterbalanceStatus.Counterbalanced:
      return applyProcessedInfo(colorStatus.accept)
    default:
      throw new Error(`StatusChip: Not found status: ${status}`)
  }
}

const generateStatusInfoForApprover = (
  status: StatusVariant,
  isApprovable: boolean,
  role: ProcessRole
) => {
  const isSubmitted = status === ApplicationStatusLabel.Submitted
  if (isSubmitted && isApprovable) {
    return generateStatusInfo(ApproverProcessStatus.Approvable, role)
  }
  if (isSubmitted && !isApprovable) {
    return generateStatusInfo(ApproverProcessStatus.Unapprovable, role)
  }
  return generateStatusInfo(status, role)
}

const generateStatusInfoForProcessRole = (
  status: StatusVariant,
  role: ProcessRole,
  isApprovable: boolean
) => {
  switch (role) {
    case AppProcessRoles.Applicant:
      return generateStatusInfo(status, role)
    case AppProcessRoles.Approver:
      return generateStatusInfoForApprover(status, isApprovable, role)
    default:
      throw new Error(`StatusChip: Not found role: ${role}`)
  }
}

const chipSizeMap = {
  small: {
    height: '24px',
    fontSize: '13px',
    lineHeight: '21px',
    fontWeight: 500,
  },
  medium: {
    height: '32px',
    fontSize: '13px',
    lineHeight: '160%',
    fontWeight: 500,
  },
}

interface StatusChipProps extends ChipProps {
  status: StatusVariant
  isApprovable?: boolean
  role?: ProcessRole
}

export function StatusChip({
  status,
  isApprovable = false,
  role = AppProcessRoles.Applicant,
  size,
  sx,
  ...rest
}: StatusChipProps) {
  const { text, color } = generateStatusInfoForProcessRole(status, role, isApprovable)
  const { accent, light } = colorMap[color]
  const { height, fontSize, lineHeight } = chipSizeMap[size || 'medium']

  return (
    <Chip
      data-cy="cy-status-label"
      label={text}
      sx={{
        color: accent,
        backgroundColor: light,
        height,
        fontSize,
        lineHeight,
        ...sx,
      }}
      {...rest}
    />
  )
}
