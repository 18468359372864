import { useMemo } from 'react'

import type {
  AddressesQueryVariables,
  ProposalPathsByNavitimeQueryVariables,
  CustomFormByApplicationTypeInput,
  CustomFormOutputFragment,
  EmployeeAddressInput,
  AutocompleteAddressInput,
} from '@/gen/graphql'
import {
  useMultitenantQuery,
  useAddressesQuery,
  AddressType,
  usePermissionsQuery,
  useProposalPathsByNavitimeQuery,
  useDivisionsQuery,
  useAddressQuery,
  useEmployeeAddressesQuery,
  useCustomFormByApplicationTypeQuery,
  useCustomFormFromSavedApplicationQuery,
  useDefaultWorkplacesQuery,
  useNavitimeAutocompleteAddressQuery,
  NavitimeAddressType,
} from '@/gen/graphql'
import type { AddressesQueryWithoutTypeInput } from '@/lib/types'
import { toDatetimeFixedOffset, toYYYYMMDDhh_slash } from '@/utils/date'

export const useMultitenant = (date?: Date) => {
  const argDate = date ? date : new Date()
  const argDateString = toYYYYMMDDhh_slash(argDate)

  const targetDate = useMemo(() => {
    return toDatetimeFixedOffset(argDate)
  }, [argDateString]) // eslint-disable-line react-hooks/exhaustive-deps

  const { data } = useMultitenantQuery({ datetime: targetDate }, { keepPreviousData: true })
  return data?.multitenant
}

export const useProposalPathsByNavitime = (
  vars: ProposalPathsByNavitimeQueryVariables | undefined
) => {
  const { data } = useProposalPathsByNavitimeQuery(vars!, {
    enabled: !!vars,
    keepPreviousData: true,
  })
  return { data }
}

type OfficesArg = { input: AddressesQueryWithoutTypeInput }

export const useOffices = (arg: OfficesArg | undefined) => {
  const { data } = useAddressesQuery(
    arg
      ? { input: { ...arg.input, typeIn: [AddressType.Flapgate, AddressType.Partner] } }
      : ({} as AddressesQueryVariables), // enabledでcall制御しているので型合わせしているだけ
    { keepPreviousData: true, enabled: !!arg }
  )

  const { data: defaultWorkplaces } = useDefaultWorkplacesQuery(undefined, {
    keepPreviousData: true,
    enabled: !!arg,
  })

  if (!arg) return null
  if (!data) return null
  if (!defaultWorkplaces) return null

  return {
    defaultWorkplaces: defaultWorkplaces.defaultWorkplaces,
    offices: data.addresses,
  }
}

export const usePermissions = (enabled: boolean) => {
  const { data } = usePermissionsQuery({}, { enabled, keepPreviousData: true })
  if (!data) return null
  return data.permissions
}

export const useDivisions = (ancestorId: string | undefined, enabled: boolean) => {
  const { data } = useDivisionsQuery({ ancestorId }, { enabled })
  if (!data) return null
  // code で昇順
  return data.divisions?.sort((b, a) => (b.code > a.code ? 1 : -1))
}

export const useAddress = (id?: string) => {
  const { data } = useAddressQuery({ id: id! }, { enabled: !!id })
  if (!data) return null
  return data.address
}

export const useEmployeeAddresses = (input: EmployeeAddressInput) => {
  const { data } = useEmployeeAddressesQuery({ input })
  if (!data) return null
  return data.employeeAddresses
}

export const useCustomFormByApplicationType = (typeId: string | undefined, divisionId?: string) => {
  const { data } = useCustomFormByApplicationTypeQuery(
    { input: { typeId, divisionId } },
    { enabled: !!typeId }
  )
  return data
}

type CustomFormsInput =
  | { type: 'create'; input: CustomFormByApplicationTypeInput }
  | { type: 'update'; input: { applicationId: string } }

export const useCustomForms = (input: CustomFormsInput): CustomFormOutputFragment[] | null => {
  const { data: d1, isSuccess: ok1 } = useCustomFormByApplicationTypeQuery(
    { input: input.type === 'create' ? input.input : ({} as CustomFormByApplicationTypeInput) },
    { enabled: input.type === 'create' }
  )
  const { data: d2, isSuccess: ok2 } = useCustomFormFromSavedApplicationQuery(
    { applicationId: input.type === 'update' ? input.input.applicationId : '' },
    { enabled: input.type === 'update' }
  )

  if (ok1 && !!d1) return d1.customFormByApplicationType
  if (ok2 && !!d2) return d2.customFormFromSavedApplication
  return null
}

export const useNavitimeAutocompleteAddress = (word: string) => {
  const input: AutocompleteAddressInput = {
    word,
    types: [
      NavitimeAddressType.Station,
      NavitimeAddressType.Airport,
      NavitimeAddressType.Address,
      NavitimeAddressType.Port,
      NavitimeAddressType.Category,
      NavitimeAddressType.Busstop,
      NavitimeAddressType.ShuttleBusstop,
      NavitimeAddressType.HighwayBusstop,
    ],
  }

  const hasAnyWords = word !== ''

  const { data, isFetching } = useNavitimeAutocompleteAddressQuery(
    { input },
    { enabled: hasAnyWords }
  )
  const addresses = data?.autocompleteAddress

  return { data: addresses, isFetching }
}
