import { useState } from 'react'
import React from 'react'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Grid, IconButton, TextField } from '@mui/material'

import { Delete as DeleteIcon } from '@/icons/Delete'

interface PropetiesProps {
  properties: object
  onChange: (value: object) => void
}

export function Properties({ properties, onChange }: PropetiesProps) {
  const [newProperty, setNewProperty] = useState<string>('')
  return (
    <Grid container alignItems="center" justifyContent="flex-start" spacing={2}>
      {Object.entries(properties).map(([key, value]) => (
        <React.Fragment key={`property-row-${key}`}>
          <Grid item xs={4} key={`property-key-${key}`}>
            <TextField fullWidth value={key} />
          </Grid>
          <Grid item xs={7} key={`property-value-${key}`}>
            <TextField
              fullWidth
              value={value.toString()}
              onChange={(e) => {
                if (e.target.value === 'true') {
                  onChange({ ...properties, [key]: true })
                } else if (e.target.value === 'false') {
                  onChange({ ...properties, [key]: false })
                } else if (/^[1-9]\d*$/.test(e.target.value)) {
                  onChange({ ...properties, [key]: Number(e.target.value) })
                } else {
                  onChange({ ...properties, [key]: e.target.value })
                }
              }}
            />
          </Grid>
          <Grid item xs={1} key={`property-delete-${key}`}>
            <IconButton
              onClick={() => {
                onChange(
                  Object.entries(properties).reduce((acc, [k, v]) => {
                    if (k === key) return acc
                    return { ...acc, [k]: v }
                  }, {})
                )
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </React.Fragment>
      ))}
      <Grid item xs={4}>
        <TextField
          label={'新規プロパティ'}
          fullWidth
          value={newProperty}
          onChange={(e) => {
            setNewProperty(e.target.value)
          }}
        />
      </Grid>
      <Grid item xs={1}>
        <IconButton
          color="primary"
          onClick={() => {
            if (newProperty === '') return
            onChange({ ...properties, [newProperty]: '' })
            setNewProperty('')
          }}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}
