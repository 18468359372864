import type { ReactElement, ReactNode } from 'react'

import '@/lib/cognito'
import * as Sentry from '@sentry/react'
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useNavigate } from 'react-router-dom'
import '@/i18n'

import { ApiError, MogokErrorWithMsg } from '@/api/config'
import { NotificationSeverity, useNotificationContext } from '@/contexts/Notification'
import { MogokErrorKind } from '@/gen/graphql'
import { _errorPage } from '@/lib/paths'

const formatError = (code: MogokErrorKind) => {
  switch (code) {
    case MogokErrorKind.InvalidApprovalFlow:
      return '不正な承認経路です'
    default:
      return 'エラーが発生しました'
  }
}

export function QueryClientContextProvider({
  children,
}: {
  children: ReactNode
}): ReactElement | null {
  const { showSnackbar } = useNotificationContext()
  const nav = useNavigate()

  const queryClient = new QueryClient({
    // windowにfocusを当て直すと全api callするっていう挙動がdefaultなので封印
    // https://react-query.tanstack.com/guides/window-focus-refetching
    defaultOptions: {
      queries: { refetchOnWindowFocus: false, retry: false },
      mutations: { retry: false },
    },
    queryCache: new QueryCache({
      onError: (error, query) => {
        const [opName, queryArgs] = query.queryKey
        const err = error as Error
        console.error(`GRAPHQL ERROR\n${opName}: ${err.message}\n${JSON.stringify(queryArgs)}`)
        if (err instanceof ApiError) {
          nav(_errorPage(err))
          return
        }

        const errorKind = err.message as MogokErrorKind

        // 通貨APIのエラーはエラー表示させずに、ユーザに通貨レートを入力させる
        if (errorKind === MogokErrorKind.CurrencyApi) return

        Sentry.withScope((scope) => {
          scope.setFingerprint(['{{ default }}', opName as string])
          Sentry.captureException(new Error(err.message + query.queryHash), { level: 'error' })
        })

        showSnackbar({
          message: formatError(errorKind),
          severity: NotificationSeverity.ERROR,
        })
      },
    }),
    mutationCache: new MutationCache({
      onError: (error, variables, _context, mutation) => {
        const err = error as Error
        const opName = mutation.options.mutationKey?.[0]

        console.error(
          `GRAPHQL MUTATION ERROR\n${opName}: ${err.message}\n${JSON.stringify(variables)}`
        )

        if (err instanceof ApiError) {
          nav(_errorPage(err))
          return
        }

        Sentry.withScope((scope) => {
          scope.setFingerprint(['{{ default }}', opName as string])
          Sentry.captureException(new Error(err.message + JSON.stringify(variables)), {
            level: 'error',
          })
        })

        if (err instanceof MogokErrorWithMsg) {
          showSnackbar({
            message: err.message,
            severity: NotificationSeverity.ERROR,
          })
          return
        }

        showSnackbar({
          message: formatError(err.message as MogokErrorKind),
          severity: NotificationSeverity.ERROR,
        })
      },
    }),
  })

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      {children}
    </QueryClientProvider>
  )
}
