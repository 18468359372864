import { useCallback } from 'react'

import type { UserAdminSearchCondition } from '@/components/search/types'
import type {
  ImportApplicationTypeCsvMutationVariables,
  ImportDivisionCsvMutationVariables,
  ImportUserCsvMutationVariables,
  NewYayoiKanjouKamokuInput,
  SaveApproverGroupMutationVariables,
  UpdateAuditRulesMutationVariables,
  UsersInput,
} from '@/gen/graphql'
import {
  useExportApplicationTypesQuery,
  useExportCustomFormsQuery,
  useExportApproverGroupsQuery,
  useExportDivisionsQuery,
  useExportUsersQuery,
  useExportApplicationTypeListQuery,
  useDeleteCustomFormMutation,
  useCustomFormOutputQuery,
  useListApproverGroupQuery,
  useSaveApproverGroupMutation,
  useFilterAuditRulesQuery,
  useUpdateAuditRulesMutation,
  useOrgOriginQuery,
  useImportDivisionCsvMutation,
  useImportApplicationTypeCsvMutation,
  useImportUserCsvMutation,
  useYayoiKanjouKamokuByTypeQuery,
  useSaveYayoiKanjouKamokuMutation,
  useListYayoiKanjouKamokuMasterQuery,
} from '@/gen/graphql'

export const useYayoiKanjouKamokuByType = (applicationTypeCode?: string) => {
  const { data, refetch, isFetched } = useYayoiKanjouKamokuByTypeQuery(
    { input: applicationTypeCode! },
    { keepPreviousData: true, enabled: !!applicationTypeCode }
  )
  const masterData = data?.yayoiKanjouKamokuByType
  return { data: masterData, refetch, isFetched }
}

/* 下記の形式のデータ
{
  事務用品費: "xxx",
  交際費: "yyy",
  ...
}
*/
export const useListYayoiKanjouKamokuMaster = () => {
  const { data, refetch, isFetched } = useListYayoiKanjouKamokuMasterQuery(
    {},
    { keepPreviousData: true }
  )

  const masterData = (() => {
    if (!data) return
    const hashMap = data.listYayoiKanjouKamokuMaster as { [key: string]: string }
    const keys = Object.keys(hashMap)
    keys.sort()

    const sortedObject: { [key: string]: string } = {}
    keys.forEach((key) => {
      sortedObject[key] = hashMap[key]
    })
    return sortedObject
  })()

  return { data: masterData, refetch, isFetched }
}

export const useSaveYayoiKanjouKamoku = () => {
  const { mutateAsync, isLoading } = useSaveYayoiKanjouKamokuMutation()
  const callback = useCallback(
    async (input: NewYayoiKanjouKamokuInput[]) => await mutateAsync({ input }),
    [mutateAsync]
  )
  return { saveYayoiKanjouKamoku: callback, isLoading }
}

interface useExportUsersArgs {
  searchCondition: UserAdminSearchCondition
  page?: number
}

export const useExportUsers = ({ searchCondition, page }: useExportUsersArgs) => {
  const searchQuery: Pick<UsersInput, 'userId' | 'includeInactive'> = {
    userId: searchCondition.user_id,
    includeInactive: searchCondition.include_inactive,
  }

  const { data, refetch } = useExportUsersQuery(
    { input: { ...searchQuery, page: page ?? 1, perPage: 10 } },
    { keepPreviousData: true }
  )

  if (!data) return null
  return { data: data.exportUsers, refetch }
}

export const useExportApplicationTypes = () => {
  const { data, refetch } = useExportApplicationTypesQuery({}, { keepPreviousData: true })

  return { data: data?.exportApplicationTypes, refetch }
}

export const useExportApplicationTypeList = () => {
  const { data, isLoading } = useExportApplicationTypeListQuery({}, { keepPreviousData: true })
  if (!data) return null
  return { data: data.exportApplicationTypes, isLoading }
}

interface useExportCustomFormsArgs {
  page?: number
  words?: string
}

export const useExportCustomForms = ({ page, words }: useExportCustomFormsArgs) => {
  const { data, isLoading, refetch } = useExportCustomFormsQuery({
    input: {
      perPage: 10,
      page: page,
      words,
    },
  })
  return { data: data?.exportCustomForms, isLoading, refetch }
}

interface UseCustomFormOutPutArgs {
  code: string
}

export const useCustomFormOutPut = ({ code }: UseCustomFormOutPutArgs) => {
  const { data, isLoading } = useCustomFormOutputQuery({
    code: code,
  })
  return { data: data?.customForm, isLoading }
}

export const useDeleteCustomForm = () => {
  const { mutateAsync, isLoading } = useDeleteCustomFormMutation()

  const callback = useCallback(async (code: string) => await mutateAsync({ code }), [mutateAsync])

  return { deleteCustomForm: callback, isLoading }
}

export const useExportDivisions = () => {
  const { data, isLoading, refetch } = useExportDivisionsQuery()

  return { data: data?.exportDivisions, isLoading, refetch }
}

export const useExportApproverGroups = () => {
  const { data, isLoading } = useExportApproverGroupsQuery({})
  if (!data) return null

  return { data: data.exportApproverGroups, isLoading }
}

export const useListApproverGroup = (page: number) => {
  const { data, refetch } = useListApproverGroupQuery({ input: { page, perPage: 10 } })
  if (!data) return null

  return { data: data.listApproverGroup, refetch }
}

export const useSaveApproverGroup = () => {
  const { mutateAsync, isLoading } = useSaveApproverGroupMutation()

  const callback = useCallback(
    async (input: SaveApproverGroupMutationVariables) => await mutateAsync(input),
    [mutateAsync]
  )

  return { saveApproverGroup: callback, isLoading }
}

export const useFilterAuditRules = () => {
  const { data, refetch } = useFilterAuditRulesQuery({ input: {} }, { keepPreviousData: true })

  return { data: data?.filterAuditRules, refetch }
}

export const useUpdateAuditRules = () => {
  const { mutateAsync, isLoading } = useUpdateAuditRulesMutation()

  const callback = useCallback(
    async (input: UpdateAuditRulesMutationVariables) => await mutateAsync(input),
    [mutateAsync]
  )

  return { updateAuditRules: callback, isLoading }
}

export const useOrgOrigin = () => {
  const { data } = useOrgOriginQuery()

  return data?.orgOrigin
}

export const useImportDivisionCsv = () => {
  const { mutateAsync, isLoading } = useImportDivisionCsvMutation()

  const callback = useCallback(
    async (input: ImportDivisionCsvMutationVariables) => await mutateAsync(input),
    [mutateAsync]
  )

  return { importDivisionCsv: callback, isLoading }
}

export const useImportApplicationTypeCsv = () => {
  const { mutateAsync, isLoading } = useImportApplicationTypeCsvMutation()

  const callback = useCallback(
    async (input: ImportApplicationTypeCsvMutationVariables) => await mutateAsync(input),
    [mutateAsync]
  )

  return { importApplicationTypeCsv: callback, isLoading }
}

export const useImportUserCsv = () => {
  const { mutateAsync, isLoading } = useImportUserCsvMutation()

  const callback = useCallback(
    async (input: ImportUserCsvMutationVariables) => await mutateAsync(input),
    [mutateAsync]
  )

  return { importUserCsv: callback, isLoading }
}
