import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

import { ReactComponent as SvgShoninTsukinCheck } from '@/icons/svg/shonin_tsukin_check.svg'

export function ShoninTsukinCheck(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <SvgShoninTsukinCheck />
    </SvgIcon>
  )
}
