export const decimal_format = (
  amount: string | number | null | undefined,
  decimal_place?: number
): number => {
  if (!amount) return 0
  if (decimal_place === undefined) {
    if (typeof amount === 'string') return parseFloat(amount)
    if (typeof amount === 'number') return amount
  } else {
    if (typeof amount === 'string') return parseFloat(parseFloat(amount).toFixed(decimal_place))
    if (typeof amount === 'number') return parseFloat(amount.toFixed(decimal_place))
  }
  throw new Error('unreachable')
}
