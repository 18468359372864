import type { DialogProps } from '@mui/material'
import { Dialog as MuiDialog } from '@mui/material'

import { useBrowserState } from '@/hooks/useBrowserState'

// FullWidthをつけて使うことで携帯で見たときだけFullWidthになる
// FIXME: 命名が悪いので直したい
export function Dialog({ children, fullScreen, transitionDuration, ...props }: DialogProps) {
  const { isMobile } = useBrowserState()
  const defaultFullScreen = fullScreen ?? isMobile

  return (
    <MuiDialog fullScreen={defaultFullScreen} transitionDuration={200} {...props}>
      {children}
    </MuiDialog>
  )
}
