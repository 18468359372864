import { useState, useCallback, useMemo } from 'react'

import * as Sentry from '@sentry/react'
import * as Amplify from 'aws-amplify'
import isMobile from 'ismobilejs'
import { useNavigate } from 'react-router-dom'

import { backdoor } from '@/api/backdoor'
import { fetcher } from '@/api/config'
import type {
  PermissionsQuery,
  ProxyUsersQuery,
  UsersByEmailQuery,
  UsersByEmailQueryVariables,
  YayoiSessionQuery,
} from '@/gen/graphql'
import { UsersByEmailDocument, YayoiSessionDocument } from '@/gen/graphql'
import { useAsyncEffect } from '@/hooks'
import { getCurrentSession, getIdToken } from '@/lib/cognito'
import type { StoredUser } from '@/lib/cookie'
import { read, write } from '@/lib/cookie'
import { _login } from '@/lib/paths'

export enum AuthProvider {
  TOPPAN = 'toppan',
  TOPPANQA = 'toppan-qa',
  KAO = 'kao',
  MILETOS = 'miletos',
}

export type Auth = {
  loggedIn: boolean
  user?: { employeeId: string; name: string }
  provider?: AuthProvider
}

export type ProxyUser = ProxyUsersQuery['proxyUsers'][0]

export type UserProfile = {
  permissions: PermissionsQuery['permissions']
  division?: string
  proxyStatus: {
    currentProxyUser?: StoredUser
    changeProxyUser: (user: StoredUser | undefined) => void
  }
  isRemoteWorkAllowanceTarget: boolean
}

const backdoorAuth: Auth = {
  loggedIn: true,
  user: { employeeId: backdoor.employeeId, name: backdoor.name },
  provider: backdoor.provider,
}

export const shouldUseYayoiSso = () => {
  return isMobile(window.navigator) && window.location.href.includes('yayoi-kk.co.jp')
}

export const useUsingYayoiSso = () => {
  return useMemo(() => shouldUseYayoiSso(), [])
}

export const useAuth = () => {
  const [auth, setAuth] = useState<Auth | undefined>()
  const usingYayoiSso = useUsingYayoiSso()
  useAsyncEffect(async () => {
    try {
      if (backdoor.isEnabled) {
        setAuth(backdoorAuth)
        return
      }
      let employeeId: string
      let name: string
      // 弥生OEM版のPC(web)では、弥生のSSOを用いる
      if (usingYayoiSso) {
        if (!read('YSAT')) {
          setAuth({ loggedIn: false })
          return
        }
        const { ping } = await fetcher<YayoiSessionQuery, {}>(YayoiSessionDocument, {})()
        employeeId = ping.actAs?.employeeId!
        name = ping.actAs?.name!
        setAuth({
          loggedIn: true,
          user: { employeeId, name },
          provider: ping.organization as AuthProvider,
        })
        return
      }
      const payload = (await getIdToken()).payload
      const authorizedIdentity = payload.identities[0]
      if (
        typeof payload['custom:employee_id'] === 'string' &&
        payload['custom:employee_id'].length === 6
      ) {
        employeeId = String(payload['custom:employee_id']).padStart(8, '10')
        name = payload['custom:name_jp']
      } else if (typeof payload['custom:employee_id'] === 'string') {
        employeeId = payload['custom:employee_id']
        name = payload['custom:name_jp']
      } else {
        const { userByEmail } = await fetcher<UsersByEmailQuery, UsersByEmailQueryVariables>(
          UsersByEmailDocument,
          { email: payload['email'] }
        )()
        employeeId = userByEmail.employeeId
        name = userByEmail.name
      }
      setAuth({
        loggedIn: true,
        user: { employeeId, name },
        provider: authorizedIdentity.providerName,
      })
    } catch (e) {
      console.error('e: ', e)
      setAuth({ loggedIn: false })
    }
  }, [])

  return auth
}

export const usePreventDuplicateLoginEffect = () => {
  const navigate = useNavigate()

  useAsyncEffect(async () => {
    if (backdoor.isEnabled) return

    try {
      const session = await getCurrentSession()
      if (!session.isValid()) return
      navigate('/')
    } catch (e) {
      console.error('e: ', e)
    }
  }, [])
  //
}

export const useProxy = (): UserProfile['proxyStatus'] | null => {
  const changeProxyUser = useCallback((su: StoredUser | undefined) => {
    write({
      key: 'actAs',
      value: su ? { employeeId: su.employeeId, name: su.name, division: su.division } : undefined,
    })
    // 画面が半端に残って代理ログインのユーザーでapi callされると困るのでreloadして作り直す
    window.location.reload()
  }, [])
  const currentProxyUser = read('actAs')
  return { currentProxyUser, changeProxyUser }
}

export const useSignIn = () => {
  const nav = useNavigate()

  return useCallback(
    (customProvider: string) => {
      if (backdoor.isEnabled) {
        nav('/')
        return
      }
      write({ key: 'provider', value: customProvider })
      Amplify.Auth.federatedSignIn({ customProvider })
    },
    [nav]
  )
}

export const useSignOut = () => {
  const nav = useNavigate()

  return useCallback(async () => {
    await Amplify.Auth.signOut()
    Sentry.configureScope((scope) => scope.clear())
    nav(_login())
  }, [nav])
}
