import { useEffect, useState } from 'react'

interface NavigationInterceptorProps {
  state: boolean
}

// 引数stateは、NavigationInterceptDialogの引数whenに紐づいているstateと同一の必要があります。
// triggerNavの引数callback関数は、NavigationInterceptDialogのWhenに紐づいている上記stateがfalseになった後に実行されることが保証されています。
export function useNavigationInterceptor({ state }: NavigationInterceptorProps) {
  const [routable, setRoutable] = useState<boolean>(false)
  const [callback, setCallback] = useState<{ fn?: () => void }>({ fn: undefined })

  useEffect(() => {
    if (!state && routable && callback.fn) {
      callback.fn()
    }
  }, [callback, state, routable])

  return {
    triggerNav: (callback: () => void) => {
      setRoutable(true)
      setCallback({ fn: callback })
    },
  }
}
