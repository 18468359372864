import { useState } from 'react'

import { Stack, FormControlLabel, Checkbox, Typography } from '@mui/material'

import { Dialog } from '@/components/Dialog'
import { DialogActions, ActionButton } from '@/components/DialogActions'
import { DialogContentWithTitle } from '@/components/DialogContentWithTitle'
import { NumericalTextField } from '@/components/inputs/NumericalTextField'
import { requiredSx } from '@/lib/styles'
import { decimal_format } from '@/utils/decimalFormat'

interface InputDailyCostDialogProps {
  defaultValue: number[]
  length: number
  totalAmount: number
  decimal_place?: number
  onChange: (value: number[]) => void
  onClose: () => void
}

export function InputDailyCostsDialog({
  defaultValue,
  length,
  totalAmount,
  decimal_place = 0,
  onChange,
  onClose,
}: InputDailyCostDialogProps) {
  // 保存押下時にのみしかcontrollerに反映したくなかったので中間状態を保持
  const [costs, setCosts] = useState<number[]>(defaultValue)
  const [isEquallyDivided, setIsEquallyDivided] = useState<boolean>(false)
  const disableDivideEquallyButton = !totalAmount || totalAmount === 0

  const handleChangeCost = (cost: number, i: number) => {
    setIsEquallyDivided(false)
    const newCosts = [...costs]
    newCosts[i] = cost
    setCosts(newCosts)
  }

  const handleChange = () => {
    onChange(costs)
    onClose()
  }

  const makeAmountDividedEqually = () => {
    if (isEquallyDivided) {
      setIsEquallyDivided(false)
      return
    }
    const dividedAmount = decimal_format(totalAmount / length, decimal_place)
    const newCosts = Array(length).fill(dividedAmount)
    setCosts(newCosts)
    setIsEquallyDivided(true)
  }

  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <DialogContentWithTitle title="宿泊代" onClose={onClose}>
        <Stack direction="column" spacing={3}>
          <FormControlLabel
            control={
              <Checkbox
                disableRipple
                checked={isEquallyDivided}
                onChange={makeAmountDividedEqually}
                disabled={disableDivideEquallyButton}
              />
            }
            label={
              <>
                <Typography
                  sx={{
                    fontSize: '14px',
                    lineHeight: '140%',
                    color: '#666666',
                  }}
                >
                  等分する
                </Typography>
                {disableDivideEquallyButton && (
                  <Typography
                    sx={{
                      fontSize: '14px',
                      lineHeight: '140%',
                      color: 'red',
                      fontWeight: 'bold',
                    }}
                  >
                    {' ※経費精算の総額が未入力です'}
                  </Typography>
                )}
              </>
            }
            sx={{
              height: '24px',
            }}
          />
          {Array(length)
            .fill(null)
            .map((_, i) => (
              <NumericalTextField
                disabled={isEquallyDivided}
                key={`cost-${i}`}
                fullWidth
                mode={decimal_place > 0 ? 'float' : 'integer'}
                label={`${i + 1}日目`}
                value={costs[i] ?? 0}
                InputProps={{
                  sx: requiredSx(true, costs[i]),
                }}
                onChange={(num) => handleChangeCost(num ?? 0, i)}
              />
            ))}
        </Stack>
      </DialogContentWithTitle>
      <DialogActions>
        <ActionButton variant="outlined" onClick={onClose}>
          キャンセル
        </ActionButton>
        <ActionButton variant="contained" onClick={handleChange}>
          保存
        </ActionButton>
      </DialogActions>
    </Dialog>
  )
}
