import { useState, useMemo } from 'react'

import { Box, Typography } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider, DateRangePicker } from '@mui/x-date-pickers-pro'
import { differenceInCalendarDays } from 'date-fns'
import ja from 'date-fns/locale/ja'

import { grey } from '@/lib/colors'
import { requiredSx } from '@/lib/styles'

interface DateRangeProps {
  label: {
    start: string
    end: string
  }
  required?: boolean
  value: [Date | null, Date | null]
  isDateRangeDisplay?: boolean
  onChange: (dates: [Date, Date]) => void
}

export function DateRange({
  label,
  required = false,
  value,
  isDateRangeDisplay = true,
  onChange,
}: DateRangeProps) {
  // OK押下時にのみしかcontrollerに反映したくなかったので中間状態を保持
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>(value)

  const diffInDays = useMemo(() => {
    if (!dateRange[0] || !dateRange[1]) return 0 // どちらかのdateが未入力の場合は0を返す
    const diff = differenceInCalendarDays(dateRange[1] as Date, dateRange[0] as Date)
    return diff
  }, [dateRange])

  return (
    <Box data-cy={`cy-input-daterange-form`} pt={1}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
        <DateRangePicker
          data-cy={`cy-schema-daterange-form`}
          localeText={{
            openPreviousView: '前月を表示',
            openNextView: '次月を表示',
            toolbarTitle: '日付選択',
            cancelButtonLabel: 'キャンセル',
            okButtonLabel: '選択',
            start: label.start,
            end: label.end,
          }}
          slotProps={{
            toolbar: {
              toolbarFormat: 'yyyy年MM月dd日',
            },
            textField: {
              variant: 'outlined',
              error: false,
              InputProps: {
                sx: requiredSx(required, value[0]),
              },
            },
          }}
          format="yyyy/MM/dd"
          value={dateRange}
          onChange={(dr) => {
            setDateRange(dr)
          }}
          onAccept={(dr) => {
            if (dr[0] && dr[1]) {
              setDateRange(dr)
              onChange([dr[0], dr[1]])
            }
          }}
        />
      </LocalizationProvider>
      {isDateRangeDisplay && (
        <Box textAlign="end">
          <Typography display="inline-block" mt={2} color={grey[50]}>
            {isNaN(diffInDays) ? ( //  テキスト入力でひらがなを打つなどした場合のハンドリング
              <Typography component="span">日付を選択してください</Typography>
            ) : (
              <Typography component="span">合計：{diffInDays} 日</Typography>
            )}
          </Typography>
        </Box>
      )}
    </Box>
  )
}
