import { isEmpty, isUndefined } from 'lodash'

import { convertToPaymentOrder, convertToReceiptOrder } from '@/components/search/hooks'
import type {
  ExpenseListSearchCondition,
  FilterPaymentSearchCondition,
  ReceiptListSearchCondition,
  RequestListSearchCondition,
} from '@/components/search/types'
import type { FilterPaymentParams, MyApplicationsInput, ReceiptsInput } from '@/gen/graphql'
import { CounterbalanceStatus, ApplicationStatusLabel } from '@/gen/graphql'
import { ApplicationPageKind, kindsOf } from '@/lib/types'

export function intoFilterPaymentParams(
  searchCondition: FilterPaymentSearchCondition
): FilterPaymentParams {
  const { process_status, min_amount, max_amount, from, to, payee, include_deleted, order } =
    searchCondition ?? {}

  const unprocessedStatus = [
    CounterbalanceStatus.NotYet,
    CounterbalanceStatus.Partial,
    CounterbalanceStatus.Processing,
    CounterbalanceStatus.Exceeded,
  ]

  const processStatus = isEmpty(searchCondition) ? unprocessedStatus : process_status

  // BEでは [全て・削除済みonly・削除済みでないonly・何も返さない] の4状態に対応しているが、FEは[全て・削除済みでないonly]の2状態あれば十分らしいので変換する
  const isActive = include_deleted ? undefined : true

  const searchQuery: Pick<
    FilterPaymentParams,
    'statuses' | 'amountGe' | 'amountLe' | 'from' | 'to' | 'payee' | 'isActive' | 'order'
  > = {
    statuses: processStatus,
    amountGe: isUndefined(min_amount) ? undefined : String(min_amount),
    amountLe: isUndefined(max_amount) ? undefined : String(max_amount),
    from,
    to,
    payee,
    isActive,
    order: convertToPaymentOrder(order),
  }
  return searchQuery
}

export const fromReceiptListSearchCondition = (
  searchCondition: ReceiptListSearchCondition
): ReceiptsInput => {
  const { process_status, min_amount, max_amount, from, to, include_deleted, payee, order } =
    searchCondition ?? {}

  const defaultStatus = [
    CounterbalanceStatus.NotYet,
    CounterbalanceStatus.Partial,
    CounterbalanceStatus.Processing,
    CounterbalanceStatus.Exceeded,
  ]

  const processStatus = isEmpty(searchCondition) ? defaultStatus : process_status

  const searchQuery: Pick<
    ReceiptsInput,
    | 'statuses'
    | 'amountGe'
    | 'amountLe'
    | 'from'
    | 'to'
    | 'includeDeleted'
    | 'payee'
    | 'page'
    | 'perPage'
    | 'order'
  > = {
    statuses: processStatus,
    amountGe: isUndefined(min_amount) ? undefined : String(min_amount),
    amountLe: isUndefined(max_amount) ? undefined : String(max_amount),
    from,
    to,
    includeDeleted: include_deleted ? true : undefined,
    payee,
    order: convertToReceiptOrder(order), // ここでconvertやるのは変だが、共通化実装が変なので一旦諦めた。消したい。
  }

  return {
    ...searchQuery,
  }
}

export function fromExpenseListSearchCondition(
  searchCondition: ExpenseListSearchCondition
): MyApplicationsInput {
  const { application_status, min_amount, max_amount, from, to, audit_severity } =
    searchCondition ?? {}

  const applicationStatus = isEmpty(searchCondition)
    ? [
        ApplicationStatusLabel.Rejected,
        ApplicationStatusLabel.Submitted,
        ApplicationStatusLabel.Unapplied,
        ApplicationStatusLabel.Withdrawn,
      ]
    : application_status

  const searchQuery: Pick<
    MyApplicationsInput,
    'statuses' | 'ge' | 'le' | 'from' | 'to' | 'severities'
  > = {
    statuses: applicationStatus,
    ge: isUndefined(min_amount) ? undefined : String(min_amount),
    le: isUndefined(max_amount) ? undefined : String(max_amount),
    from,
    to,
    severities: isEmpty(audit_severity) ? undefined : audit_severity,
  }

  return {
    kinds: kindsOf(ApplicationPageKind.Expense),
    ...searchQuery,
  }
}

export function fromRequestListSearchCondition(
  searchCondition: RequestListSearchCondition
): MyApplicationsInput {
  const { application_status, min_amount, max_amount, from, to, audit_severity } =
    searchCondition ?? {}

  const applicationStatus = isEmpty(searchCondition)
    ? [
        ApplicationStatusLabel.Rejected,
        ApplicationStatusLabel.Submitted,
        ApplicationStatusLabel.Unapplied,
        ApplicationStatusLabel.Withdrawn,
      ]
    : application_status

  const searchQuery: Pick<
    MyApplicationsInput,
    'statuses' | 'ge' | 'le' | 'from' | 'to' | 'severities'
  > = {
    statuses: applicationStatus,
    ge: isUndefined(min_amount) ? undefined : String(min_amount),
    le: isUndefined(max_amount) ? undefined : String(max_amount),
    from,
    to,
    severities: isEmpty(audit_severity) ? undefined : audit_severity,
  }

  return {
    kinds: kindsOf(ApplicationPageKind.Request),
    ...searchQuery,
  }
}

export function convertEmptyArrayToUndefined<T>(value: T[]): T[] | undefined {
  if (value.length === 0) return
  return value
}

export function emptyStringToUndefined(value: string | ''): string | undefined {
  if (value === '') return
  return value
}

export function emptyToUndefinedFromNumber(value: number | null): string | undefined {
  if (value === null) return
  if (value === 0) return // NumericalTextField が、値を消すと勝手に0にする
  return value.toString()
}
