import type { FC } from 'react'
import { useEffect } from 'react'

import { Box, Button, Stack, Typography } from '@mui/material'
import * as Sentry from '@sentry/react'
import type { FallbackProps } from 'react-error-boundary'
import { useLocation } from 'react-router-dom'

import { usePreviousValue } from '@/hooks'
import { ReactComponent as ErrorImage } from '@/icons/svg/Buster_error.svg'
import { grey, main } from '@/lib/colors'
import { read, write } from '@/lib/cookie'

export const ErrorFallbackContent: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const { pathname } = useLocation()
  const prevLocation = usePreviousValue(pathname, pathname)

  if (error.name === 'ChunkLoadError') {
    const isAlreadyReloaded = read('forceReloaded') === 'true'
    if (isAlreadyReloaded) {
      console.error(error.message)
      Sentry.captureException(error)
    } else {
      write({ key: 'forceReloaded', value: 'true' }, 0.05) // 0.05 day = 1.2h
      window.location.assign(`/?t=${Date.now()}`)
    }
  }

  useEffect(() => {
    if (pathname !== prevLocation) {
      resetErrorBoundary()
    }
  }, [pathname]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <Stack
        flexDirection={'column'}
        alignItems={'center'}
        sx={{
          height: '100%',
          width: '100%',
          textAlign: 'center',
          mt: 5,
        }}
      >
        <ErrorImage style={{ width: '30%', minWidth: '200px' }} />
        <Caption message={error.message} reloadable={error.name === 'ChunkLoadError'} />
      </Stack>
    </Box>
  )
}

interface CaptionProps {
  message: string
  reloadable: boolean
}
const Caption = ({ message, reloadable }: CaptionProps) => {
  return (
    <Stack flexDirection={'column'} alignItems={'center'} spacing={2}>
      <Typography variant="h5" color={main['L_C1']} mt={3}>
        エラーが発生しました
      </Typography>
      <Typography variant="body1" color={main['L_C1']} fontWeight={500} mt={3}>
        {reloadable
          ? 'お手数ですが、ページ再読み込みボタンを押すか、時間をおいて再度操作をお試しください。'
          : 'お手数ですが、時間をおいて再度操作をお試しください。'}
      </Typography>
      <Typography variant="body1" color={grey['50']} mt={3}>
        エラー： {message.toUpperCase()}
      </Typography>

      {reloadable && (
        <Button
          data-cy="cy-admin-type-edit-save"
          onClick={() => window.location.reload()}
          variant="outlined"
          sx={{ mt: 3 }}
        >
          ページ再読み込み
        </Button>
      )}
    </Stack>
  )
}
