import type { ReactElement, ReactNode } from 'react'
import { useContext, createContext } from 'react'
import { useCallback, useEffect, useState } from 'react'

import { Slide, Snackbar, Alert } from '@mui/material'
import { useLocation } from 'react-router-dom'

export enum NotificationSeverity {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

interface ShowSnackbar {
  showSnackbar: (props: SnackbarAlert) => void
}
// CONTEXT
const NotificationContext = createContext<ShowSnackbar>({} as ShowSnackbar)

export const useNotificationContext = () => useContext(NotificationContext)

// COMPONENT
export function NotificationContextProvider({
  children,
}: {
  children: ReactNode
}): ReactElement | null {
  const { NotificationSnackbar, showSnackbar } = useSnackbar()
  return (
    <NotificationContext.Provider value={{ showSnackbar }}>
      <NotificationSnackbar />
      {children}
    </NotificationContext.Provider>
  )
}

// Snackbar本体
export interface SnackbarAlert {
  message: string
  severity: NotificationSeverity
}
//  Snackbarを表示したいときは useNotificationContext を使う
function useSnackbar() {
  const [isOpen, setIsOpen] = useState(false)
  const [text, setText] = useState('')
  const [severity, setSeverity] = useState<NotificationSeverity | undefined>()
  const { pathname } = useLocation()

  useEffect(() => {
    setIsOpen(false)
  }, [pathname])

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      // Snackbarの外をクリックするときは何もおきない
      return
    }
    setIsOpen(false)
  }

  const NotificationSnackbar = () => (
    <Snackbar
      open={isOpen}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={3000}
      TransitionComponent={Slide}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity} variant="filled" sx={{ width: '100%' }}>
        {text}
      </Alert>
    </Snackbar>
  )

  const showSnackbar = useCallback(({ message, severity }: SnackbarAlert) => {
    setIsOpen(true)
    setText(message)
    setSeverity(severity)
  }, [])

  return { NotificationSnackbar, showSnackbar }
}
