import * as React from 'react'

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import type { TextFieldProps } from '@mui/material'
import { Button, DialogActions, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateField } from '@mui/x-date-pickers/DateField'
import type { DatePickerToolbarProps } from '@mui/x-date-pickers/DatePicker'
import { DatePickerToolbar } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import type { DateValidationError, DateView } from '@mui/x-date-pickers/models'
import type { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar'
import type { PickersLayoutProps } from '@mui/x-date-pickers/PickersLayout'
import {
  pickersLayoutClasses,
  PickersLayoutContentWrapper,
  PickersLayoutRoot,
  usePickerLayout,
} from '@mui/x-date-pickers/PickersLayout'
import ja from 'date-fns/locale/ja'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'

const buildDateRangeValidator =
  (minDate: Date | undefined, maxDate: Date | undefined) => (d: Dayjs | null | undefined) => {
    if (!d) return false
    if (!dayjs(d).isValid()) return false
    if (minDate && dayjs(d).isBefore(dayjs(minDate), 'day')) return false
    if (maxDate && dayjs(d).isAfter(dayjs(maxDate), 'day')) return false
    return true
  }

function PickersDateField(props: {
  value?: Dayjs | null
  label?: string
  onChange: (d: Dayjs | null, reason: DateValidationError) => void
  error: boolean
}) {
  const { value, error, onChange, label } = props
  const [draftDate, setDraftDate] = React.useState<Dayjs | null | undefined>(value)

  const handleChange = (d: Dayjs | null, reason: DateValidationError) => {
    setDraftDate(d)
    onChange(d, reason)
  }

  return (
    <DateField
      value={draftDate}
      label={label}
      onChange={(d, { validationError }) => handleChange(d, validationError)}
      color={error ? 'error' : undefined}
      helperText={error ? '無効な日付です' : ''}
      FormHelperTextProps={{
        error,
      }}
      sx={{
        width: '100%',
      }}
      data-cy="cy-datepicker-keyboard-view"
    />
  )
}

// ref: https://github.com/mui/mui-x/blob/0453af437b93db58735f7e1c58b7773c167d898f/docs/data/migration/migration-pickers-v5/MobileKeyboardView.tsx

function LayoutWithKeyboardView(
  props: PickersLayoutProps<Dayjs | null, Dayjs, DateView> & {
    minDate?: Date
    maxDate?: Date
    label?: string
  }
) {
  const { value, minDate, maxDate, onChange, label } = props

  const [showKeyboardView, setShowKeyboardView] = React.useState(false)
  const [acceptButtonDisabled, setAcceptButtonDisabled] = React.useState(false)
  const [isvalidDate, setIsvalidDate] = React.useState(true)
  const isDateWithinBounds = buildDateRangeValidator(minDate, maxDate)

  React.useEffect(() => {
    // カレンダーでの変更を検知するために、valueを監視する
    setAcceptButtonDisabled(!isDateWithinBounds(value))
  }, [value, setAcceptButtonDisabled, isDateWithinBounds])

  const validateDate = (d: Dayjs | null, reason: DateValidationError) => {
    if (reason === 'invalidDate') return false
    if (!isDateWithinBounds(d)) return false
    return true
  }

  const handleChange = (d: Dayjs | null, reason: DateValidationError) => {
    setIsvalidDate(validateDate(d, reason))
    onChange(d)
  }

  const { toolbar, tabs, content, actionBar } = usePickerLayout({
    ...props,
    slotProps: {
      ...props.slotProps,
      toolbar: {
        ...props.slotProps?.toolbar,
        toolbarFormat: 'yyyy年MM月dd日',
        // @ts-ignore
        showKeyboardViewSwitch: props.wrapperVariant === 'mobile',
        showKeyboardView,
        setShowKeyboardView,
      },
      actionBar: {
        ...props.slotProps?.actionBar,
        actions: ['cancel', 'accept'],
        // @ts-ignore
        acceptButtonDisabled,
      },
    },
  })

  return (
    <PickersLayoutRoot ownerState={props}>
      {toolbar}
      <PickersLayoutContentWrapper className={pickersLayoutClasses.contentWrapper}>
        {tabs}
        {showKeyboardView ? (
          <Box sx={{ mx: 3, my: 2, width: 272 }}>
            <PickersDateField
              value={value}
              label={label}
              onChange={handleChange}
              error={!isvalidDate}
            />
          </Box>
        ) : (
          content
        )}
      </PickersLayoutContentWrapper>
      {actionBar}
    </PickersLayoutRoot>
  )
}

function ToolbarWithKeyboardViewSwitch(
  props: DatePickerToolbarProps<any> & {
    showKeyboardViewSwitch?: boolean
    showKeyboardView?: boolean
    setShowKeyboardView?: React.Dispatch<React.SetStateAction<boolean>>
  }
) {
  const { showKeyboardViewSwitch, showKeyboardView, setShowKeyboardView, ...other } = props

  if (showKeyboardViewSwitch) {
    return (
      <Stack
        spacing={2}
        direction={other.isLandscape ? 'column' : 'row'}
        alignItems="center"
        sx={
          other.isLandscape
            ? {
                gridColumn: 1,
                gridRow: '1 / 3',
              }
            : { gridColumn: '1 / 4', gridRow: 1, mr: 1 }
        }
      >
        <DatePickerToolbar {...other} sx={{ flex: '1 1 100%' }} />
        <IconButton
          color="inherit"
          onClick={() => setShowKeyboardView!((prev) => !prev)}
          data-cy="cy-datepicker-toolbar-icon-btn"
        >
          {showKeyboardView ? (
            <CalendarMonthIcon data-cy="cy-datepicker-calendar-icon" />
          ) : (
            <ModeEditIcon data-cy="cy-datepicker-edit-icon" />
          )}
        </IconButton>
      </Stack>
    )
  }

  return <DatePickerToolbar {...other} />
}

function PickersActionBar(
  props: PickersActionBarProps & {
    acceptButtonDisabled?: boolean
  }
) {
  const { onAccept, onCancel, onClear, onSetToday, actions, acceptButtonDisabled, ...other } = props

  if (actions == null || actions.length === 0) {
    return null
  }

  const buttons = actions?.map((actionType) => {
    switch (actionType) {
      case 'cancel':
        return (
          <Button data-cy="cy-datepicker-cancel-btn" onClick={onCancel} key={actionType}>
            キャンセル
          </Button>
        )
      case 'accept':
        return (
          <Button
            data-cy="cy-datepicker-accept-btn"
            onClick={onAccept}
            key={actionType}
            disabled={acceptButtonDisabled}
          >
            選択
          </Button>
        )
      default:
        return null
    }
  })
  return <DialogActions {...other}>{buttons}</DialogActions>
}

interface DatePickerProps {
  label?: string
  value: Date | null
  maxDate?: Date
  minDate?: Date
  dataCy?: string
  required?: boolean
  textFieldProps?: TextFieldProps
  disabled?: boolean
  onChange?: (date: Date | null) => void
  onAccept?: (date: Date | null) => void
}

export const DATE_PICKER_MIN_DATE = new Date('2020-01-01')

export function DatePicker({
  label,
  value,
  minDate,
  maxDate,
  required = true,
  dataCy,
  textFieldProps,
  disabled = false,
  onChange,
  onAccept,
}: DatePickerProps) {
  const handleDialogClose = (event: React.SyntheticEvent, reason: string) => {
    if (reason === 'backdropClick') {
      event.stopPropagation()
      return
    }
  }
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={ja}
      dateFormats={{ monthAndYear: 'yyyy年 MM月' }}
      localeText={{
        datePickerToolbarTitle: '日付選択',
      }}
    >
      <MobileDatePicker
        disabled={disabled}
        label={label}
        value={value}
        onChange={onChange}
        onAccept={onAccept}
        minDate={minDate}
        maxDate={maxDate}
        format="yyyy/MM/dd"
        localeText={{
          previousMonth: '前月を表示',
          nextMonth: '次月を表示',
        }}
        slots={{
          layout: (props) => (
            <LayoutWithKeyboardView {...props} minDate={minDate} maxDate={maxDate} label={label} />
          ),
          toolbar: ToolbarWithKeyboardViewSwitch,
          actionBar: PickersActionBar,
          textField: (props) => (
            <TextField
              {...props}
              fullWidth
              required={required}
              name="date"
              variant="outlined"
              data-cy={dataCy}
              {...textFieldProps}
            />
          ),
        }}
        slotProps={{
          dialog: {
            onClose: handleDialogClose,
          },
        }}
      />
    </LocalizationProvider>
  )
}
