import Decimal from 'decimal.js'

export function mul(a: number, b: number, ...more: number[]): number {
  return more.reduce(
    (acc, val) => new Decimal(acc).mul(val).toNumber(),
    new Decimal(a).mul(b).toNumber()
  )
}

export function div(a: number, b: number, ...more: number[]): number {
  return more.reduce(
    (acc, val) => new Decimal(acc).div(val).toNumber(),
    new Decimal(a).div(b).toNumber()
  )
}
