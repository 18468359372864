import isMobile from 'ismobilejs'

import { backdoor, backdoorAuthHeader } from '@/api/backdoor'
import { getIdTokenJwt } from '@/lib/cognito'
import { read } from '@/lib/cookie'

const cognitoAuthHeader = async () => ({
  Authorization: `Bearer ${await getIdTokenJwt()}`,
})

const authHeader = async () => {
  return backdoor.isEnabled ? backdoorAuthHeader : await cognitoAuthHeader()
}

const proxyRoleHeader = (): { 'X-Act-As': string } | {} => {
  const actAs = read('actAs')
  return !!actAs ? { 'X-Act-As': actAs.employeeId } : {}
}

// Open API config
export const basePath = process.env.REACT_APP_API_ORIGIN
  ? `${process.env.REACT_APP_API_ORIGIN}api`
  : '/api'

export const openApiHeader = async () => {
  return {
    ...(await authHeader()),
    'Content-Type': 'application/json',
  }
}

// GraphQL config
const endpointUrl = process.env.REACT_APP_API_ORIGIN
  ? `${process.env.REACT_APP_API_ORIGIN}api/graphql`
  : '/api/graphql'

const fetchParams = async (usingYayoiSso?: boolean) => {
  return usingYayoiSso
    ? {
        headers: {
          ...proxyRoleHeader(),
          'Content-Type': 'application/json',
        },
      }
    : {
        headers: {
          ...(await authHeader()),
          ...proxyRoleHeader(),
          'Content-Type': 'application/json',
        },
      }
}

export class MogokErrorWithMsg extends Error {}

export class ApiError extends Error {}

export class InternalServerError extends ApiError {}

export class UnauthorizedError extends ApiError {}

export function fetcher<TData, TVariables>(query: string, variables?: TVariables) {
  return async (): Promise<TData> => {
    const usingYayoiSso =
      isMobile(window.navigator) && window.location.href.includes('yayoi-kk.co.jp')
    const res = await fetch(endpointUrl as string, {
      method: 'POST',
      ...(await fetchParams(usingYayoiSso)),
      body: JSON.stringify({ query, variables }),
    })

    if (res.status === 401) {
      throw new UnauthorizedError()
    }

    if (500 <= res.status && res.status < 600) {
      throw new InternalServerError()
    }

    const json = await res.json()
    if (json.errors) {
      console.log(json.errors)

      if (json.errors[0]?.extensions?.msg) {
        throw new MogokErrorWithMsg(json.errors[0].extensions.msg)
      }
      if (json.errors[0]?.message) {
        throw new MogokErrorWithMsg(json.errors[0].message)
      }

      throw new Error(json.errors[0].extensions?.code)
    }
    return json.data
  }
}

// Sentry config
export const sentryDSN = process.env.REACT_APP_SENTRY_DSN
