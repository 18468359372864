import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import PriceCheckOutlinedIcon from '@mui/icons-material/PriceCheckOutlined'
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined'
import WorkOutlinedIcon from '@mui/icons-material/WorkOutlined'
import { Tooltip } from '@mui/material'

import { colorMap } from '@/components/StatusChip'
import { AssociatedIC } from '@/icons/AssociatedIC'
import { AssociatedJrEx } from '@/icons/AssociatedJrEx'

// Offset(相殺)
// CashAdvance(仮払い)
// Request(事前申請)
// Receipt(領収書)
// CreditCard JRExpress BPlus TransportationCard (連携データ)
export const ALL_APPLICATION_ASSOCIATIONS = [
  'Offset',
  'CashAdvance',
  'Request',
  'Receipt',
  'CreditCard',
  'JRExpress',
  'BPlus',
  'TransportationCard',
] as const
export type ApplicationAssociation = typeof ALL_APPLICATION_ASSOCIATIONS[number]

export const AssociationIcon = ({ association }: { association: ApplicationAssociation }) => {
  switch (association) {
    case 'Receipt':
      return (
        <Tooltip title="レシートあり">
          <ReceiptLongOutlinedIcon fontSize="small" sx={{ color: colorMap.main.accent }} />
        </Tooltip>
      )
    case 'CreditCard':
      return (
        <Tooltip title="CPカード">
          <CreditCardOutlinedIcon fontSize="small" sx={{ color: colorMap.main.accent }} />
        </Tooltip>
      )
    case 'BPlus':
      return (
        <Tooltip title="BTM（B+PLUS等）">
          <WorkOutlinedIcon fontSize="small" sx={{ color: colorMap.main.accent }} />
        </Tooltip>
      )
    case 'JRExpress':
      return (
        <Tooltip title="JR-EX">
          <span>
            <AssociatedJrEx fontSize="small" sx={{ color: colorMap.main.accent }} />
          </span>
        </Tooltip>
      )
    case 'TransportationCard':
      return (
        <Tooltip title="交通系ICカード">
          <span>
            <AssociatedIC fontSize="small" sx={{ color: colorMap.main.accent }} />
          </span>
        </Tooltip>
      )
    case 'Request':
      return (
        <Tooltip title="事前申請あり">
          <FactCheckOutlinedIcon fontSize="small" sx={{ color: colorMap.main.accent }} />
        </Tooltip>
      )
    case 'CashAdvance':
      return (
        <Tooltip title="仮払い">
          <AccountBalanceWalletOutlinedIcon fontSize="small" sx={{ color: colorMap.main.accent }} />
        </Tooltip>
      )
    case 'Offset':
      return (
        <Tooltip title="返金">
          <PriceCheckOutlinedIcon fontSize="small" sx={{ color: colorMap.main.accent }} />
        </Tooltip>
      )
  }
}
