import SvgIcon from '@mui/material/SvgIcon'

import { ReactComponent as SvgLogo } from '@/icons/svg/logo.svg'

export function Logo() {
  return (
    <SvgIcon
      data-cy="cy-logo"
      viewBox="0 0 118 24"
      sx={{
        width: 118,
        height: 24,
      }}
    >
      <SvgLogo />
    </SvgIcon>
  )
}
