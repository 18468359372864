import { Permission } from '@/gen/graphql'

const uuidPattern = '([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})'

export const allowedBy: Record<Permission, RegExp> = {
  [Permission.Payment]: /^\/payment(\/.*|\?.*)?$/,
  [Permission.Receipt]: /^\/receipt(\/.*|\?.*)?$/,
  [Permission.ApplyExpense]: /^\/expense(\/.*|\?.*)?$/,
  [Permission.ApproveExpense]: /^\/approve\/expense(\/.*|\?.*)?$/,
  [Permission.EditAndApproveExpense]: /^\/approve\/expense(\/.*|\?.*)?$/,
  [Permission.ApplyRequest]: /^\/request(\/.*|\?.*)?$/,
  [Permission.ApproveRequest]: /^\/approve\/request(\/.*|\?.*)?$/,
  [Permission.EditAndApproveRequest]: /^\/approve\/request(\/.*|\?.*)?$/,
  [Permission.ApplyCommuting]: /^\/commuting(\/.*|\?.*)?$/,
  [Permission.ApproveCommuting]: /^\/approve\/commuting(\/.*|\?.*)?$/,
  [Permission.ApplyRoute]: /^\/route(\/.*|\?.*)?$/,
  [Permission.ApproveRoute]: /^\/approve\/route(\/.*|\?.*)?$/,
  [Permission.PaymentAudit]: /^\/activity\/payment(\/.*|\?.*)?$/,
  [Permission.ReceiptAudit]: /^\/activity\/receipt(\/.*|\?.*)?$/,
  [Permission.ExpenseAudit]: new RegExp(
    '^\\/activity\\/(expense(\\/' + uuidPattern + ')?|request\\/' + uuidPattern + ')$'
  ),
  [Permission.RequestAudit]: new RegExp(
    '^\\/activity\\/(request(\\/' + uuidPattern + ')?|expense\\/' + uuidPattern + ')$'
  ),
  [Permission.CommutingAudit]: /^\/activity\/commuting(\/.*|\?.*)?$/,
  [Permission.RouteAudit]: /^\/activity\/route(\/.*|\?.*)?$/,
  [Permission.Debug]: /^\/admin(\/.*|\?.*)?$/,
}

export const allowedForAll: RegExp = /^\/(401|404|500)(\/.*|\?.*)?$/
