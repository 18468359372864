import { isArray, isEmpty, isString, isUndefined } from 'lodash'
import type { ParsedQs } from 'qs'
import { parse } from 'qs'

import type {
  ApproveCommutingListSearchCondition,
  ApproveCommutingWeeklyListSearchCondition,
  ApproveExpenseListSearchCondition,
  ApproveRoutesListSearchCondition,
  ExpenseListSearchCondition,
  FilterPaymentSearchCondition,
  ReceiptListSearchCondition,
  RequestListSearchCondition,
  RoutesOfficesAddPageSearchCondition,
  SearchCondition,
  UserAdminSearchCondition,
} from '@/components/search/types'
import { IntegrationStatus } from '@/components/search/types'
import {
  ApplicationStatusLabel,
  ApplicationStatusLabelForApprover,
  AuditSeverity,
  CounterbalanceStatus,
  PaymentOrder,
  ReceiptsInputOrder,
} from '@/gen/graphql'
import { useQueryParams } from '@/hooks/query'

const useParsedQuery = (): Record<string, string | string[] | undefined> => {
  const rawQuery = useQueryParams()
  const query = parse(rawQuery.toString(), { ignoreQueryPrefix: true })

  const validate = (query?: ParsedQs) => {
    if (isUndefined(query)) return {}

    return Object.entries(query).reduce((acc, [key, value]) => {
      if (isString(value)) {
        return { ...acc, [key]: value }
      }
      if (isArray(value)) {
        return { ...acc, [key]: value }
      }
      return { ...acc, [key]: undefined }
    }, {})
  }

  return validate(query)
}

const convertToApplicationStatuses = (value?: string | string[]): ApplicationStatusLabel[] => {
  if (isString(value)) throw new Error('invalid application status')
  if (value === undefined) return []

  return value.filter((v) =>
    Object.values(ApplicationStatusLabel).includes(v as ApplicationStatusLabel)
  ) as ApplicationStatusLabel[]
}

const convertToApprovalStatuses = (
  value?: string | string[]
): ApplicationStatusLabelForApprover[] => {
  if (isString(value)) throw new Error('invalid approval status')
  if (value === undefined) return []

  return value.filter((v) =>
    Object.values(ApplicationStatusLabelForApprover).includes(
      v as ApplicationStatusLabelForApprover
    )
  ) as ApplicationStatusLabelForApprover[]
}

const convertToProcessStatus = (value?: string | string[]): CounterbalanceStatus[] => {
  if (isString(value)) throw new Error('invalid process status')
  if (value === undefined) return []

  return value.filter((v) =>
    Object.values(CounterbalanceStatus).includes(v as CounterbalanceStatus)
  ) as CounterbalanceStatus[]
}

const convertToIntegrationStatuses = (value?: string | string[]): IntegrationStatus[] => {
  if (isString(value)) throw new Error('invalid integration status')
  if (value === undefined) return []

  return value.filter((v) =>
    Object.values(IntegrationStatus).includes(v as IntegrationStatus)
  ) as IntegrationStatus[]
}

const convertToSeverities = (value?: string | string[]): AuditSeverity[] => {
  if (isString(value)) throw new Error('invalid severity')
  if (value === undefined) return []
  return value.filter((v) =>
    Object.values(AuditSeverity).includes(v as AuditSeverity)
  ) as AuditSeverity[]
}

const convertToNumber = (value?: string | string[]): number | undefined => {
  if (Array.isArray(value)) throw new Error('invalid number')
  if (isUndefined(value)) return undefined
  if (isNaN(Number(value))) return undefined
  return Number(value)
}

const convertToDate = (value?: string | string[]): string | undefined => {
  if (Array.isArray(value)) throw new Error('invalid date')
  if (isUndefined(value)) return undefined
  if (/^\d{4}-\d{2}-\d{2}$/.test(value) === false) return undefined
  return value
}

const convertToBoolean = (value?: string | string[]): boolean => {
  if (Array.isArray(value)) throw new Error('invalid boolean')
  if (value === undefined) return false
  return value === 'true'
}

const convertToString = (value?: string | string[]): string | undefined => {
  if (Array.isArray(value)) throw new Error('invalid string')
  return isString(value) ? value : undefined
}

export const convertToReceiptOrder = (
  value?: string | string[]
): ReceiptsInputOrder | undefined => {
  if (Array.isArray(value)) throw new Error('invalid string')
  switch (value) {
    case ReceiptsInputOrder.PaidOnAsc:
      return ReceiptsInputOrder.PaidOnAsc
    case ReceiptsInputOrder.PaidOnDesc:
      return ReceiptsInputOrder.PaidOnDesc
    case ReceiptsInputOrder.UpdatedAtAsc:
      return ReceiptsInputOrder.UpdatedAtAsc
    case ReceiptsInputOrder.UpdatedAtDesc:
      return ReceiptsInputOrder.UpdatedAtDesc
    default:
      return
  }
}

export const convertToPaymentOrder = (value?: string | string[]): PaymentOrder | undefined => {
  if (Array.isArray(value)) throw new Error('invalid string')
  switch (value) {
    case PaymentOrder.PaidOnAsc:
      return PaymentOrder.PaidOnAsc
    case PaymentOrder.PaidOnDesc:
      return PaymentOrder.PaidOnDesc
    default:
      return
  }
}

const removeEmpty = <T extends SearchCondition>(condition: T): T => {
  return Object.entries(condition).reduce((acc, [key, value]) => {
    if (isUndefined(value)) return acc
    if (isArray(value) && value.length === 0) return acc

    return { ...acc, [key]: value }
  }, {} as T)
}

export const useExpenseListSearchCondition = (): ExpenseListSearchCondition => {
  const query = useParsedQuery()

  const { application_status, min_amount, max_amount, from, to, audit_severity } = query

  const searchCondition = {
    application_status: convertToApplicationStatuses(application_status),
    min_amount: convertToNumber(min_amount),
    max_amount: convertToNumber(max_amount),
    from: convertToDate(from),
    to: convertToDate(to),
    audit_severity: convertToSeverities(audit_severity),
  }

  return removeEmpty<ExpenseListSearchCondition>(searchCondition)
}

export const useRequestListSearchCondition = (): RequestListSearchCondition => {
  const query = useParsedQuery()

  const { application_status, min_amount, max_amount, from, to, audit_severity } = query

  const searchCondition = {
    application_status: convertToApplicationStatuses(application_status),
    min_amount: convertToNumber(min_amount),
    max_amount: convertToNumber(max_amount),
    from: convertToDate(from),
    to: convertToDate(to),
    audit_severity: convertToSeverities(audit_severity),
  }

  return removeEmpty<RequestListSearchCondition>(searchCondition)
}

export const useReceiptListSearchCondition = (): ReceiptListSearchCondition => {
  const query = useParsedQuery()

  const { process_status, payee, min_amount, max_amount, from, to, include_deleted, order } = query

  const searchCondition = {
    process_status: convertToProcessStatus(process_status),
    payee: convertToString(payee),
    min_amount: convertToNumber(min_amount),
    max_amount: convertToNumber(max_amount),
    from: convertToDate(from),
    to: convertToDate(to),
    include_deleted: convertToBoolean(include_deleted) || undefined,
    order: convertToReceiptOrder(order),
  }

  return removeEmpty<ReceiptListSearchCondition>(searchCondition)
}

export const useFilterPaymentSearchCondition = (
  useDefaultIfEmpty: boolean
): FilterPaymentSearchCondition => {
  const query = useParsedQuery()
  if (useDefaultIfEmpty && isEmpty(query)) {
    return {
      process_status: [
        CounterbalanceStatus.NotYet,
        CounterbalanceStatus.Partial,
        CounterbalanceStatus.Processing,
        CounterbalanceStatus.Exceeded,
      ],
      include_deleted: false,
      order: PaymentOrder.PaidOnAsc,
    }
  }
  const { process_status, payee, min_amount, max_amount, from, to, include_deleted, order } = query

  return {
    process_status: convertToProcessStatus(process_status),
    payee: convertToString(payee),
    min_amount: convertToNumber(min_amount),
    max_amount: convertToNumber(max_amount),
    from: convertToDate(from),
    to: convertToDate(to),
    include_deleted: convertToBoolean(include_deleted),
    order: convertToPaymentOrder(order),
  }
}

export const useApproveExpenseListSearchCondition = (
  useDefaultIfEmpty: boolean
): ApproveExpenseListSearchCondition => {
  const query = useParsedQuery()
  if (useDefaultIfEmpty && isEmpty(query)) {
    return {
      approval_status: [ApplicationStatusLabelForApprover.YourTurn],
    }
  }

  const { approval_status, min_amount, max_amount, from, to, audit_severity, applicant } = query

  return {
    approval_status: convertToApprovalStatuses(approval_status) ?? [],
    min_amount: convertToNumber(min_amount),
    max_amount: convertToNumber(max_amount),
    from: convertToDate(from),
    to: convertToDate(to),
    audit_severity: convertToSeverities(audit_severity) ?? [],
    applicant: convertToString(applicant),
  }
}

export const useApproveRequestSearchCondition = (
  useDefaultIfEmpty: boolean
): ApproveExpenseListSearchCondition => {
  const query = useParsedQuery()
  if (useDefaultIfEmpty && isEmpty(query))
    return { approval_status: [ApplicationStatusLabelForApprover.YourTurn] }
  const { approval_status, min_amount, max_amount, from, to, audit_severity, applicant } = query

  return {
    approval_status: convertToApprovalStatuses(approval_status) ?? [],
    min_amount: convertToNumber(min_amount),
    max_amount: convertToNumber(max_amount),
    from: convertToDate(from),
    to: convertToDate(to),
    audit_severity: convertToSeverities(audit_severity) ?? [],
    applicant: convertToString(applicant),
  }
}

export const useApproveRoutesSearchCondition = (
  useDefaultIfEmpty: boolean
): ApproveRoutesListSearchCondition => {
  const query = useParsedQuery()
  if (useDefaultIfEmpty && isEmpty(query))
    return { approval_status: [ApplicationStatusLabelForApprover.YourTurn] }
  const { approval_status, from, to, audit_severity, applicant } = query

  return {
    approval_status: convertToApprovalStatuses(approval_status),
    from: convertToDate(from),
    to: convertToDate(to),
    audit_severity: convertToSeverities(audit_severity),
    applicant: convertToString(applicant),
  }
}

export const approveCommutingSearchConditionDefaultValues: ApproveCommutingListSearchCondition = {
  approval_status: [ApplicationStatusLabelForApprover.YourTurn],
}
export const useApproveCommutingSearchCondition = (
  useDefaultIfEmpty: boolean
): ApproveCommutingListSearchCondition => {
  const query = useParsedQuery()
  if (useDefaultIfEmpty && isEmpty(query)) return approveCommutingSearchConditionDefaultValues
  const { approval_status, audit_severity, applicant } = query

  return {
    approval_status: convertToApprovalStatuses(approval_status) ?? [],
    audit_severity: convertToSeverities(audit_severity),
    applicant: convertToString(applicant),
  }
}

export const useApproveCommutingWeeklyListSearchCondition =
  (): ApproveCommutingWeeklyListSearchCondition => {
    const query = useParsedQuery()

    const { from, to, audit_severity, integration_status } = query

    const searchCondition = {
      audit_severity: convertToSeverities(audit_severity),
      from: convertToDate(from),
      to: convertToDate(to),
      integration_status: convertToIntegrationStatuses(integration_status),
    }

    return removeEmpty<ApproveCommutingWeeklyListSearchCondition>(searchCondition)
  }

export const useRoutesOfficesAddCondition = (): RoutesOfficesAddPageSearchCondition => {
  const query = useParsedQuery()

  const { name_like } = query

  const searchCondition = {
    name_like: convertToString(name_like),
  }

  return removeEmpty<RoutesOfficesAddPageSearchCondition>(searchCondition)
}

export const useUserAdminSearchCondition = (
  useDefaultIfEmpty: boolean
): UserAdminSearchCondition => {
  const query = useParsedQuery()
  if (useDefaultIfEmpty && isEmpty(query)) return {}
  const { user_id, include_inactive } = query

  return {
    include_inactive: convertToBoolean(include_inactive),
    user_id: convertToString(user_id),
  }
}
