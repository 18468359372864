import type { AuthProvider } from '@/hooks/auth'

const isBackdoorEnabled = () => {
  if (!process.env.REACT_APP_BACKDOOR_PROVIDER) return false
  if (!process.env.REACT_APP_BACKDOOR_EMPLOYEE_ID) return false
  return true
}

// -- BACKDOOR USER ID LIST
// 10582810: 広島の人1 (21117)
// 10578784: 広島の人2 (20532)
// 10553404: 広島の人3
// 10311402: 新百合ヶ丘の人
// 10544214: ひばりヶ丘の人
// 10317075: 秦野の人
// 10542399: 日本橋の人
// 10311326: 茅ヶ崎の人
// 10328662: 京都の人
// 10576378: 新曳舟の人
// 10992633: 練馬の人
// 10578142: 渋谷の人
// 10549140: 10582810のauthorizer
export const backdoor = {
  isEnabled: isBackdoorEnabled(),
  provider: `${process.env.REACT_APP_BACKDOOR_PROVIDER}` as AuthProvider,
  // employeeId: `10308517`, // applicant
  // employeeId: `10308660`, // approver
  employeeId: `${process.env.REACT_APP_BACKDOOR_EMPLOYEE_ID}`,
  name: `${process.env.REACT_APP_BACKDOOR_USER_NAME || 'backdoor太郎'}`,
}

export const backdoorAuthHeader = {
  'X-Backdoor-Provider': backdoor.provider,
  'X-Backdoor-Employee-Id': backdoor.employeeId,
}
