export const main = {
  NAVY: '#232887',
  L_C1: '#505FD2',
  L_C2: '#7078E6',
  L_C3: '#998CEA',
  L_C4: '#B29BEB',
  L_C5: '#4B505A',
}

export const L_C1 = {
  90: '#EEEFFB',
  80: '#DCDFF6',
  70: '#CBCFF2',
  60: '#B9BFED',
  50: '#A8AFE9',
  40: '#969FE4',
  30: '#858FE0',
  20: '#737FDB',
  10: '#626FD7',
}

export const L_C1_80 = {
  60: '#585962',
  50: '#6E707B',
  40: '#848694',
  30: '#9A9CAC',
  20: '#B0B2C5',
  10: '#C6C9DD',
}

export const L_C1_60 = {
  60: '#4A4C5F',
  50: '#5D6077',
  40: '#6F738E',
  30: '#8286A6',
  20: '#9499BE',
  10: '#A7ACD5',
}

export const accent = {
  S_C1: '#E65840', // 赤
  S_C1_2: '#E27A42', // オレンジ
  S_C2: '#DCC33C', // 黄色
  S_C2_2: '#EACF52',
  S_C3: '#2BC789', // 緑
  S_C3_2: '#0098A1',
  S_C4: '#3C9EEB', // 水色
  S_C4_2: '#3288C5',
  S_C5: '#A14BD1', // 紫
  S_C5_2: '#B06598',
}

// 仮称
export const grey = {
  5: '#F9F8FA',
  10: '#F5F5F5',
  20: '#E0E0E0',
  25: '#D6D6D6',
  30: '#BDBDBD',
  50: '#999999',
  60: '#75767B',
  80: '#666666',
  90: '#585858',
}

export const black = '#212121'

export const heavyBlack = '#333333'

// 現状色の定義がされていないもの 今後定義される可能性があるため、一旦まとめておく
export const other = {
  '4654B9': '#4654B9',
  DEE0F7: '#DEE0F7',
  D6D6D6: '#D6D6D6',
  lightPink: '#FBE4E0',
}

// 背景色が白の場合はprimary、背景色が青の場合はaccentを使用する
export const alertColor = {
  primary: {
    error: { background: '#FAEAE1', icon: '#E65840' },
    warning: { background: '#FAEAE1', icon: '#E27A42' },
    info: { background: '#E0EFFC', icon: '#3C9EEB' },
  },
  accent: {
    error: { background: '#EDD7DD', icon: '#E65840' },
    warning: { background: '#ECDCDD', icon: '#E27A42' },
    info: { background: '#D1E2F8', icon: '#3C9EEB' },
  },
}

export const textColor = {
  secondry: '#6C737F',
}
