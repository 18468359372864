import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

import { ReactComponent as SvgShoninKeihiCheck } from '@/icons/svg/shonin_keihi_check.svg'

export function ShoninKeihiCheck(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <SvgShoninKeihiCheck />
    </SvgIcon>
  )
}
