import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

import { ReactComponent as SvgDelete } from '@/icons/svg/delete.svg'

export function Delete(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <SvgDelete />
    </SvgIcon>
  )
}
