import { StrictMode } from 'react'

import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { createRoot } from 'react-dom/client'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { pdfjs } from 'react-pdf'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import App from '@/App'
import { initYup } from '@/lib/yup'

import { initSentry } from './lib/sentry'

//  PDFをWeb上でInline表示するために使用しているライブラリ(react-pdf)で必要な初期設定
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

initSentry()
initYup()

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <StrictMode>
    <HelmetProvider>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ToastContainer />
          <App />
        </LocalizationProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  </StrictMode>
)
