import { parse, eachDayOfInterval, sub, endOfMonth } from 'date-fns'
import type { OptionsWithTZ } from 'date-fns-tz'
import { formatInTimeZone } from 'date-fns-tz'

export function formatJST(utcDate: Date | number, format: string, options?: OptionsWithTZ): string {
  return formatInTimeZone(utcDate, 'Asia/Tokyo', format, options)
}

export function parseJST(s: string, format: string): Date {
  return new Date(`${s}T00:00:00+09:00`)
}

export const fromYYYYMM = (s: string) => parseJST(s, 'yyyy-MM')
export const fromYYYYMMDD = (s: string) => parseJST(s, 'yyyy-MM-dd')
export const fromDatetimeFixedOffset = (s: string) =>
  parse(s, "yyyy-MM-dd'T'HH:mm:ssXXX", new Date())

export const toYYYYMM01 = (d: Date) => formatJST(d, 'yyyy-MM-01')
export const toYYYYMM = (d: Date) => formatJST(d, 'yyyy-MM')
export const toYYYYMMDD = (d: Date) => formatJST(d, 'yyyy-MM-dd')
export const toYYYYMMDDhh_slash = (d: Date) => formatJST(d, 'yyyy/MM/dd hh')
export const toYYYYMMDDHHmm = (d: Date) => formatJST(d, 'yyyy-MM-dd HH:mm')
export const toYYYYMMDDHHmm_utc = (d: Date) => formatInTimeZone(d, 'UTC', 'yyyy-MM-dd HH:mm')
export const toYYYYMMDDhhmm_slash = (d: Date) => formatJST(d, 'yyyy/MM/dd hh:mm')

export const toYYYYMM_jp = (d: Date) => formatJST(d, 'yyyy年MM月')
export const toYYYYMMDD_jp = (d: Date) => formatJST(d, 'yyyy年MM月dd日')
export const toMMDD_jp = (d: Date) => formatJST(d, 'MM月dd日')
export const toYYYYMMDD_slash = (d: Date | number) => formatJST(d, 'yyyy/MM/dd')
export const toYYYYMM01_slash = (d: Date | number) => formatJST(d, 'yyyy/MM/01')
export const toMMDD_slash = (d: Date | number) => formatJST(d, 'MM/dd')
export const toYYYYMM_slash = (d: Date | number) => formatJST(d, 'yyyy/MM')
export const toDay = (d: Date) => ['日', '月', '火', '水', '木', '金', '土'][d.getDay()]
export const toNaive = (d: Date) => formatJST(d, "yyyy-MM-dd'T'HH:mm:ss")
export const toDatetimeFixedOffset = (d: Date) => formatJST(d, "yyyy-MM-dd'T'HH:mm:ss.000XXX")
export const toYYYYMMDDEEEE_jp = (d: Date) => `${formatJST(d, 'yyyy年MM月dd日')} ${toDay(d)}曜日`
export const toYYYYMMDDhhmm_jp = (d: Date) => formatJST(d, 'yyyy年MM月dd日 hh時mm分')

export const isSameYYYYMMDD = (d1: Date | string, d2: Date | string) =>
  toYYYYMMDD(new Date(d1)) === toYYYYMMDD(new Date(d2))

export const endDateOfLastMonth = (d: Date) => endOfMonth(sub(d, { months: 1 }))

// ref: https://miletostech.slack.com/archives/C04L15W0QTW/p1683854467832409?thread_ts=1683798003.550559&cid=C04L15W0QTW
export const generateWeeklyArrays = (date: Date) => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1

  // 処理対象月の最初の日付オブジェクトを生成
  const startDate = new Date(year, month - 1, 1)
  // 月初の曜日インデックスを求める (0=日曜、1=月曜、...)
  const dayOfWeekIndex = startDate.getDay()
  // 月初から日付を戻すことで、曜日インデックスが0 (日曜) になる日付を求める
  startDate.setDate(startDate.getDate() - dayOfWeekIndex)

  const weeks = []
  let currentMonth = month - 1 // JavaScriptのDateオブジェクトでは月は0-11の範囲で表現されるため、monthから1を引く

  while (currentMonth === month - 1) {
    const week = []
    for (let j = 0; j < 7; j++) {
      // 曜日を7日分繰り返す
      // 現在の日付オブジェクトを配列に追加
      week.push(new Date(startDate))
      // 日付を1日ずつ増やす
      startDate.setDate(startDate.getDate() + 1)
    }
    weeks.push(week)
    currentMonth = startDate.getMonth()
  }

  return weeks
}

export const generateMonthlyDates = (date: Date) => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1

  const start = new Date(year, month - 1, 1)
  const end = new Date(year, month, 0)
  return eachDayOfInterval({ start, end })
}

export const findNearestDates = (
  today: Date,
  dates: string[]
): {
  nearestPrevDate: Date | null
  nearestNextDate: Date | null
} => {
  const sortedDates = [...dates].sort().map((date) => fromYYYYMMDD(date))
  const index = sortedDates.findIndex((date) => isSameYYYYMMDD(date, today))
  return {
    nearestPrevDate: sortedDates[index - 1] ?? null,
    nearestNextDate: sortedDates[index + 1] ?? null,
  }
}

// JSTで本来何日であるかを示すよう、Dateオブジェクトの日付を調整する
export const toJSTDate = (date: Date): Date => {
  // 例えばサンフランシスコの場合は UTC-7 で、 420という値が返ってくる。
  // 日本との時差は16時間なので、16時間を加算した時刻の日付を返す
  const JstOffsetMinutes = 540
  const offsetMinutes = JstOffsetMinutes + date.getTimezoneOffset()
  const result = new Date(date.getTime() + offsetMinutes * 60 * 1000)
  result.setHours(0, 0, 0, 0)
  return result
}
