import createSvgIcon from '@mui/material/utils/createSvgIcon'

const InquiryFactCheck = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M9.19922 16.5H4.69922V18.3H9.19922V16.5Z" fill="#6F738E" />
    <path d="M9.19922 12.8999H4.69922V14.6999H9.19922V12.8999Z" fill="#6F738E" />
    <path
      d="M9.19922 10.2545C8.96112 9.95125 8.74962 9.63375 8.57292 9.30005H4.69922V11.1H9.19922V10.2545Z"
      fill="#6F738E"
    />
    <path
      d="M16.7761 13.0183L16.2358 12.478C15.6897 12.6482 15.1026 12.7319 14.496 12.7475L13.2945 13.9575L12.012 12.675L10.752 13.9575L13.2945 16.5L16.7761 13.0183Z"
      fill="#6F738E"
    />
    <path
      d="M19.266 15.5078L18.1999 14.4417V20.1H3.7999V7.49995H7.9508C7.8779 7.10435 7.8383 6.69765 7.8383 6.28025C7.8383 6.08435 7.8494 5.89135 7.8656 5.69995H3.7999C3.3049 5.69995 2.8812 5.87615 2.5287 6.22865C2.1762 6.58115 2 7.00485 2 7.49985V20.0999C2 20.5949 2.1763 21.0187 2.5287 21.3712C2.8812 21.7237 3.3049 21.8999 3.7999 21.8999H18.1999C18.6949 21.8999 19.1187 21.7236 19.4711 21.3712C19.8235 21.0188 19.9998 20.5949 19.9998 20.0999V15.9929C19.7328 15.8855 19.4824 15.7243 19.266 15.5078Z"
      fill="#6F738E"
    />
    <path
      d="M20.8222 13.9519L16.712 9.8417C16.38 10.0643 16.0138 10.2397 15.6133 10.368C15.2128 10.4962 14.7817 10.5604 14.3199 10.5604C13.1343 10.5604 12.1245 10.1433 11.2903 9.3092C10.4562 8.4751 10.0391 7.4654 10.0391 6.2802C10.0391 5.095 10.4562 4.0853 11.2903 3.2512C12.1244 2.4171 13.1341 2 14.3193 2C15.5045 2 16.5142 2.4171 17.3483 3.2512C18.1824 4.0853 18.5995 5.0952 18.5995 6.2808C18.5995 6.749 18.5354 7.1801 18.4071 7.5742C18.2789 7.9683 18.1035 8.3282 17.8808 8.6538L22.0006 12.7831C22.0006 12.7831 20.8223 13.9519 20.8222 13.9519ZM14.3193 8.9024C15.0514 8.9024 15.6715 8.6484 16.1795 8.1404C16.6875 7.6324 16.9415 7.0123 16.9415 6.2802C16.9415 5.5481 16.6875 4.928 16.1795 4.42C15.6715 3.912 15.0514 3.658 14.3193 3.658C13.5872 3.658 12.9671 3.912 12.4591 4.42C11.9511 4.928 11.6971 5.5481 11.6971 6.2802C11.6971 7.0123 11.9511 7.6324 12.4591 8.1404C12.9671 8.6484 13.5872 8.9024 14.3193 8.9024Z"
      fill="#6F738E"
    />
  </svg>,
  'InquiryFactCheck'
)

export default InquiryFactCheck
