import createSvgIcon from '@mui/material/utils/createSvgIcon'

const InquiryRoute = createSvgIcon(
  <svg
    id="_レイヤー_1"
    data-name="レイヤー 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="#6F738E"
  >
    <g>
      <path d="M8.6525,10.9126c.4127,.4761,.9206,.8622,1.4734,1.2059-.9183-1.2689-1.4664-2.8225-1.472-4.5071-.2324-.2802-.5096-.4997-.8661-.6126-.4435-.1404-.8796-.1219-1.3083,.0554l-4.4793,1.907v4.1689h1.774v-3.0158l1.5966-.6208-2.4836,12.5066h1.8627l1.5966-7.0959,1.8627,1.774v5.322h1.774v-6.6525l-1.8627-1.774,.5322-2.6609Z" />
      <path d="M8.6525,6.4776c.0309,0,.059-.0068,.0894-.0082,.1603-1.1091,.5522-2.1412,1.1302-3.0463-.341-.3266-.7466-.4934-1.2196-.4934-.4879,0-.9055,.1737-1.2529,.5211s-.5211,.765-.5211,1.2529,.1737,.9055,.5211,1.2529,.765,.5211,1.2529,.5211Z" />
      <polygon points="16.5911 18.4964 17.212 19.1172 12.6439 19.1172 12.6439 20.4478 17.212 20.4478 16.5911 21.0687 17.5224 22 19.7399 19.7825 17.5224 17.565 16.5911 18.4964" />
      <path d="M15.2755,15.2439c-.5154-.0753-1.0134-.2004-1.4907-.3719l-1.1409,1.1409,2.2175,2.2175,.9313-.9313-.6209-.6209h4.568v-1.3305h-4.568l.1036-.1036Z" />
    </g>
    {/* 虫眼鏡 */}
    <path d="M20.8222 13.9519L16.712 9.8417C16.38 10.0643 16.0138 10.2397 15.6133 10.368C15.2128 10.4962 14.7817 10.5604 14.3199 10.5604C13.1343 10.5604 12.1245 10.1433 11.2903 9.3092C10.4562 8.4751 10.0391 7.4654 10.0391 6.2802C10.0391 5.095 10.4562 4.0853 11.2903 3.2512C12.1244 2.4171 13.1341 2 14.3193 2C15.5045 2 16.5142 2.4171 17.3483 3.2512C18.1824 4.0853 18.5995 5.0952 18.5995 6.2808C18.5995 6.749 18.5354 7.1801 18.4071 7.5742C18.2789 7.9683 18.1035 8.3282 17.8808 8.6538L22.0006 12.7831C22.0006 12.7831 20.8223 13.9519 20.8222 13.9519ZM14.3193 8.9024C15.0514 8.9024 15.6715 8.6484 16.1795 8.1404C16.6875 7.6324 16.9415 7.0123 16.9415 6.2802C16.9415 5.5481 16.6875 4.928 16.1795 4.42C15.6715 3.912 15.0514 3.658 14.3193 3.658C13.5872 3.658 12.9671 3.912 12.4591 4.42C11.9511 4.928 11.6971 5.5481 11.6971 6.2802C11.6971 7.0123 11.9511 7.6324 12.4591 8.1404C12.9671 8.6484 13.5872 8.9024 14.3193 8.9024Z" />
  </svg>,
  'InquiryRoute'
)

export default InquiryRoute
