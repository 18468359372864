import type {
  ApplicationStatusLabel,
  CounterbalanceStatus,
  AuditSeverity,
  ApplicationStatusLabelForApprover,
  ApplicationsOfCommutingPathInput,
} from '@/gen/graphql'

// TODO: 連携ステータスの型を仮で定義している。BE側の実装が完了したら削除する
// 2023/08/01
export enum IntegrationStatus {
  ShiftData = 'shift_data',
  EntryExitData = 'entry_exit_data',
  ICCard = 'ic_card',
}

type SearchQueryValueTypes = {
  no_default?: boolean
  application_status?: ApplicationStatusLabel[]
  approval_status?: ApplicationStatusLabelForApprover[]
  min_amount?: number
  max_amount?: number
  from?: string
  to?: string
  audit_severity?: AuditSeverity[]
  process_status?: CounterbalanceStatus[]
  applicant?: string
  payee?: string
  include_deleted?: boolean
  include_expired?: boolean
  name_like?: string
  integration_status?: IntegrationStatus[]
  is_active?: boolean
  order?: string
}

export type ExpenseListSearchCondition = Pick<
  SearchQueryValueTypes,
  'application_status' | 'min_amount' | 'max_amount' | 'from' | 'to' | 'audit_severity'
>

export type RequestListSearchCondition = Pick<
  SearchQueryValueTypes,
  'application_status' | 'min_amount' | 'max_amount' | 'from' | 'to' | 'audit_severity'
>

export type ApproveExpenseListSearchCondition = Pick<
  SearchQueryValueTypes,
  | 'no_default'
  | 'approval_status'
  | 'min_amount'
  | 'max_amount'
  | 'from'
  | 'to'
  | 'audit_severity'
  | 'applicant'
>

export type ApproveRequestListSearchCondition = Pick<
  SearchQueryValueTypes,
  | 'no_default'
  | 'approval_status'
  | 'min_amount'
  | 'max_amount'
  | 'from'
  | 'to'
  | 'audit_severity'
  | 'applicant'
>

export type ApproveRoutesListSearchCondition = Pick<
  SearchQueryValueTypes,
  'no_default' | 'approval_status' | 'from' | 'to' | 'audit_severity' | 'applicant'
>

export type ApproveCommutingListSearchCondition = Pick<
  SearchQueryValueTypes,
  'no_default' | 'approval_status' | 'audit_severity' | 'applicant'
>

export type ApproveCommutingWeeklyListSearchCondition = Pick<
  SearchQueryValueTypes,
  'from' | 'to' | 'audit_severity' | 'integration_status'
>

export type ReceiptListSearchCondition = Pick<
  SearchQueryValueTypes,
  | 'process_status'
  | 'payee'
  | 'min_amount'
  | 'max_amount'
  | 'from'
  | 'to'
  | 'include_deleted'
  | 'order'
>

export type FilterPaymentSearchCondition = Pick<
  SearchQueryValueTypes,
  | 'process_status'
  | 'payee'
  | 'min_amount'
  | 'max_amount'
  | 'from'
  | 'to'
  | 'include_deleted'
  | 'order'
>

export type FilterTransportationCardListSearchCondition = Pick<
  SearchQueryValueTypes,
  'process_status' | 'min_amount' | 'max_amount' | 'from' | 'to' | 'include_deleted'
>

export type UserAdminSearchCondition = {
  user_id?: string
  no_default?: boolean
  include_inactive?: boolean
}

export type RoutesOfficesAddPageSearchCondition = Pick<SearchQueryValueTypes, 'name_like'>

export type ApplicationsOfCommutingPathSearchCondition = Pick<
  SearchQueryValueTypes,
  'application_status' | 'include_expired'
>

export type SearchCondition =
  | ExpenseListSearchCondition
  | RequestListSearchCondition
  | ApproveExpenseListSearchCondition
  | ApproveRequestListSearchCondition
  | ApproveRoutesListSearchCondition
  | FilterPaymentSearchCondition
  | FilterTransportationCardListSearchCondition
  | ReceiptListSearchCondition
  | RoutesOfficesAddPageSearchCondition
  | ApplicationsOfCommutingPathInput
