import createSvgIcon from '@mui/material/utils/createSvgIcon'

const InquiryRequestQuote = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12.4337 12.5V12.4904C11.4263 12.1902 10.5121 11.643 9.73409 10.8653C9.36119 10.4923 9.04809 10.0849 8.78279 9.6543H8.63939V10.6029H7.69079C7.42199 10.6029 7.19669 10.6938 7.01499 10.8756C6.83319 11.0574 6.74219 11.2827 6.74219 11.5514V14.3972C6.74219 14.666 6.83309 14.8913 7.01499 15.0731C7.19679 15.2549 7.42199 15.3458 7.69079 15.3458H10.5366V16.2944H6.74219V18.1916H8.63939V19.1402H10.5366V18.1916H11.4852C11.7539 18.1916 11.9792 18.1007 12.161 17.9189C12.3428 17.7371 12.4337 17.5118 12.4337 17.243V14.3972C12.4337 14.1285 12.3428 13.9032 12.161 13.7214C11.9792 13.5396 11.7539 13.4487 11.4852 13.4487H8.63939V12.5001L12.4337 12.5Z"
      fill="#6F738E"
    />
    <path
      d="M16.2367 12.4781C15.9291 12.574 15.609 12.6439 15.2801 12.6911V20.0887H3.8971V4.91136H7.9815C8.1221 4.23806 8.3715 3.60316 8.7181 3.01416H3.8971C3.3754 3.01416 2.9288 3.19996 2.5573 3.57146C2.1858 3.94296 2 4.38966 2 4.91136V20.0888C2 20.6105 2.1858 21.0572 2.5573 21.4287C2.9288 21.8002 3.3754 21.986 3.8971 21.986H15.2801C15.8018 21.986 16.2485 21.8002 16.62 21.4287C16.9915 21.0572 17.1773 20.6105 17.1773 20.0888V13.4188L16.2367 12.4781Z"
      fill="#6F738E"
    />
    <path
      d="M20.8222 13.9519L16.712 9.8417C16.38 10.0643 16.0138 10.2397 15.6133 10.368C15.2128 10.4962 14.7817 10.5604 14.3199 10.5604C13.1343 10.5604 12.1245 10.1433 11.2903 9.3092C10.4562 8.4751 10.0391 7.4654 10.0391 6.2802C10.0391 5.095 10.4562 4.0853 11.2903 3.2512C12.1244 2.4171 13.1341 2 14.3193 2C15.5045 2 16.5142 2.4171 17.3483 3.2512C18.1824 4.0853 18.5995 5.0952 18.5995 6.2808C18.5995 6.749 18.5354 7.1801 18.4071 7.5742C18.2789 7.9683 18.1035 8.3282 17.8808 8.6538L22.0006 12.7831C22.0006 12.7831 20.8223 13.9519 20.8222 13.9519ZM14.3193 8.9024C15.0514 8.9024 15.6715 8.6484 16.1795 8.1404C16.6875 7.6324 16.9415 7.0123 16.9415 6.2802C16.9415 5.5481 16.6875 4.928 16.1795 4.42C15.6715 3.912 15.0514 3.658 14.3193 3.658C13.5872 3.658 12.9671 3.912 12.4591 4.42C11.9511 4.928 11.6971 5.5481 11.6971 6.2802C11.6971 7.0123 11.9511 7.6324 12.4591 8.1404C12.9671 8.6484 13.5872 8.9024 14.3193 8.9024Z"
      fill="#6F738E"
    />
  </svg>,
  'InquiryRequestQuote'
)

export default InquiryRequestQuote
