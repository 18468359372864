import { useCallback, useMemo, useState } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material'
import MuiDialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import _, { cloneDeep } from 'lodash'
import { stringify } from 'qs'
import { Helmet } from 'react-helmet-async'
import type { UseFormReturn } from 'react-hook-form'
import { useController, useWatch } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { Properties } from '@/components/admin/Properties'
import { ApprovalFlows } from '@/components/admin/user/ApprovalFlows'
import { Dialog } from '@/components/Dialog'
import {
  FormActionType,
  NavigationInterceptDialog,
} from '@/components/dialog/NavigationInterceptDialog'
import { Divider } from '@/components/Divider'
import type { User } from '@/components/inputs/UserAutoComplete'
import { UserAutoComplete } from '@/components/inputs/UserAutoComplete'
import { NestedSelectDivisionForm } from '@/components/NestedSelectDivisionForm'
import type {
  DivisionDetailFragment,
  EmployeeDataPermissionInput,
  EmployeeDataPermissionScope,
  UserImporterInput,
} from '@/gen/graphql'
import { DivisionKind, Permission } from '@/gen/graphql'
import { useBool } from '@/hooks'
import { defaultUserImporterFormValues, useUserImporterForm } from '@/hooks/forms/userImporter'
import { useExportUsers } from '@/hooks/graphql/admin'
import { fetchUserByEmployeeId, useImportUsers } from '@/hooks/graphql/page'
import { useNavigationInterceptor } from '@/hooks/useNavigationInterceptor'
import SaveIcon from '@/icons/Save_F0_W400_S24'
import { _adminUser } from '@/lib/paths'
import { LoadingPage } from '@/pages/LoadingPage'

export function UserAdminCreatePage() {
  const [employeeId, setEmployeeId] = useState<string>()

  const initialValues: UserImporterInput = useMemo(
    () => ({
      data: {
        isActive: true,
        employeeId: employeeId ?? '',
        hidden: false,
      },
      divisions: [],
      approvalFlows: [],
      permissions: [
        {
          permissions: [Permission.ApplyExpense],
          scope: {
            __typename: 'EmployeeDataPermissionScopeEmployeeId',
            employeeId: employeeId ?? '',
          },
        },
      ],
    }),
    [employeeId]
  )
  return (
    <>
      <EmployeeidInputDialog open={!employeeId} onClose={setEmployeeId} />
      <Helmet>
        <title>SAPPHIRE 経費精算 - 管理</title>
      </Helmet>
      {employeeId && <Inner initialValues={initialValues} defaultDivisionAncestors={[]} />}
    </>
  )
}

function EmployeeidInputDialog({
  open,
  onClose,
}: {
  open: boolean
  onClose: (id: string) => void
}) {
  const [employeeId, setEmployeeId] = useState<string>('')
  const [userExist, setUserExist] = useState(false)

  const handleSubmit = useCallback(async () => {
    try {
      setUserExist(false)
      await fetchUserByEmployeeId(employeeId)
      setUserExist(true)
    } catch {
      onClose(employeeId)
    }
  }, [employeeId, onClose])

  return (
    <Dialog open={open}>
      <DialogTitle>従業員ID入力</DialogTitle>
      <DialogContent sx={{ pt: 2 }}>
        <TextField
          data-cy="cy-admin-user-create-employee-id"
          fullWidth
          label="従業員ID"
          value={employeeId}
          onChange={(e) => setEmployeeId(e.target.value)}
        />
        {userExist && (
          <Typography color="error" variant="caption">
            入力された ID は既に使われています。
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleSubmit}>
          決定
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export function UserAdminSavePage() {
  const { id } = useParams()
  const res = useExportUsers({ searchCondition: id ? { user_id: id, include_inactive: true } : {} })

  if (!res) return <LoadingPage />
  const user = res.data.data[0]
  const initialValues: UserImporterInput = {
    data: _.omit(user?.data, ['id']),
    divisions:
      user?.divisions.map((d) => {
        return _.omit(d, ['ancestors'])
      }) ?? [],
    approvalFlows: user?.approvalFlows ?? [],
    permissions: user?.permissions ?? [],
  }
  return (
    <>
      <Helmet>
        <title>SAPPHIRE 経費精算 - 管理</title>
      </Helmet>
      <Inner
        key={stringify(res.data.data[0])}
        initialValues={initialValues}
        defaultDivisionAncestors={
          user?.divisions.find((d) => d.kind === DivisionKind.Default)?.ancestors ?? []
        }
      />
    </>
  )
}

interface InnerProps {
  initialValues?: UserImporterInput
  defaultDivisionAncestors: DivisionDetailFragment[]
}

function Inner({ initialValues, defaultDivisionAncestors }: InnerProps) {
  const importUsers = useImportUsers()
  const nav = useNavigate()
  const methods = useUserImporterForm(initialValues ?? defaultUserImporterFormValues)

  const { triggerNav } = useNavigationInterceptor({
    state: methods.formState.isDirty,
  })

  const {
    reset,
    getValues,
    setValue,
    register,
    formState: { isValid },
  } = methods

  const isActive = useWatch({
    control: methods.control,
    name: 'data.isActive',
  })

  const properties = useWatch({
    control: methods.control,
    name: 'data.properties',
  })

  return (
    <>
      <NavigationInterceptDialog
        when={methods.formState.isDirty}
        formType={FormActionType.Update}
      />
      <Toolbar disableGutters sx={{ pb: 2 }}>
        <Grid alignItems="center" container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Button onClick={() => nav(_adminUser())} startIcon={<ArrowBackIcon />} variant="text">
              従業員一覧
            </Button>
          </Grid>
          <Grid item>
            <Button
              data-cy="cy-admin-user-edit-save"
              onClick={async () => {
                const user = getValues()
                //usernameフィールドを削除、Oneof inout objectのため。
                user.approvalFlows = user.approvalFlows.map((flow) => ({
                  ..._.omit(flow, ['applicationType']),
                  nodes: flow.nodes.map((node) =>
                    _.omit(node, ['user', '__typename', '_listKeyId', 'group'])
                  ),
                }))
                user.permissions = user.permissions.map((p) => ({
                  ...p,
                  scope: p.scope ? _.omit(p.scope, ['__typename', 'division', 'user']) : undefined,
                }))
                await importUsers([user])
                reset(getValues())
                triggerNav(() => nav(_adminUser()))
              }}
              startIcon={<SaveIcon />}
              variant="contained"
              disabled={!isValid}
            >
              保存
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
      <Stack spacing={3}>
        <Card>
          <CardContent>
            <Grid container direction={'row'} spacing={3}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      data-cy="cy-admin-user-edit-active"
                      checked={isActive}
                      onClick={() => {
                        setValue('data.isActive', !isActive, { shouldDirty: true })
                      }}
                    />
                  }
                  label="アクティブ"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...register('data.employeeId')}
                  data-cy="cy-admin-user-edit-employeeId"
                  fullWidth
                  required
                  label="従業員ID"
                  variant="outlined"
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  data-cy="cy-admin-user-edit-email"
                  {...register('data.email')}
                  fullWidth
                  required
                  label="メールアドレス"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  data-cy="cy-admin-user-edit-nameJp"
                  {...register('data.nameJp')}
                  fullWidth
                  required
                  label="名前 (日本語)"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  data-cy="cy-admin-user-edit-nameEn"
                  {...register('data.nameEn')}
                  fullWidth
                  required
                  label="名前 (ローマ字)"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Divider orientation="horizontal" flexItem />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" sx={{ mb: 2 }}>
                  所属組織
                </Typography>
                <DivisionSelector initDivisions={defaultDivisionAncestors} methods={methods} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Permissions methods={methods} />
        <ApprovalFlows methods={methods} />
        <Card>
          <CardHeader title="プロパティ" titleTypographyProps={{ variant: 'subtitle2' }} />
          <CardContent>
            <Properties
              properties={properties ?? {}}
              onChange={(obj) => {
                setValue('data.properties', obj, { shouldDirty: true })
              }}
            />
          </CardContent>
        </Card>
      </Stack>
    </>
  )
}

function DivisionSelector({
  initDivisions,
  methods,
}: {
  initDivisions: DivisionDetailFragment[]
  methods: UseFormReturn<UserImporterInput>
}) {
  const [innerDivisions, setInnerDivisions] = useState<DivisionDetailFragment[]>(initDivisions)

  const {
    field: { value: divisions, onChange: setDivisions },
  } = useController({ name: 'divisions', control: methods.control })

  return (
    <NestedSelectDivisionForm
      value={innerDivisions}
      data-cy={`cy-admin-user-divisions-select`}
      onChange={(value: DivisionDetailFragment[]) => {
        setInnerDivisions(value)

        const lastDivision = value[value.length - 1]
        if (!lastDivision) {
          setDivisions([])
          return
        }
        // DeepCopy
        const divs = cloneDeep(divisions)
        // ShallowCopy
        const changed = divs.find((d) => d.kind === DivisionKind.Default)
        if (changed) {
          // change ShallowCopy value
          changed.code = lastDivision.code
        } else {
          divs.push({
            code: lastDivision.code,
            kind: DivisionKind.Default,
          })
        }
        setDivisions(divs)
      }}
      spacing={1}
    />
  )
}

interface PermissionsProps {
  methods: UseFormReturn<UserImporterInput>
}

function Permissions({ methods }: PermissionsProps) {
  const { setValue, getValues } = methods
  const [isUserDialogOpen, openUserDialog, closeUserDialog] = useBool()
  const [isDivisionDialogOpen, openDivisionDialog, closeDivisionDialog] = useBool()
  const permissionsByScope = useWatch({ control: methods.control, name: 'permissions' })

  const {
    data: { employeeId },
  } = getValues()

  const columns: [string, Permission[]][] = [
    ['領収書・レシート', [Permission.Receipt]],
    ['連携データ', [Permission.Payment]],
    ['経費申請', [Permission.ApplyExpense]],
    ['事前申請', [Permission.ApplyRequest]],
    ['通勤経路', [Permission.ApplyRoute]],
    ['通勤手当', [Permission.ApplyCommuting]],
    ['経費申請承認', [Permission.ApproveExpense]],
    ['経費申請編集承認', [Permission.EditAndApproveExpense]],
    ['事前申請承認', [Permission.ApproveRequest]],
    ['事前申請編集承認', [Permission.EditAndApproveRequest]],
    ['通勤経路承認', [Permission.ApproveRoute]],
    ['通勤手当承認', [Permission.ApproveCommuting]],
    ['経費申請を照会', [Permission.ExpenseAudit]],
    ['事前申請を照会', [Permission.RequestAudit]],
    ['領収書・レシートを照会', [Permission.ReceiptAudit]],
    ['連携データを照会', [Permission.PaymentAudit]],
    ['通勤経路を照会', [Permission.RouteAudit]],
    ['通勤手当を照会', [Permission.CommutingAudit]],
  ]

  const permissionLabel = (permission: EmployeeDataPermissionScope | undefined) => {
    if (!permission) {
      return '*'
    }
    switch (permission.__typename) {
      case 'EmployeeDataPermissionScopeDivisionCode':
        const division = permission.division.find((d) => d.code === permission.divisionCode)
        if (!division) {
          return `組織: ${permission.divisionCode}`
        }
        return `組織: ${division?.name}`
      case 'EmployeeDataPermissionScopeEmployeeId':
        if (employeeId === permission.employeeId) {
          return 'me'
        }
        return `従業員: ${permission.user.name}`
    }
    return '*'
  }

  return (
    <Card>
      <AddDivision
        open={isDivisionDialogOpen}
        onClose={closeDivisionDialog}
        permissions={permissionsByScope}
        onChange={(v) => {
          setValue('permissions', [...permissionsByScope, v], { shouldDirty: true })
        }}
      />
      <AddUser
        open={isUserDialogOpen}
        onClose={closeUserDialog}
        permissions={permissionsByScope}
        onChange={(v) => setValue('permissions', [...permissionsByScope, v], { shouldDirty: true })}
      />
      <CardHeader
        title={'権限'}
        titleTypographyProps={{ variant: 'subtitle2' }}
        action={
          <>
            <Button
              onClick={() => {
                setValue(
                  'permissions',
                  [
                    ...permissionsByScope,
                    {
                      scope: undefined,
                      permissions: [],
                    },
                  ],
                  { shouldDirty: true }
                )
              }}
            >
              *
            </Button>
            <Button onClick={openDivisionDialog}>組織に対する権限を追加</Button>
            <Button onClick={openUserDialog}>ユーザーに対する権限を追加</Button>
          </>
        }
      />
      <CardContent>
        <Grid container direction={'row'} spacing={3}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ minWidth: '150px' }}>スコープ</TableCell>
                    {columns.map(([title, _]) => {
                      return <TableCell key={`permission-head-col-${title}`}>{title}</TableCell>
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {permissionsByScope.map((row, scope_idx) => (
                    <TableRow
                      key={stringify(row.scope)}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {permissionLabel(row.scope as EmployeeDataPermissionScope | undefined)}
                      </TableCell>
                      {columns.map(([title, permissionHeaders]) => {
                        return (
                          <TableCell key={`permission-body-col-${title}-${scope_idx}`}>
                            <Checkbox
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                let ps = cloneDeep(permissionsByScope)
                                if (event.target.checked) {
                                  ps[scope_idx].permissions = [
                                    ...permissionHeaders,
                                    ...ps[scope_idx].permissions,
                                  ]
                                  setValue('permissions', ps, { shouldDirty: true })
                                } else {
                                  ps[scope_idx].permissions = ps[scope_idx].permissions.filter(
                                    (p) => !permissionHeaders.includes(p)
                                  )
                                  setValue('permissions', ps, { shouldDirty: true })
                                }
                              }}
                              checked={permissionHeaders.some((p) => row.permissions.includes(p))}
                            />
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

interface AddProps {
  open: boolean
  onClose: () => void
  permissions: EmployeeDataPermissionInput[]
  onChange: (permissions: EmployeeDataPermissionInput) => void
}

function AddDivision({ open, onClose, permissions, onChange }: AddProps) {
  const [divisions, setDivisions] = useState<DivisionDetailFragment[]>([])

  return (
    <MuiDialog data-cy="cy-application-action-modal" open={open} onClose={onClose} fullWidth>
      <DialogTitle>追加</DialogTitle>
      <DialogContent>
        <NestedSelectDivisionForm value={divisions} spacing={1} onChange={setDivisions} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button
          disabled={divisions.length === 0}
          onClick={() => {
            const selected = divisions[divisions.length - 1]
            if (
              !permissions.find(
                (p) => p.scope?.divisionCode === divisions[divisions.length - 1]!.code
              )
            ) {
              onChange({
                scope: {
                  divisionCode: selected.code,
                  // @ts-ignore
                  __typename: 'EmployeeDataPermissionScopeDivisionCode',
                  // @ts-ignore
                  division: [{ code: selected.code, name: selected.name }],
                },
                permissions: [],
              })
            }
            onClose()
          }}
        >
          追加
        </Button>
      </DialogActions>
    </MuiDialog>
  )
}

function AddUser({ open, onClose, permissions, onChange }: AddProps) {
  const [user, setUser] = useState<User>()

  return (
    <MuiDialog data-cy="cy-application-action-modal" open={open} onClose={onClose} fullWidth>
      <DialogTitle>追加</DialogTitle>
      <DialogContent>
        <UserAutoComplete
          value={undefined}
          onSelect={(u) => {
            setUser(u)
          }}
          includeInactive
          minWordLength={2}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button
          disabled={!user}
          onClick={() => {
            if (!permissions.find((p) => p.scope?.employeeId === user!.employeeId)) {
              onChange({
                scope: {
                  employeeId: user!.employeeId,
                  // @ts-ignore
                  __typename: 'EmployeeDataPermissionScopeEmployeeId',
                  // @ts-ignore
                  user: { name: user!.name },
                },
                permissions: [],
              })
            }
            onClose()
          }}
        >
          追加
        </Button>
      </DialogActions>
    </MuiDialog>
  )
}
