import type { ReactElement, ReactNode } from 'react'
import { useContext, createContext } from 'react'

import { useGoogleTagManager } from '@/contexts//GoogleTagManager'
import type { MultitenantQuery } from '@/gen/graphql'
import { useMultitenant } from '@/hooks/graphql'
import { useUserGuiding } from '@/hooks/useUserGuiding'

const MultitenantContext = createContext({} as MultitenantQuery['multitenant'])
export const useMultiTenantContext = () => useContext(MultitenantContext)

// COMPONENT
interface MultitenantContextProviderProps {
  children: ReactNode
}

export function MultitenantContextProvider({
  children,
}: MultitenantContextProviderProps): ReactElement | null {
  const mt = useMultitenant()
  useGoogleTagManager(mt?.tagManager)
  useUserGuiding()
  if (!mt) return null

  return <MultitenantContext.Provider value={mt}>{children}</MultitenantContext.Provider>
}
