import type { ReactNode } from 'react'

import { CancelOutlined, InfoOutlined, WarningAmber } from '@mui/icons-material'
import type { AlertColor, SxProps } from '@mui/material'
import { Alert } from '@mui/material'

import { alertColor } from '@/lib/colors'

export interface MogokAlertProps {
  children: ReactNode
  severity: AlertColor
  sx?: SxProps
  colorType: 'primary' | 'accent'
}

export const MogokAlert = ({ children, severity, sx, colorType }: MogokAlertProps) => {
  const icon = getMogokAlertProps(severity, colorType)
  const backgroundColor = getBackgroundColor(severity, colorType)
  const overrideSx = { ...(backgroundColor && { backgroundColor }), ...sx }

  return (
    <Alert severity={severity} {...(icon && { icon })} {...(overrideSx && { sx: overrideSx })}>
      {children}
    </Alert>
  )
}

const getBackgroundColor = (
  severity: AlertColor,
  colorType: MogokAlertProps['colorType']
): string | undefined => {
  switch (severity) {
    case 'error':
      return alertColor[colorType].error.background
    case 'warning':
      return alertColor[colorType].warning.background
    case 'info':
      return alertColor[colorType].info.background
    default:
      return
  }
}

const getMogokAlertProps = (
  severity: AlertColor,
  colorType: MogokAlertProps['colorType']
): ReactNode | undefined => {
  switch (severity) {
    case 'error':
      return <CancelOutlined sx={{ color: alertColor[colorType].error.icon }} />
    case 'warning':
      return <WarningAmber sx={{ color: alertColor[colorType].warning.icon }} />
    case 'info':
      return <InfoOutlined sx={{ color: alertColor[colorType].info.icon }} />
    default:
      return
  }
}
