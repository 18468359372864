import type { ReactElement } from 'react'
import { createContext, useContext } from 'react'

import type { FilterPaymentParams, ReceiptsInput } from '@/gen/graphql'
import { CounterbalanceStatus } from '@/gen/graphql'
import { useNApprovable, useNApprovableCommutingApplicationGroup } from '@/hooks/countApprovable'
import { useNReceipt, useNPayment } from '@/hooks/graphql/page'
import { ApplicationPageKind, kindsOf } from '@/lib/types'

export interface BadgeCounterContextValue {
  paymentBadgeCount?: number
  receiptBadgeCount?: number
  approveRequestBadgeCount?: number
  uniqueUserCountOfApproveRequest?: number
  approveExpenseBadgeCount?: number
  uniqueUserCountOfApproveExpense?: number
  approveRouteBadgeCount?: number
  approveCommutingBadgeCount?: number
  refetch: () => void
}

const BadgeCounterContext = createContext<BadgeCounterContextValue>({} as BadgeCounterContextValue)

export const useBadgeCounterContext = () => useContext(BadgeCounterContext)

export function BadgeCounterContextProvider({
  children,
}: {
  children: React.ReactNode
}): ReactElement | null {
  const defaultPaymentParams: FilterPaymentParams = {
    statuses: [CounterbalanceStatus.NotYet],
    isActive: true,
  }
  const paymentCount = useNPayment(defaultPaymentParams)

  const defaultReceiptParams: ReceiptsInput = {
    statuses: [CounterbalanceStatus.NotYet],
  }
  const receiptCount = useNReceipt(defaultReceiptParams)

  const approvableCountResult = useNApprovable()

  const requestCountResult = approvableCountResult?.data.nApprovable.filter((count) =>
    kindsOf(ApplicationPageKind.Request).includes(count.kind)
  )

  const expenseCountResult = approvableCountResult?.data.nApprovable.filter((count) =>
    kindsOf(ApplicationPageKind.Expense).includes(count.kind)
  )

  const routeCountResult = approvableCountResult?.data.nApprovable.filter((count) =>
    kindsOf(ApplicationPageKind.CommutingPath).includes(count.kind)
  )

  const commutingCountResult = useNApprovableCommutingApplicationGroup()

  const context: BadgeCounterContextValue = {
    paymentBadgeCount:
      paymentCount?.data &&
      paymentCount.data.nCreditCardStatements.notYet +
        paymentCount.data.nJrExpressStatements.notYet +
        paymentCount.data.nTransportationCardStatements.notYet +
        paymentCount.data.nBplusResults.notYet,
    receiptBadgeCount: receiptCount?.data && receiptCount?.data.nReceipt.notYet,
    approveRequestBadgeCount: requestCountResult?.reduce((a, b) => a + b.nApplications, 0),
    uniqueUserCountOfApproveRequest: requestCountResult?.reduce((a, b) => a + b.nUsers, 0),
    approveExpenseBadgeCount: expenseCountResult?.reduce((a, b) => a + b.nApplications, 0),
    uniqueUserCountOfApproveExpense: expenseCountResult?.reduce((a, b) => a + b.nUsers, 0),
    approveRouteBadgeCount: routeCountResult?.reduce((a, b) => a + b.nApplications, 0),
    approveCommutingBadgeCount:
      commutingCountResult?.data.nApprovableCommutingApplicationGroup.reduce(
        (a, b) => a + b.nGroups,
        0
      ),
    refetch: () => {
      paymentCount?.refetch()
      receiptCount?.refetch()
      approvableCountResult?.refetch()
      commutingCountResult?.refetch()
    },
  }

  return <BadgeCounterContext.Provider value={context}>{children}</BadgeCounterContext.Provider>
}
