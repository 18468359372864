import { toJSTDate } from '@/utils/date'

export type Month = {
  year: number
  month: number
}

export function parseMonth(year_month: string): Month | null {
  const [year, month] = year_month.split('-')
  if (year && month && year.length === 4 && month.length === 2) {
    const y = parseInt(year)
    const m = parseInt(month)
    if (!Number.isNaN(y) && !Number.isNaN(m)) {
      return { year: y, month: m }
    }
  }
  return null
}

export const getMonthFromDate = (d: Date): Month => {
  return { year: d.getFullYear(), month: d.getMonth() + 1 }
}

export const thisMonth = (): Month => {
  return getMonthFromDate(new Date())
}

export const addToMonth = (month: Month, months: number): Month => {
  let m = month.month + months
  let y = month.year
  while (m < 0 || m > 12) {
    if (m < 0) {
      m += 12
      y--
    } else {
      m -= 12
      y++
    }
  }
  return { year: y, month: m }
}

export const formatMonth = (month: Month): string => {
  return `${month.year}-${month.month.toString().padStart(2, '0')}`
}

export const formatMonthJp = (month: Month): string => {
  return `${month.year}年${month.month}月`
}

export const toStartOfMonthDate = (month: Month): Date => {
  return toJSTDate(new Date(month.year, month.month - 1, 1))
}

export const toEndOfMonthDate = (month: Month): Date => {
  return toJSTDate(new Date(month.year, month.month, 0))
}

export const compareMonth = (a: Month, b: Month): number => {
  return a.year * 12 + a.month - (b.year * 12 + b.month)
}
