import { useEffect, useMemo } from 'react'
import type { FC } from 'react'

import {
  BusinessOutlined,
  FeedOutlined,
  GroupOutlined,
  PersonOutlined,
  VerifiedUser,
} from '@mui/icons-material'
import { Grid, Box, Drawer, Typography } from '@mui/material'
import type { Theme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import { Divider } from '@/components/Divider'
import { useAuthContext } from '@/contexts/Auth'
import { useBadgeCounterContext } from '@/contexts/BadgeCounter'
import { useMultiTenantContext } from '@/contexts/Multitenant'
import type { FeatureConfig } from '@/gen/graphql'
import { FeatureConfigState } from '@/gen/graphql'
import { useUserPolicy } from '@/hooks/userProfile'
import CalendarMonth from '@/icons/CalendarMonth'
import { DatasetLinked } from '@/icons/DatasetLinked'
import { DirectionsSubwayFilled } from '@/icons/DirectionsSubwayFilled'
import { FactCheck } from '@/icons/FactCheck'
import InquiryCommuting from '@/icons/InquiryCommuting'
import InquiryDatasetLinked from '@/icons/InquiryDatasetLinked'
import InquiryFactCheck from '@/icons/InquiryFactCheck'
import InquiryReceiptLong from '@/icons/InquiryReceiptLong'
import InquiryRequestQuote from '@/icons/InquiryRequestQuote'
import InquiryRoute from '@/icons/InquiryRoute'
import { ReceiptLongFilled } from '@/icons/ReceiptLongFilled'
import { RequestQuote } from '@/icons/RequestQuote'
import { ShoninJizenCheck } from '@/icons/ShoninJizenCheck'
import { ShoninKeihiCheck } from '@/icons/ShoninKeihiCheck'
import { ShoninRouteCheck } from '@/icons/ShoninRouteCheck'
import { ShoninTsukinCheck } from '@/icons/ShoninTsukinCheck'
import { TransferWithinAStation } from '@/icons/TransferWithinAStation'

import Logo from '../Logo'
import NavSection from '../NavSection'

interface DashboardSidebarProps {
  onMobileClose: () => void
  openMobile: boolean
}

type SectionItem = {
  title: string
  path: string
  icon: JSX.Element
  hasNotifications: boolean
  badgeCount: number
  subTitle?: string
  excludeOnPathMatch?: string[]
  featureConfig?: keyof Omit<FeatureConfig, '__typename'>
}

type Section = {
  title: string
  items: SectionItem[]
}

const _sections: Section[] = [
  // {
  //   title: '全般',
  //   items: [
  //     {
  //       title: 'ダッシュボード',
  //       path: '/dashboard',
  //       icon: <DashboardIcon fontSize="small" />,
  //       hasNotifications: false,
  //     },
  //   ],
  // },
  {
    title: '経費',
    items: [
      {
        title: '事前申請',
        path: '/request',
        icon: <FactCheck fontSize="small" />,
        hasNotifications: false,
        badgeCount: 0,
      },
      {
        title: '経費精算',
        path: '/expense',
        icon: <RequestQuote fontSize="small" />,
        hasNotifications: false,
        badgeCount: 0,
      },
    ],
  },
  {
    title: '通勤手当',
    items: [
      {
        title: '通勤手当',
        path: '/commuting',
        icon: <DirectionsSubwayFilled fontSize="small" />,
        hasNotifications: false,
        badgeCount: 0,
        excludeOnPathMatch: [`/commuting/past/${new Date().getFullYear()}`],
        featureConfig: 'useCommute',
      },
      {
        title: '通勤手当',
        subTitle: '過去月',
        path: `/commuting/past/${new Date().getFullYear()}`,
        icon: <CalendarMonth fontSize="small" />,
        hasNotifications: false,
        badgeCount: 0,
        featureConfig: 'useCommute',
      },
      {
        title: '通勤経路',
        path: '/route',
        icon: <TransferWithinAStation fontSize="small" />,
        hasNotifications: false,
        badgeCount: 0,
        featureConfig: 'useCommute',
      },
    ],
  },

  {
    title: '支払い・決済',
    items: [
      {
        title: '連携データ',
        path: '/payment',
        icon: <DatasetLinked fontSize="small" />,
        hasNotifications: false,
        badgeCount: 0,
      },
      {
        title: '領収書・レシート',
        path: '/receipt',
        icon: <ReceiptLongFilled fontSize="small" />,
        hasNotifications: false,
        badgeCount: 0,
      },
    ],
  },
  {
    title: '承認',
    items: [
      {
        title: '事前申請',
        path: '/approve/request',
        icon: <ShoninJizenCheck fontSize="small" />,
        hasNotifications: false,
        badgeCount: 0,
      },
      {
        title: '経費精算',
        path: '/approve/expense',
        icon: <ShoninKeihiCheck fontSize="small" />,
        hasNotifications: false,
        badgeCount: 0,
      },
      {
        title: '通勤手当',
        path: '/approve/commuting',
        icon: <ShoninTsukinCheck fontSize="small" />,
        hasNotifications: false,
        badgeCount: 0,
        featureConfig: 'useCommute',
      },
      {
        title: '通勤経路',
        path: '/approve/route',
        icon: <ShoninRouteCheck fontSize="small" />,
        hasNotifications: false,
        badgeCount: 0,
        featureConfig: 'useCommute',
      },
    ],
  },
  {
    title: '照会',
    items: [
      {
        title: '事前申請',
        path: '/activity/request',
        icon: <InquiryFactCheck fontSize="small" />,
        hasNotifications: false,
        badgeCount: 0,
      },
      {
        title: '経費精算',
        path: '/activity/expense',
        icon: <InquiryRequestQuote fontSize="small" />,
        hasNotifications: false,
        badgeCount: 0,
      },
      {
        title: '領収書・レシート',
        path: '/activity/receipt',
        icon: <InquiryReceiptLong fontSize="small" />,
        hasNotifications: false,
        badgeCount: 0,
      },
      {
        title: '連携データ',
        path: '/activity/payment',
        icon: <InquiryDatasetLinked fontSize="small" />,
        hasNotifications: false,
        badgeCount: 0,
      },
      {
        title: '通勤経路',
        path: '/activity/route',
        icon: <InquiryRoute fontSize="small" />,
        hasNotifications: false,
        badgeCount: 0,
        featureConfig: 'useCommute',
      },
      {
        title: '通勤手当',
        path: '/activity/commuting',
        icon: <InquiryCommuting fontSize="small" />,
        hasNotifications: false,
        badgeCount: 0,
        featureConfig: 'useCommute',
      },
    ],
  },
  {
    title: '管理',
    items: [
      {
        title: 'ユーザー',
        path: '/admin/user',
        icon: <PersonOutlined fontSize="small" />,
        hasNotifications: false,
        badgeCount: 0,
      },
      {
        title: '経費タイプ',
        path: '/admin/type',
        icon: <RequestQuote fontSize="small" />,
        hasNotifications: false,
        badgeCount: 0,
      },
      {
        title: 'グループ承認者',
        path: '/admin/approvergroup',
        icon: <GroupOutlined fontSize="small" />,
        hasNotifications: false,
        badgeCount: 0,
      },
      {
        title: 'カスタムフォーム',
        path: '/admin/customform',
        icon: <FeedOutlined fontSize="small" />,
        hasNotifications: false,
        badgeCount: 0,
      },
      {
        title: '所属',
        path: '/admin/division',
        icon: <BusinessOutlined fontSize="small" />,
        hasNotifications: false,
        badgeCount: 0,
      },
      {
        title: '監査ルール',
        path: '/admin/audit',
        icon: <VerifiedUser fontSize="small" />,
        hasNotifications: false,
        badgeCount: 0,
      },
    ],
  },
]

const useSections = () => {
  const { accessible } = useUserPolicy()
  const { featureConfig } = useMultiTenantContext().config

  const {
    paymentBadgeCount,
    receiptBadgeCount,
    approveRequestBadgeCount,
    approveExpenseBadgeCount,
    approveRouteBadgeCount,
    approveCommutingBadgeCount,
  } = useBadgeCounterContext()
  return useMemo(() => {
    return _sections
      .map((section) => {
        const items = section.items
          .filter((item) => accessible(item.path))
          .filter((item) => {
            if (item.featureConfig) {
              return featureConfig[item.featureConfig] === FeatureConfigState.Enabled
            }
            return true
          })
        if (items.length === 0) return null
        const updateItems = items.map((item) => {
          switch (item.path) {
            case '/payment':
              return { ...item, badgeCount: paymentBadgeCount }
            case '/receipt':
              return { ...item, badgeCount: receiptBadgeCount }
            case '/approve/request':
              return { ...item, badgeCount: approveRequestBadgeCount }
            case '/approve/expense':
              return { ...item, badgeCount: approveExpenseBadgeCount }
            case '/approve/commuting':
              return { ...item, badgeCount: approveCommutingBadgeCount }
            case '/approve/route':
              return { ...item, badgeCount: approveRouteBadgeCount }
            default:
              return item
          }
        })
        return { ...section, items: updateItems }
      })
      .filter((s): s is typeof _sections[0] => !!s)
  }, [
    accessible,
    featureConfig,
    paymentBadgeCount,
    receiptBadgeCount,
    approveRequestBadgeCount,
    approveExpenseBadgeCount,
    approveCommutingBadgeCount,
    approveRouteBadgeCount,
  ])
}

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props
  const location = useLocation()
  const { user } = useAuthContext()
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
  const sections = useSections()

  useEffect(() => {
    onMobileClose()
  }, [location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: {
            lg: 'none',
            xs: 'flex',
          },
          justifyContent: 'center',
          p: 2,
        }}
      >
        <RouterLink to="/">
          <Logo
            sx={{
              height: 40,
              width: 40,
            }}
          />
        </RouterLink>
      </Box>
      <Box sx={{ p: 2 }}>
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: '#F8F9FD', // FIXME: 現在この色の定義がないので直接指定
            borderRadius: '6px',
            display: 'flex',
            overflow: 'hidden',
            p: 2,
          }}
        >
          <Box sx={{ ml: 2 }}>
            <Typography color="textPrimary" variant="subtitle2">
              {user?.name}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              ID: {user?.employeeId}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Divider type="Line1" />

      <Box sx={{ pt: 2, px: 2, pb: 6 }}>
        {sections.map((section) => (
          <NavSection
            key={section.title}
            pathname={location.pathname}
            sx={{
              '& + &': {
                mt: 3,
              },
            }}
            {...section}
          />
        ))}
      </Box>
      {/*
        XXX: トライアルでは表示しないので封印
        <Divider />
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            component={RouterLink}
            fullWidth
            sx={{ mt: 2 }}
            to="/docs"
            variant="contained"
          >
            ヘルプ
          </Button>
        </Box>
      */}
    </Box>
  )

  if (lgUp) {
    return (
      <Grid item xs={2}>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 2 / 12,
              borderRight: 'none',
            },
          }}
          variant="permanent"
        >
          {content}
        </Drawer>
      </Grid>
    )
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  )
}

export default DashboardSidebar
