import type { ReactNode, ReactElement } from 'react'
import { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

import { useAuthContext } from '@/contexts/Auth'
import { useUsingYayoiSso, type Auth } from '@/hooks/auth'
import { read } from '@/lib/cookie'
import { _login } from '@/lib/paths'

interface AuthGuardProps {
  children: ReactNode
}

const renderable = (auth: Auth) => {
  return auth.loggedIn && auth.user && auth.provider
}

export function AuthGuard({ children }: AuthGuardProps): ReactElement | null {
  const auth = useAuthContext()
  const navigate = useNavigate()
  const usingYayoiSso = useUsingYayoiSso()

  useEffect(() => {
    if (renderable(auth)) return

    if (usingYayoiSso) {
      // YSATが含まれているか？
      if (!read('YSAT')) {
        window.location.replace(
          `https://${process.env.REACT_APP_YAYOI_ACCOUNT_DOMAIN}/login?service_id=${
            process.env.REACT_APP_YAYOI_NEXT_EXPENSE_SERVICE_ID
          }&success=${encodeURIComponent(window.location.href)}`
        )
      } else {
        return
      }
    } else {
      navigate(`//${process.env.REACT_APP_SERVICE_ROOT_DOMAIN}${_login()}`)
    }
  }, [auth, navigate, usingYayoiSso])

  return renderable(auth) ? <>{children}</> : null
}
