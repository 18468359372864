import { useState } from 'react'
import React from 'react'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import { stringify } from 'qs'
import type { UseFormReturn } from 'react-hook-form'
import { useFieldArray, useForm } from 'react-hook-form'

import { Dialog } from '@/components/Dialog'
import { DialogContentWithTitle } from '@/components/DialogContentWithTitle'
import { Divider } from '@/components/Divider'
import { UserAutoComplete } from '@/components/inputs'
import { ApplicationTypeAutoComplete } from '@/components/inputs/ApplicationTypeAutoComplete'
import { ApproverGroupAutoComplete } from '@/components/inputs/ApproverGroupAutoComplete'
import type {
  ApplicationKind,
  ApprovalFlowByApplicationType,
  ApprovalFlowByApplicationTypeInput,
  ApproverImporter,
  ApproverImporterGroup,
  ApproverImporterUser,
  UserImporterInput,
} from '@/gen/graphql'
import { applicationKindToLabel } from '@/lib/types'

interface ApprovalFlowsProps {
  methods: UseFormReturn<UserImporterInput>
}

export function ApprovalFlows({ methods }: ApprovalFlowsProps) {
  const { watch } = methods

  const [openEdit, setOpenEdit] = useState<ApprovalFlowByApplicationTypeInput | null>(null)

  const [openCreate, setOpenCreate] = useState(false)

  const deepestNode =
    watch('approvalFlows')
      ?.sort((a, b) => {
        if (a.nodes.length < b.nodes.length) {
          return -1
        }
        if (a.nodes.length > b.nodes.length) {
          return 1
        }
        return 0
      })
      ?.at(-1)?.nodes ?? []

  const allKinds = Array.from(new Set(watch('approvalFlows').map((flow) => flow.kind)))

  const noParentKinds = allKinds.filter((kind) => {
    return !watch('approvalFlows').find((flow) => flow.kind === kind && !flow.code)
  })
  return (
    <>
      {openEdit && (
        <ApprovalFlowEditDialog
          methods={methods}
          flow={openEdit}
          allFlows={watch('approvalFlows')}
          open={!!openEdit}
          onClose={() => setOpenEdit(null)}
        />
      )}
      <ApprovalFlowCreateDialog
        methods={methods}
        open={openCreate}
        onClose={() => setOpenCreate(false)}
      />
      <Card>
        <CardHeader
          title="承認フロー"
          titleTypographyProps={{ variant: 'subtitle2' }}
          action={[
            <Button key={'approval-flow-add-kind-btn'} onClick={() => setOpenCreate(true)}>
              種別を追加
            </Button>,
          ]}
        />
        <CardContent>
          <Grid container direction={'row'} spacing={3}>
            <Grid item xs={12}>
              <TableContainer component={Paper} sx={{ minWidth: 600, whiteSpace: 'nowrap' }}>
                <Table sx={{ minWidth: 650 }} aria-label="apploval flow table">
                  <TableHead>
                    <TableRow>
                      <TableCell>種別</TableCell>
                      <TableCell width={'60px'} />
                      {deepestNode.map((_, idx) => (
                        <TableCell key={`approval-flow-head-${idx}`}>承認者{idx + 1}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {watch('approvalFlows')
                      .filter((flow) => !flow.code)
                      .map((flow, idx) => {
                        const children = watch('approvalFlows').filter(
                          (v) => !!v.code && v.kind === flow.kind
                        )

                        return (
                          <ApprovalFlowRow
                            setOpenEdit={setOpenEdit}
                            parent={flow}
                            children={children}
                            maxNodeLength={deepestNode.length}
                          />
                        )
                      })}
                    {noParentKinds.map((kind) => {
                      const children = watch('approvalFlows').filter(
                        (v) => !!v.code && v.kind === kind
                      )
                      return (
                        <ApprovalFlowRow
                          isTmp
                          setOpenEdit={setOpenEdit}
                          parent={{ kind: kind, nodes: [] }}
                          children={children}
                          maxNodeLength={deepestNode.length}
                        />
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

interface ApprovalFlowCreateDialogProps {
  methods: UseFormReturn<UserImporterInput>
  open: boolean
  onClose: () => void
}

function ApprovalFlowCreateDialog({ open, onClose, methods }: ApprovalFlowCreateDialogProps) {
  const { watch, setValue, getValues, reset } =
    useForm<{ kind: ApplicationKind; code: string; name: string }>()

  const onApprovalFlowCreate = () => {
    const isDuplicated = methods
      .getValues('approvalFlows')
      .some((flow) => flow.kind === getValues('kind') && flow.code === (getValues('code') || null))
    if (isDuplicated) {
      reset()
      onClose()
      return
    }

    const parent = methods
      .getValues('approvalFlows')
      .find((flow) => !flow.code && flow.kind === getValues('kind'))

    methods.setValue(
      'approvalFlows',
      [
        ...methods.getValues('approvalFlows'),
        {
          kind: getValues('kind'),
          code: getValues('code'),
          // @ts-ignore
          applicationType: {
            code: getValues('code'),
            name: getValues('name'),
          },
          nodes: parent?.nodes ?? [],
        },
      ],
      { shouldDirty: true }
    )
    reset()
    onClose()
  }

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
      <DialogContentWithTitle title={'新規承認フロー追加'} onClose={onClose}>
        <Stack spacing={2} divider={<Divider />}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>
                種別<span style={{ color: 'red' }}>*</span>
              </Typography>
              <Select
                value={watch('kind') ?? ''}
                fullWidth
                onChange={(e) => {
                  setValue('kind', e.target.value as ApplicationKind)
                }}
              >
                {Object.entries(applicationKindToLabel).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Typography>経費タイプ</Typography>
              <ApplicationTypeAutoComplete
                value={watch('code')}
                onSelect={(t) => {
                  if (!t) return
                  setValue('code', t.code)
                  setValue('name', t.name)
                }}
              />
            </Grid>
          </Grid>
        </Stack>
      </DialogContentWithTitle>
      <DialogActions sx={{ bgcolor: '#F9F8FA' }}>
        <Button variant="outlined" onClick={onClose}>
          キャンセル
        </Button>
        <Button variant="contained" disabled={!watch('kind')} onClick={onApprovalFlowCreate}>
          追加
        </Button>
      </DialogActions>
    </Dialog>
  )
}
enum ApprovalFlowAddState {
  NONE,
  USER,
  GROUP,
}

interface ApprovalFlowEditDialogProps {
  open: boolean
  onClose: () => void
  allFlows: ApprovalFlowByApplicationTypeInput[]
  flow: ApprovalFlowByApplicationTypeInput
  methods: UseFormReturn<UserImporterInput>
}

function ApprovalFlowEditDialog({
  methods,
  open,
  onClose,
  flow,
  allFlows,
}: ApprovalFlowEditDialogProps) {
  const { control } = useForm({
    defaultValues: {
      nodes: flow.nodes as ApproverImporter[],
    },
    mode: 'onSubmit',
  })
  const { fields, update, append, remove } = useFieldArray({
    control,
    name: 'nodes',
    keyName: '_listKeyId',
  })

  const [approverAddState, setApproverAddState] = useState(ApprovalFlowAddState.NONE)

  const ApprovalFlowNodeSelector = (node: ApproverImporter, idx: number) => {
    switch (node.__typename) {
      case 'ApproverImporterUser':
        return (
          <UserAutoComplete
            defaultValue={{ employeeId: node.employeeId, name: node.user.name }}
            onSelect={(u) => {
              if (!u) return
              update(idx, {
                __typename: 'ApproverImporterUser',
                employeeId: u.employeeId,
                // @ts-ignore
                user: {
                  name: u.name,
                },
              })
            }}
            value={{ employeeId: node.employeeId ?? '' }}
          />
        )
      case 'ApproverImporterGroup':
        return (
          <ApproverGroupAutoComplete
            value={{ code: node.groupCode }}
            defaultValue={{ data: { code: node.groupCode, name: node.group.name } }}
            onSelect={(g) => {
              if (!g) return
              update(idx, {
                __typename: 'ApproverImporterGroup',
                groupCode: g.data.code,
                //@ts-ignore
                group: { name: g.data.name },
              })
            }}
          />
        )
    }
  }

  const approvalFlowNodeLabel = (node: ApproverImporter, idx: number) => {
    switch (node.__typename) {
      case 'ApproverImporterUser':
        return `承認者 (フロー${idx})`
      case 'ApproverImporterGroup':
        return `承認グループ (フロー${idx})`
    }
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={() => {
        setApproverAddState(ApprovalFlowAddState.NONE)
        onClose()
      }}
    >
      <DialogContentWithTitle
        title={flow.code ? flow.code : applicationKindToLabel[flow.kind!]}
        onClose={() => {
          setApproverAddState(ApprovalFlowAddState.NONE)
          onClose()
        }}
      >
        <Stack divider={<Divider />} spacing={1}>
          {fields.length !== 0 ? (
            fields.map((node, idx) => (
              <Box key={`flow-edit-dialog-${idx}`}>
                <Typography>{approvalFlowNodeLabel(node, idx + 1)}</Typography>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={11}>
                    {ApprovalFlowNodeSelector(node, idx)}
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton onClick={() => remove(idx)}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            ))
          ) : (
            <Typography>承認フローが設定されていません。</Typography>
          )}
        </Stack>
        <Divider type="Line1" sx={{ my: 2 }} />
        {approverAddState !== ApprovalFlowAddState.USER ? (
          <Button
            size={'large'}
            onClick={() => {
              setApproverAddState(ApprovalFlowAddState.USER)
            }}
          >
            <AddCircleOutlineIcon />
            承認者(個人)を追加
          </Button>
        ) : (
          <Grid item md={11}>
            <UserAutoComplete
              value={undefined}
              onSelect={(u) => {
                if (!u) return

                const approver: ApproverImporterUser = {
                  __typename: 'ApproverImporterUser',
                  employeeId: u.employeeId,
                  // @ts-ignore
                  user: { name: u.name },
                }
                append(approver)
                setApproverAddState(ApprovalFlowAddState.NONE)
              }}
            />
          </Grid>
        )}
        {approverAddState !== ApprovalFlowAddState.GROUP ? (
          <Button
            size={'large'}
            onClick={() => {
              setApproverAddState(ApprovalFlowAddState.GROUP)
            }}
          >
            <AddCircleOutlineIcon />
            承認者(グループ)を追加
          </Button>
        ) : (
          <Grid item md={11}>
            <ApproverGroupAutoComplete
              onSelect={(g) => {
                if (!g) return

                const approver: ApproverImporterGroup = {
                  __typename: 'ApproverImporterGroup',
                  groupCode: g.data.code,
                  // @ts-ignore
                  group: { name: g.data.name },
                }
                append(approver)
                setApproverAddState(ApprovalFlowAddState.NONE)
              }}
            />
          </Grid>
        )}
      </DialogContentWithTitle>
      <DialogActions sx={{ bgcolor: '#F9F8FA' }}>
        <Button variant="outlined" onClick={onClose}>
          キャンセル
        </Button>
        <Button
          disabled={fields.length === 0}
          data-cy="cy-admin-user-approver-flow-edit-save"
          variant="contained"
          onClick={() => {
            methods.setValue(
              'approvalFlows',
              allFlows.map((value) => {
                if (value.kind === flow.kind && value.code === flow.code) {
                  return {
                    ...flow,
                    nodes: fields,
                  }
                }

                return value
              }),
              { shouldDirty: true }
            )
            onClose()
          }}
        >
          保存
        </Button>
      </DialogActions>
    </Dialog>
  )
}

interface ApprovalFlowChildProps {
  children: ApprovalFlowByApplicationTypeInput[]
  parent: ApprovalFlowByApplicationTypeInput
  maxNodeLength: number
  isTmp?: boolean
  setOpenEdit: (val: ApprovalFlowByApplicationTypeInput | null) => void
}

function ApprovalFlowRow({
  parent,
  children,
  maxNodeLength,
  setOpenEdit,
  isTmp,
}: ApprovalFlowChildProps) {
  const [showChildren, setShowChildren] = useState(false)

  function getApproverName(node: ApproverImporter) {
    switch (node.__typename) {
      case 'ApproverImporterUser':
        return node.user.name
      case 'ApproverImporterGroup':
        return `${node.group.name}(グループ)`
    }
  }

  function fillWithEmptyCell(nodes: Array<ApproverImporter>, length: number) {
    const approverCells = [
      ...nodes.map((node) => (
        <TableCell key={`approval-flow-col-${stringify(node)}`}>
          <Typography>{getApproverName(node)}</Typography>
        </TableCell>
      )),
    ]

    for (let i = nodes.length; i < length; i++) {
      approverCells.push(<TableCell key={`approval-flow-col-${stringify(nodes)}-empty-${i}`} />)
    }
    return approverCells
  }

  const approverFlowLabelForChild = (applicationType: ApprovalFlowByApplicationType) => {
    if (applicationType.applicationType) {
      return (
        <>
          <Typography>
            {applicationType.applicationType.name}
            <span style={{ color: 'gray', fontSize: '0.7rem' }}>
              ({applicationType.applicationType.code})
            </span>
          </Typography>
        </>
      )
    }
    return applicationType.code ?? ''
  }

  return (
    <React.Fragment key={`flow-body-row-${parent.kind}`}>
      <TableRow key={`flow-${parent.kind}`}>
        <TableCell>
          <Box sx={{ display: 'flex', alignItem: 'center' }}>
            {children.length !== 0 &&
              (showChildren ? (
                <KeyboardArrowUpIcon
                  onClick={() => setShowChildren(!showChildren)}
                  sx={{ cursor: 'pointer' }}
                />
              ) : (
                <KeyboardArrowDownIcon
                  onClick={() => setShowChildren(!showChildren)}
                  sx={{ cursor: 'pointer' }}
                />
              ))}
            {/*34px＝アイコン（24Px)＋Padding（10px）*/}
            <Typography style={{ marginLeft: children.length !== 0 ? '10px' : '34px' }}>
              {applicationKindToLabel[parent.kind!]}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          {isTmp ? (
            <Typography variant={'overline'}>
              編集するには種別[{applicationKindToLabel[parent.kind!]}]を追加して下さい
            </Typography>
          ) : (
            <Button
              size={'small'}
              onClick={() => setOpenEdit(parent)}
              data-cy={`cy-admin-user-approver-flow-edit-${parent.kind}`}
            >
              編集
            </Button>
          )}
        </TableCell>
        {fillWithEmptyCell(parent.nodes as Array<ApproverImporter>, maxNodeLength)}
      </TableRow>
      {showChildren &&
        children.map((child) => {
          return (
            <TableRow key={`flow-${child.kind}-${child.code}`}>
              <TableCell sx={{ pl: '65px' }}>
                {approverFlowLabelForChild(child as ApprovalFlowByApplicationType)}
              </TableCell>
              <TableCell>
                <Button
                  size={'small'}
                  onClick={() => setOpenEdit(child)}
                  data-cy={`cy-admin-user-approver-flow-edit-${child.kind}-${child.code}`}
                >
                  編集
                </Button>
              </TableCell>
              {fillWithEmptyCell(child.nodes as Array<ApproverImporter>, maxNodeLength)}
            </TableRow>
          )
        })}
    </React.Fragment>
  )
}
