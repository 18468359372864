import { useCallback, useState } from 'react'

import { filterApplicantsByNameQuery } from '@/api/gql'
import type { User } from '@/components/inputs/UserAutoComplete'
import { UserAutoComplete } from '@/components/inputs/UserAutoComplete'

const search = async (str: string): Promise<User[]> => {
  const res = await filterApplicantsByNameQuery(str)
  return res.filterApplicantsByName.map((d) => ({
    id: d.id,
    employeeId: d.employeeId,
    name: d.name,
    nameEn: d.nameEn || '',
    division: d.division?.isActive ? d.division.name : '',
  }))
}

interface FilterApplicantsProps {
  onChange: (userId?: string) => void
}

export function ApplicantSelect({ onChange }: FilterApplicantsProps) {
  const [user, setUser] = useState<User>()

  const handleSelect = useCallback(
    (u: User | undefined) => {
      setUser(u)
      onChange(u?.id)
    },
    [onChange]
  )

  return (
    <UserAutoComplete
      value={user}
      onSelect={handleSelect}
      minWordLength={1}
      onInputChange={search}
    />
  )
}
