import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

import { ReactComponent as SvgDirectionsSubwayFilled } from '@/icons/svg/directions_subway_filled.svg'

export function DirectionsSubwayFilled(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <SvgDirectionsSubwayFilled />
    </SvgIcon>
  )
}
