import createSvgIcon from '@mui/material/utils/createSvgIcon'

const InquiryReceiptLong = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M7.66211 9.73251H8.96601C8.59511 9.14931 8.32781 8.51631 8.16521 7.84521H7.66211V9.73251Z"
      fill="#6F738E"
    />
    <path
      d="M7.66211 12.5636H12.8662C11.7422 12.2869 10.7232 11.7181 9.87011 10.8654C9.80911 10.8044 9.75611 10.7389 9.69801 10.6763H7.66211V12.5636Z"
      fill="#6F738E"
    />
    <path
      d="M17.0986 13.2048V19.1692C17.0986 19.4366 17.008 19.6605 16.8268 19.8411C16.6463 20.0223 16.4223 20.1129 16.1549 20.1129C15.8875 20.1129 15.6636 20.0223 15.483 19.8411C15.3018 19.6606 15.2112 19.4366 15.2112 19.1692V16.3382H6.7183V5.95785H7.9891C8.0229 5.23175 8.1668 4.53715 8.423 3.88795L7.662 3.12695L6.2465 4.54245L4.831 3.12695V16.3382H2V19.1692C2 19.9556 2.2753 20.624 2.8257 21.1745C3.3761 21.725 4.0446 22.0002 4.8309 22.0002H16.1548C16.9412 22.0002 17.6096 21.725 18.16 21.1745C18.7104 20.624 18.9857 19.9556 18.9857 19.1692V15.0922L17.0986 13.2048ZM13.3239 20.1128H4.831C4.5636 20.1128 4.3394 20.0222 4.1581 19.841C3.9776 19.6605 3.8873 19.4365 3.8873 19.1691V18.2254H13.3239V20.1128Z"
      fill="#6F738E"
    />
    <path
      d="M20.9569 13.9519L16.8467 9.8417C16.5147 10.0643 16.1485 10.2397 15.748 10.368C15.3475 10.4962 14.9164 10.5604 14.4546 10.5604C13.269 10.5604 12.2592 10.1433 11.425 9.3092C10.5909 8.4751 10.1738 7.4654 10.1738 6.2802C10.1738 5.095 10.5909 4.0853 11.425 3.2512C12.2591 2.4171 13.2688 2 14.454 2C15.6392 2 16.6489 2.4171 17.483 3.2512C18.3171 4.0853 18.7342 5.0952 18.7342 6.2808C18.7342 6.749 18.6701 7.1801 18.5418 7.5742C18.4136 7.9683 18.2382 8.3282 18.0155 8.6538L22.1353 12.7831C22.1353 12.7831 20.957 13.9519 20.9569 13.9519ZM14.454 8.9024C15.1861 8.9024 15.8062 8.6484 16.3142 8.1404C16.8222 7.6324 17.0762 7.0123 17.0762 6.2802C17.0762 5.5481 16.8222 4.928 16.3142 4.42C15.8062 3.912 15.1861 3.658 14.454 3.658C13.7219 3.658 13.1018 3.912 12.5938 4.42C12.0858 4.928 11.8318 5.5481 11.8318 6.2802C11.8318 7.0123 12.0858 7.6324 12.5938 8.1404C13.1018 8.6484 13.7219 8.9024 14.454 8.9024Z"
      fill="#6F738E"
    />
  </svg>,
  'InquiryReceiptLong'
)

export default InquiryReceiptLong
