import type { FC } from 'react'

import { AppBar, Box, IconButton, Link, Toolbar, styled, useTheme } from '@mui/material'
import type { AppBarProps } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import { AccountPopover } from '@/components/forms/AccountPopover'
import { useUserProfileContext } from '@/contexts/Auth'
import { Logo } from '@/icons/Logo'
import MenuIcon from '@/icons/Menu'

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void
}

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 100,
})) as typeof AppBar

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const theme = useTheme()

  const {
    proxyStatus: { currentProxyUser },
  } = useUserProfileContext()

  const { onSidebarMobileOpen } = props

  return (
    <DashboardNavbarRoot variant={currentProxyUser ? 'appbarProxy' : 'appbarMain'}>
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: 'none',
            },
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <Link
          color={theme.palette.common.white}
          sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}
          component={RouterLink}
          to="/"
        >
          <Logo />
        </Link>
        <Box sx={{ flexGrow: 1, ml: 2 }} />
        {/* <LanguagePopover /> */}
        {/* <Box sx={{ ml: 1 }}>
          <ContentSearch />
        </Box>
        <Box sx={{ ml: 1 }}>
          <ContactsPopover />
        </Box>
        <Box sx={{ ml: 1 }}>
          <NotificationsPopover />
        </Box> */}
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  )
}

export default DashboardNavbar
