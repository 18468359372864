import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

import { ReactComponent as SvgFactCheck } from '@/icons/svg/fact_check.svg'

export function FactCheck(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <SvgFactCheck />
    </SvgIcon>
  )
}
