import type { ReactElement } from 'react'

import { ErrorBoundary } from 'react-error-boundary'
import { Outlet } from 'react-router-dom'

import { AuthGuard } from '@/components/AuthGuard'
import { ErrorFallbackContent } from '@/components/ErrorFallbackContent'
import { AuthContextProvider } from '@/contexts/Auth'
import { UserProfileContextProvider } from '@/contexts/Auth'
import { BadgeCounterContextProvider } from '@/contexts/BadgeCounter'
import { GlobalStateContextProvider } from '@/contexts/GlobalState'
import { MultitenantContextProvider } from '@/contexts/Multitenant'
import { NotificationContextProvider } from '@/contexts/Notification'
import { QueryClientContextProvider } from '@/contexts/QueryClient'
import useScrollReset from '@/hooks/useScrollReset'

export function PresetLayout(): ReactElement {
  useScrollReset()

  return (
    <NotificationContextProvider>
      <QueryClientContextProvider>
        <GlobalStateContextProvider>
          <ErrorBoundary FallbackComponent={ErrorFallbackContent}>
            <AuthContextProvider>
              <AuthGuard>
                <UserProfileContextProvider>
                  <MultitenantContextProvider>
                    <BadgeCounterContextProvider>
                      <Outlet />
                    </BadgeCounterContextProvider>
                  </MultitenantContextProvider>
                </UserProfileContextProvider>
              </AuthGuard>
            </AuthContextProvider>
          </ErrorBoundary>
        </GlobalStateContextProvider>
      </QueryClientContextProvider>
    </NotificationContextProvider>
  )
}
