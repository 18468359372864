import { useNApprovableCommutingApplicationGroupQuery, useNApprovableQuery } from '@/gen/graphql'

export const useNApprovable = () => {
  const result = useNApprovableQuery()
  if (!result) return null
  if (!result.data) return null

  const { data, refetch } = result
  return { data, refetch }
}

export const useNApprovableCommutingApplicationGroup = () => {
  const result = useNApprovableCommutingApplicationGroupQuery()
  if (!result) return null
  if (!result.data) return null
  const { data, refetch } = result
  return { data, refetch }
}
