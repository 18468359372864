import { useCallback, useEffect } from 'react'

import type { Blocker, BlockerFunction } from '@remix-run/router'
import { useBeforeUnload, useBlocker } from 'react-router-dom'

export function usePrompt(when: boolean | BlockerFunction): Blocker {
  const blocker = useBlocker(when)
  useEffect(() => {
    if (blocker.state === 'blocked' && !when) {
      blocker.reset()
    }
  }, [blocker, when])

  useBeforeUnload(
    useCallback(
      (event) => {
        if ((typeof when === 'boolean' && when) || (typeof when === 'function' && !!when)) {
          event.preventDefault()
          event.returnValue = 'Changes that you made may not be saved.'
        }
      },
      [when]
    ),
    { capture: true }
  )

  return blocker
}

declare interface InitialStateType {
  isActive: boolean
  onConfirm(): void
  resetConfirmation(): void
}

export function useConfirm(when: boolean | BlockerFunction): InitialStateType {
  const blocker = usePrompt(when)

  const resetConfirmation = () => {
    if (blocker.state === 'blocked') blocker.reset()
  }

  const onConfirm = () => {
    if (blocker.state === 'blocked') setTimeout(blocker.proceed, 0)
  }

  return {
    isActive: blocker.state === 'blocked',
    onConfirm,
    resetConfirmation,
  }
}
