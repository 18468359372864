import { useMemo } from 'react'

import { useUserProfileContext } from '@/contexts/Auth'
import { read } from '@/lib/cookie'
import { allowedBy, allowedForAll } from '@/utils/userPolicy'

export const useUserPolicy = () => {
  const { permissions } = useUserProfileContext()
  return useMemo(() => {
    return {
      accessible: (pathname: string) => {
        const actAs = read('actAs')
        let checkers = permissions
          .filter((p) => {
            if (!p.actAs) return true
            return p.actAs?.employeeId === (actAs ? actAs.employeeId : p.delegatedBy.employeeId)
          })
          .map((p) => allowedBy[p.permission])
        checkers.push(allowedForAll)
        return checkers.some((ch) => pathname.match(ch))
      },
    }
  }, [permissions])
}
