import { useCallback, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import type { CardProps, BoxProps } from '@mui/material'
import {
  Card,
  CardContent,
  Grid,
  Fab,
  Box,
  Typography,
  CircularProgress,
  IconButton,
} from '@mui/material'
import type { DropEvent, FileRejection } from 'react-dropzone'
import { useDropzone } from 'react-dropzone'

import { CardGenreDisplay, CardHeaderGenre } from '@/components/CardHeader'
import { useFileLocationQuery } from '@/gen/graphql'
import { useBool } from '@/hooks'
import { Download } from '@/icons/DownloadFromGoogle'
import { main as mainColor } from '@/lib/colors'
import { formatJST } from '@/utils/date'

import { MogokAlert } from '../_common/MogokAlert'

interface FileCardLayoutProps {
  filename: string
  size?: number
  date: Date
  onDownload?: () => void
  onClose?: () => void
}

function FileCardLayout({ filename, size, date, onDownload, onClose }: FileCardLayoutProps) {
  const mb = (size: number) => (size / 1024 / 1024).toFixed(2)
  const formattedDate = formatJST(date, 'yyyy年M月d日')

  const DocumentIcon = () => (
    <DescriptionOutlinedIcon
      sx={{
        color: '#888888',
        width: '48px',
        height: '48px',
      }}
    />
  )

  const rightGridSx = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bgcolor: '#EDEDED',
  }

  const dateTextProps = {
    fontSize: '14px',
    color: '#666666',
    lineHeight: '143%',
    sx: {
      ml: 1,
    },
  }

  const nameTextProps = {
    display: 'block',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '140%',
    color: '#333333',
    sx: {
      mt: 2,
    },
  }

  const sizeTextProps = {
    display: 'block',
    fontSize: '14px',
    fontWeight: 700,
    color: '#333333',
    lineHeight: '143%',
    sx: {
      mt: 1,
    },
  }

  const fabProps = {
    size: 'small' as const,
    sx: {
      top: '8px',
      right: '8px',
      backgroundColor: '#FFF',
      position: 'absolute',
      color: mainColor['L_C1'],
      width: '28px',
      height: '28px',
      minWidth: '28px',
      minHeight: '28px',
    },
  }

  return (
    <Card>
      <Grid container sx={{ minHeight: '140px', flexDirection: 'row-reverse' }}>
        <Grid item xs={8}>
          <CardContent sx={{ '&:last-child': { pb: 2 } }}>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <CardGenreDisplay cardHeaderGenre={CardHeaderGenre.ATTACHED_FILE} />
                <Typography {...dateTextProps}>{formattedDate}</Typography>
              </Box>
              {onClose && (
                <IconButton sx={{ p: 0 }} onClick={onClose}>
                  <CloseIcon sx={{ width: '14px', height: '14px' }} />
                </IconButton>
              )}
            </Box>
            <Typography {...nameTextProps}>{filename}</Typography>
            {size && <Typography {...sizeTextProps}>{mb(size)}MB</Typography>}
          </CardContent>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            position: 'relative',
            ...rightGridSx,
          }}
        >
          <DocumentIcon />
          {onDownload && (
            <Fab {...fabProps} onClick={onDownload}>
              <Download sx={{ width: '20px', height: '20px' }} />
            </Fab>
          )}
        </Grid>
      </Grid>
    </Card>
  )
}

interface FileCardProps extends CardProps {
  file: File
}

function FileCard({ file }: FileCardProps) {
  const { name, size, lastModified } = file

  return <FileCardLayout filename={name} size={size} date={new Date(lastModified)} />
}

type AlertPosition = 'top' | 'bottom'

interface DropzoneProps {
  onDrop: (file: File | null) => void
  alertPosition?: AlertPosition
}

function Dropzone({ onDrop, alertPosition }: DropzoneProps) {
  const [uploading, uploadStart, uploadEnd] = useBool(false)
  const [error, setError] = useState<string | null>(null)

  const MaxSize = 5 * 1024 * 1024 // 5MB

  const onDropZone = useCallback(() => {
    uploadStart()
    setError(null)
    onDrop(null)
  }, [uploadStart, onDrop, setError])

  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0]
      onDrop(file)
      uploadEnd()
    },
    [uploadEnd, onDrop]
  )

  const onDropRejected = useCallback(
    (rejectedFiles: FileRejection[], _: DropEvent) => {
      const { file } = rejectedFiles[0]
      const { size } = file

      if (size > MaxSize) {
        const MaxSizeMB = MaxSize / 1024 / 1024
        setError(`ファイルのサイズが大きすぎます(最大${MaxSizeMB.toFixed(2)}MB)`)
      } else {
        setError('ファイルの形式が正しくありません')
      }

      onDrop(null)
      uploadEnd()
    },
    [uploadEnd, onDrop, MaxSize]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    onDrop: onDropZone,
    maxSize: MaxSize,
    accept: {
      image: ['image/*'],
      text: ['text/plain'],
      pdf: ['application/pdf'],
      word: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
      excel: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
      powerpoint: ['application/vnd.openxmlformats-officedocument.presentationml.presentation'],
    },
  })

  const fontSx = {
    fontWeight: '400',
    fontSize: '16px',
    color: 'rgba(122, 122, 122, 1)',
  }

  const boxSx = (uploading: boolean): BoxProps['sx'] => ({
    p: 2,
    border: '1px dashed rgba(0, 0, 0, 0.4)',
    textAlign: 'center',
    borderRadius: '20px',
    height: { xs: '120px', sm: '200px', lg: '280px' },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'middle',
    cursor: 'pointer',
    backgroundColor: uploading ? 'rgba(80, 95, 210, 0.04)' : 'rgba(245, 245, 245, 1)',
  })

  return (
    <Box
      sx={{ display: 'flex', flexDirection: alertPosition === 'top' ? 'column-reverse' : 'column' }}
    >
      <Box data-cy="cy-docment-file-drop-zone" sx={boxSx(uploading)} {...getRootProps()}>
        {!uploading && (
          <>
            <input {...getInputProps()} />
            <Typography sx={fontSx}>
              ここにファイルをドラッグ
              <br />
              または タップ／クリックして
              <br />
              ファイルを選択
              <br />
              <br />
              対応形式: PDF/XLSX/DOCX/PNG/JPG/ など
              <br />
              ※1ファイル最大5MB
            </Typography>
          </>
        )}

        {uploading && (
          <Box>
            <Box display="flex" justifyContent="center" alignItems="center" mb={3}>
              <CircularProgress />
            </Box>

            <Typography color="#7A7A7A" sx={{ fontsize: '26px' }}>
              アップロードしています...
            </Typography>
          </Box>
        )}
      </Box>
      {error && (
        <MogokAlert
          severity="error"
          sx={{ whiteSpace: 'pre-wrap', ...(alertPosition === 'top' ? { mb: 1 } : { mt: 1 }) }}
          colorType="primary"
        >
          {error}
        </MogokAlert>
      )}
    </Box>
  )
}

interface FileLocationCardProps {
  fileLocationId: string
  onClose?: () => void
}

export function FileLocationCard({ fileLocationId, onClose }: FileLocationCardProps) {
  const { data } = useFileLocationQuery({ fileLocationId })
  if (!data) return null

  const { fileLocation } = data
  const { downloader, filename, uploadedAt } = fileLocation

  // downloader は GCS のURLであり、直接ダウンロードできないため blob を fetch してからダウンロード用のリンクを生成する
  const download = async () => {
    try {
      const response = await fetch(downloader)
      const blob = await response.blob()
      const downloadUrl = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = downloadUrl
      link.download = filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      throw error
    }
  }

  return (
    <FileCardLayout
      filename={filename}
      // file size は backend で取得できる可能性があるため、一旦保留にしています
      // size={size}
      date={new Date(uploadedAt)}
      onDownload={download}
      onClose={onClose}
    />
  )
}

interface DocumentDropzoneProps {
  file: File | null
  onDrop: (file: File | null) => void
  alertPosition?: AlertPosition
}

export function DocumentDropzone({ file, onDrop, alertPosition }: DocumentDropzoneProps) {
  return (
    <>
      {file && <FileCard file={file} />}
      {!file && <Dropzone onDrop={onDrop} alertPosition={alertPosition} />}
    </>
  )
}
