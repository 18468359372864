import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

import { ReactComponent as SvgTransferWithinAStation } from '@/icons/svg/transfer_within_a_station_filled.svg'

export function TransferWithinAStation(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <SvgTransferWithinAStation />
    </SvgIcon>
  )
}
