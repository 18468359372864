import '@/lib/cognito'
import '@/lib/NativeBridge'
import type { FC } from 'react'

import { CssBaseline, ThemeProvider } from '@mui/material'
import { LicenseInfo } from '@mui/x-license-pro'
import '@/i18n'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import useInitSentryEffect from '@/hooks/useInitSentryEffect'
import routes from '@/routes'
import { createCustomTheme } from '@/theme'

import 'react-awesome-lightbox/build/style.css'
import { NativeModeContextProvider } from './contexts/NativeMode'

const App: FC = () => {
  LicenseInfo.setLicenseKey(
    'ae15684459c3fcf33652e2aa7f34dc68Tz03NjU1OCxFPTE3Mjg4MDQ0MjIwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
  )
  const router = createBrowserRouter(routes)

  useInitSentryEffect()

  const theme = createCustomTheme()

  return (
    <ThemeProvider theme={theme}>
      <NativeModeContextProvider>
        <CssBaseline />
        <RouterProvider router={router} />
      </NativeModeContextProvider>
    </ThemeProvider>
  )
}

export default App
