import { useEffect, useState } from 'react'

import { useAuthContext } from '@/contexts/Auth'

/**
 * 1秒に1回×3回 スクリプトタグが読み込まれたかのチェックを行い、userGuidingが読み込まれなかった場合何もしない
 */
export const useUserGuiding = () => {
  let trialCount = 0

  const [isScriptLoaded, setIsScriptLoaded] = useState(false)
  const [intervalId, setIntervalId] = useState<ReturnType<typeof setInterval> | undefined>()

  const { user, provider } = useAuthContext()

  // userGuidingはGoogleTagManager内で定義されており、scriptの読み込みタイミングはReactのHookでは感知できない
  // userGuidingのロードが終わったかどうかを確認する
  useEffect(() => {
    if (isScriptLoaded) return
    if (!user) return
    if (!provider) return

    const intervalId = setInterval(() => {
      if (trialCount > 2 || isScriptLoaded) {
        clearInterval(intervalId)
      }
      const { userGuiding } = window as any | undefined
      if (!userGuiding) {
        trialCount += 1 // eslint-disable-line
        return
      }

      const { identify: identifyUser } = userGuiding as any
      if (!!identifyUser) {
        setIsScriptLoaded(true)
      } else {
        trialCount += 1 // eslint-disable-line
        return
      }
    }, 1000)
    setIntervalId(intervalId)
  }, [isScriptLoaded, provider, user?.employeeId]) // eslint-disable-line react-hooks/exhaustive-deps

  // userGuidingのロードが終わったら、初期化を行う
  useEffect(() => {
    if (!isScriptLoaded || !intervalId) return
    clearInterval(intervalId)

    if (!user || !provider)
      throw new Error(`user or provider is undefined... user: ${user}, provider: ${provider}`)

    const { userGuiding } = window as any
    const { identify: identifyUser } = userGuiding as any

    const id = `${provider}-${user.employeeId}`
    identifyUser(id, {
      name: user.name,
      employeeId: user.employeeId,
      org: provider,
    })
  }, [isScriptLoaded, user?.employeeId, provider]) // eslint-disable-line react-hooks/exhaustive-deps
}
