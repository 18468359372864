import type { DialogContentProps, SxProps } from '@mui/material'
import { DialogContent as MuiDialogContent } from '@mui/material'

import { DialogTitle } from '@/components/DialogTitle'

interface DialogContentWithTitleProps extends DialogContentProps {
  onClose?: () => void
  title: string
  titleSx?: SxProps
}

export function DialogContentWithTitle({
  onClose,
  title,
  children,
  titleSx,
  ...props
}: DialogContentWithTitleProps) {
  return (
    <>
      <DialogTitle title={title} onClose={onClose} sx={titleSx} />
      <MuiDialogContent
        sx={{
          mt: '16px',
          p: '0 16px 16px',
        }}
        {...props}
      >
        {children}
      </MuiDialogContent>
    </>
  )
}
