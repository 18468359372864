import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

import { ReactComponent as SvgAssociatedIC } from '@/icons/svg/associated_ic.svg'

export function AssociatedIC(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="-1 -1 24 24" {...props}>
      <SvgAssociatedIC />
    </SvgIcon>
  )
}
