import type { ReactElement, ReactNode } from 'react'

import { Button, Dialog, DialogActions, DialogTitle, Stack, Typography } from '@mui/material'

export interface ConfirmActionDialogProps {
  onCancel: () => void
  onConfirm?: () => void
  open: boolean
  text?: ReactNode | string
  icon: ReactElement
  title: string
}

export function ConfirmActionDialog({
  onCancel,
  onConfirm,
  open,
  text,
  icon,
  title,
}: ConfirmActionDialogProps): ReactElement {
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle sx={{ pt: 3 }}>
        <Stack direction={'row'} alignItems={'center'}>
          {icon}
          <Stack spacing={1}>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
              {title}
            </Typography>
            {text && (
              <Typography variant="subtitle2" sx={{ color: '#6C737F' }}>
                {text}
              </Typography>
            )}
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogActions sx={{ px: 3, pb: 3 }}>
        <Button
          data-cy={'cy-confirm-action-dialog-cancel'}
          variant={'text'}
          onClick={onCancel}
          sx={{ color: 'black' }}
        >
          キャンセル
        </Button>
        <Button
          data-cy={'cy-confirm-action-dialog-ok'}
          variant={'contained'}
          onClick={onConfirm}
          sx={{
            borderRadius: '12px',
            bgcolor: '#E55037',
            '&:hover': {
              bgcolor: '#C0402F',
            },
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}
