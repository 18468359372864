import type { ReactElement } from 'react'
import { useMemo } from 'react'

import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike'
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule'
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi'
import TrainIcon from '@mui/icons-material/Train'
import {
  Typography,
  Stack,
  Box,
  TextField,
  MenuItem,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from '@mui/material'

import type {
  NavitimeMove,
  NavitimePoint,
  PathItem as GraphQLPathItem,
  PathMove,
  PathPoint,
} from '@/gen/graphql'
import { TransportationType } from '@/gen/graphql'
import { BulletTrain } from '@/icons/BulletTrain'
import * as PreciseCalc from '@/utils/preciseCalclation'

import { distance, fare } from './String'

type PathItem = GraphQLPathItem & { key: string }

type TransportationTypesForEdge = Extract<
  TransportationType,
  TransportationType.Bicycle | TransportationType.PrivateCar | TransportationType.Walk
>

export const TRANSPORTATION_TYPES_FOR_EDGE: Record<
  TransportationTypesForEdge,
  { label: string; icon: ReactElement }
> = {
  [TransportationType.Bicycle]: { label: '自転車', icon: <DirectionsBikeIcon /> },
  [TransportationType.PrivateCar]: { label: 'マイカー', icon: <DirectionsCarIcon /> },
  [TransportationType.Walk]: { label: '徒歩', icon: <DirectionsWalkIcon /> },
}

export const TRANSPORTATION_TYPES: Record<
  TransportationType,
  { label: string; icon: ReactElement }
> = {
  [TransportationType.Bicycle]: { label: '自転車', icon: <DirectionsBikeIcon /> },
  [TransportationType.Bus]: { label: 'バス', icon: <DirectionsBusIcon /> },
  [TransportationType.PrivateCar]: { label: 'マイカー', icon: <DirectionsCarIcon /> },
  [TransportationType.Taxi]: { label: 'タクシー', icon: <LocalTaxiIcon /> },
  [TransportationType.Other]: { label: 'その他', icon: <HorizontalRuleIcon /> },
  [TransportationType.Train]: { label: '鉄道', icon: <TrainIcon /> },
  [TransportationType.Express]: { label: '特急', icon: <BulletTrain /> },
  [TransportationType.Walk]: { label: '徒歩', icon: <DirectionsWalkIcon /> },
}

interface InputProps {
  pathItem: NavitimeMove | PathMove
  onChange: (v: Partial<EditableFields>) => void
}

function InputTransportationType({
  pathItem,
  selections,
  onChange,
}: InputProps & {
  selections: typeof TRANSPORTATION_TYPES | typeof TRANSPORTATION_TYPES_FOR_EDGE
}): ReactElement {
  return (
    <TextField
      required
      select
      value={pathItem.transportationType}
      onChange={(e) => {
        const transportationType = e.target.value as TransportationType
        onChange({ transportationType })
      }}
      variant="outlined"
      data-cy="cy-input-transportation-type"
    >
      {Object.keys(selections).map((type) => (
        <MenuItem key={type} value={type}>
          <Typography variant="body2">
            {selections[type as keyof typeof selections].label}
          </Typography>
        </MenuItem>
      ))}
    </TextField>
  )
}

function InputFare({ pathItem, onChange }: InputProps): ReactElement {
  return (
    <TextField
      required
      type="number"
      value={pathItem.fare || ''}
      onChange={(e) => onChange({ fare: parseInt(e.target.value, 10) || undefined })}
      variant="outlined"
      InputProps={{
        startAdornment: <InputAdornment position="start">¥</InputAdornment>,
      }}
      data-cy="cy-input-fare"
    />
  )
}

function InputDistance({ pathItem, onChange }: InputProps): ReactElement {
  return (
    <TextField
      required
      type="number"
      value={pathItem.distance ? PreciseCalc.div(pathItem.distance, 1000) : ''}
      onChange={(e) =>
        // kmが入力されるが、m単位に変換するために1000倍する。浮動小数故の誤差を防ぐため固定小数で計算する
        onChange({ distance: PreciseCalc.mul(parseFloat(e.target.value), 1000) || undefined })
      }
      variant="outlined"
      InputProps={{
        endAdornment: <InputAdornment position="end">km</InputAdornment>,
      }}
      data-cy="cy-input-distance"
    />
  )
}

function InputUseCommuterPass({ pathItem, onChange }: InputProps): ReactElement {
  return (
    <FormControlLabel
      value={true}
      checked={pathItem.useCommuterPass}
      onChange={(_e, checked) => {
        onChange({ useCommuterPass: checked })
      }}
      control={<Checkbox color="primary" />}
      label="定期利用"
    />
  )
}

interface PathItemMoveProps {
  pathItem: NavitimeMove | PathMove
  editRestriction: Array<keyof EditableFields>
  transportationTypeSelections: typeof TRANSPORTATION_TYPES | typeof TRANSPORTATION_TYPES_FOR_EDGE
  onChange: (v: Partial<EditableFields>) => void
}

// FIXME: 移植元はsrc/components/inputs/InputTransportationStep.tsxなので移植が全部終わったら消す
function PathItemMove({
  pathItem,
  editRestriction,
  transportationTypeSelections,
  onChange,
}: PathItemMoveProps): ReactElement {
  const type = TRANSPORTATION_TYPES[pathItem.transportationType]

  const visibleRouteSummary =
    !editRestriction.includes('fare') &&
    !editRestriction.includes('distance') &&
    !editRestriction.includes('useCommuterPass')

  return (
    <Stack direction="row" spacing={2} sx={{ mt: 1, mb: 2 }}>
      <Box sx={{ pt: 1 }}>{type.icon}</Box>
      <Box>
        {editRestriction.includes('transportationType') ? (
          <>
            <InputTransportationType
              pathItem={pathItem}
              selections={transportationTypeSelections}
              onChange={onChange}
            />
            {/*  路線名とかが入るのでTrainの時だけは表示する */}
            {pathItem.transportationType === TransportationType.Train && <Box>{pathItem.name}</Box>}
          </>
        ) : (
          <>
            <Box>{type.label}</Box>
            {type.label !== pathItem.name && <Box>{pathItem.name}</Box>}
          </>
        )}
        {editRestriction.includes('fare') && (
          <Box>
            <Typography variant="subtitle1">金額</Typography>
            <InputFare pathItem={pathItem} onChange={onChange} />
          </Box>
        )}
        {editRestriction.includes('distance') && (
          <Box>
            <Typography variant="subtitle1">距離</Typography>
            <InputDistance pathItem={pathItem} onChange={onChange} />
          </Box>
        )}
        {editRestriction.includes('useCommuterPass') &&
          [TransportationType.Bus, TransportationType.Train].includes(
            pathItem.transportationType
          ) && <InputUseCommuterPass pathItem={pathItem} onChange={onChange} />}
        {visibleRouteSummary && (
          <Typography variant="body2">{`${fare(pathItem.fare)}（${distance(pathItem.distance)}${
            pathItem.useCommuterPass ? '・定期利用' : ''
          }）`}</Typography>
        )}
      </Box>
    </Stack>
  )
}

interface PathItemPointProps {
  pathItem: NavitimePoint | PathPoint
}

function PathItemPoint({ pathItem }: PathItemPointProps): ReactElement {
  return <Typography>{pathItem.name}</Typography>
}

type EditableFields = {
  transportationType: TransportationType
  name: string
  fare: number
  distance: number
  useCommuterPass: boolean
}

interface PathItemStepsProps {
  pathItems: PathItem[]
  onChangeMove?: (index: number, v: Partial<EditableFields>) => void
  onlyFirstTransportationTypeEditable?: boolean
}

export function PathItemSteps({
  pathItems,
  onChangeMove,
  onlyFirstTransportationTypeEditable = false,
}: PathItemStepsProps): ReactElement {
  const items = useMemo(() => {
    return pathItems.map((pathItem, index) => {
      const isFirstMove = index === 1

      const editRestriction: Array<keyof EditableFields> = onChangeMove
        ? onlyFirstTransportationTypeEditable
          ? isFirstMove
            ? ['transportationType']
            : ['useCommuterPass']
          : ['transportationType', 'fare', 'distance', 'useCommuterPass']
        : []

      const transportationTypeSelections = isFirstMove
        ? TRANSPORTATION_TYPES_FOR_EDGE
        : TRANSPORTATION_TYPES

      switch (pathItem.__typename) {
        case 'NavitimeMove': {
          const item: NavitimeMove = pathItem
          return {
            key: pathItem.key,
            component: (
              <PathItemMove
                pathItem={item}
                editRestriction={editRestriction}
                transportationTypeSelections={transportationTypeSelections}
                onChange={(v) => onChangeMove?.(index, v)}
              />
            ),
          }
        }
        case 'NavitimePoint': {
          const item: NavitimePoint = pathItem
          return {
            key: pathItem.key,
            component: <PathItemPoint pathItem={item} />,
          }
        }
        case 'PathMove': {
          const item: PathMove = pathItem
          return {
            key: pathItem.key,
            component: (
              <PathItemMove
                pathItem={item}
                editRestriction={editRestriction}
                transportationTypeSelections={transportationTypeSelections}
                onChange={(v) => onChangeMove?.(index, v)}
              />
            ),
          }
        }
        case 'PathPoint': {
          const item: PathPoint = pathItem
          return {
            key: pathItem.key,
            component: <PathItemPoint pathItem={item} />,
          }
        }
        default: {
          const unreachable: never | undefined = pathItem.__typename
          throw new Error(unreachable)
        }
      }
    })
  }, [pathItems, onlyFirstTransportationTypeEditable, onChangeMove])

  return (
    <>
      {items.map((item, index) => (
        <Box key={item.key} data-cy={`cy-path-items-step-${index}`}>
          {item.component}
        </Box>
      ))}
    </>
  )
}
