import type { ReactElement } from 'react'
import { useState } from 'react'
import { useMemo } from 'react'

// import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import LogoutIcon from '@mui/icons-material/Logout'
import {
  Popover,
  Avatar,
  IconButton,
  List,
  ListItem,
  Divider,
  Typography,
  Box,
  Button,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { filterProxyUsersQuery } from '@/api/gql'
import type { User } from '@/components/inputs/UserAutoComplete'
import { UserAutoComplete } from '@/components/inputs/UserAutoComplete'
import { useAuthContext, useUserProfileContext } from '@/contexts/Auth'
import { NativeModeContext } from '@/contexts/NativeMode'
import { useAnchor } from '@/hooks'
import type { ProxyUser } from '@/hooks/auth'
import { useSignOut } from '@/hooks/auth'
import { useBrowserState } from '@/hooks/useBrowserState'
import { main as mainColor, grey } from '@/lib/colors'

const intoUser = (proxyUser: ProxyUser): User => {
  return {
    id: proxyUser.id,
    employeeId: proxyUser.employeeId,
    name: proxyUser.name,
    nameEn: proxyUser.nameEn || '',
    division: proxyUser.division?.isActive ? proxyUser.division.name : '',
  }
}

const search = async (str: string): Promise<User[]> => {
  const res = await filterProxyUsersQuery(str)
  return res.proxyUsers.map(intoUser)
}

function SelectProxyRole(): ReactElement | null {
  const navigate = useNavigate()

  const {
    proxyStatus: { currentProxyUser, changeProxyUser },
  } = useUserProfileContext()

  // 代理ログイン処理の実行
  const handleChangeUser = async (user?: User) => {
    navigate('/')
    changeProxyUser(user)
  }

  const [value, handleSelect] = useState<User>()

  return (
    <>
      <UserAutoComplete
        value={currentProxyUser}
        onSelect={handleSelect}
        onInputChange={search}
        minWordLength={2}
      />
      <Button
        fullWidth
        sx={{ mt: 1 }}
        variant="contained"
        onClick={() => handleChangeUser(value)}
        disabled={!value}
      >
        決定
      </Button>
    </>
  )
}

function DiscardProxyRole(): ReactElement {
  const {
    proxyStatus: { changeProxyUser },
  } = useUserProfileContext()
  const nav = useNavigate()

  const handleExitProxyUser = () => {
    nav('/')
    changeProxyUser(undefined)
  }

  return (
    <Button
      sx={{
        py: 1,
        width: '100%',
        justifyContent: 'flex-start',
        color: mainColor.L_C5,
        fontColor: mainColor.L_C5,
        '&:hover': {
          backgroundColor: '#F3F3F3',
          fontColor: mainColor.L_C5,
        },
      }}
      onClick={handleExitProxyUser}
    >
      <LogoutIcon sx={{ mr: 2 }} />
      <Typography sx={{ py: 1, fontWeight: 400 }} variant="body1_2">
        セッションを終了
      </Typography>
    </Button>
  )
}

export function AccountPopover(): ReactElement {
  // FIXME: アプリ時にログアウトを表示にできるようになったら削除する
  const { isMobile } = useBrowserState()
  const { user } = useAuthContext()
  const {
    division,
    permissions,
    proxyStatus: { changeProxyUser, currentProxyUser },
  } = useUserProfileContext()

  const signOut = useSignOut()

  const proxyUsers = useMemo(() => {
    return Array.from(new Map(permissions.map((p) => [p.actAs?.id, p.actAs])).values())
  }, [permissions])

  const [anchor, open, close] = useAnchor()

  // proxy roleをクリアしてログアウトする
  const handleLogout = async () => {
    changeProxyUser(undefined)
    await signOut()
  }

  return (
    <>
      <Box sx={{ display: { xs: 'none', sm: 'none', md: 'inline-flex' } }}>
        {currentProxyUser && `${currentProxyUser.name} として代理ログイン中`}
      </Box>
      <IconButton data-cy="cy-account-popover-avatar" onClick={anchor ? close : open}>
        <Avatar sx={{ width: '32px', height: '32px', fontSize: '12px' }}>
          {currentProxyUser ? '代理' : undefined}
        </Avatar>
      </IconButton>
      <Popover
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        PaperProps={{ sx: { minWidth: '260px' } }}
      >
        <List>
          <ListItem sx={{ pt: 2, pb: 0 }}>
            <Typography variant="subtitle1" sx={{ display: 'block' }}>
              {user?.name}
            </Typography>
          </ListItem>
          <ListItem sx={{ pt: 0.5 }}>
            <Typography variant="body2_2">ID: {user?.employeeId}</Typography>
          </ListItem>
          {!currentProxyUser && (
            <ListItem sx={{ pt: 0.5 }}>
              <Typography variant="body2_2">部門: {division}</Typography>
            </ListItem>
          )}
          {/* FIXME: 暫定対応 NativeModeContext が正常に動作するようになったら、isMobile の判定を削除する */}
          {!isMobile && (
            <NativeModeContext.Consumer>
              {(nativeMode) =>
                nativeMode.mode === 'browser' && (
                  <ListItem>
                    <Button
                      sx={{
                        py: 1,
                        width: '100%',
                        justifyContent: 'flex-start',
                        color: mainColor.L_C5,
                        fontColor: mainColor.L_C5,
                        '&:hover': {
                          backgroundColor: '#F3F3F3',
                          fontColor: mainColor.L_C5,
                        },
                      }}
                      onClick={handleLogout}
                    >
                      <LogoutIcon sx={{ mr: 2 }} />
                      <Typography sx={{ py: 1, fontWeight: 400 }} variant="body1_2">
                        ログアウト
                      </Typography>
                    </Button>
                  </ListItem>
                )
              }
            </NativeModeContext.Consumer>
          )}
          {proxyUsers && proxyUsers.length > 0 && (
            <>
              <Divider sx={{ my: 0.5, py: 0.5 }} />
              <ListItem>
                <Box
                  sx={{
                    pb: 0.5,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    color: grey[60],
                    fontColor: grey[60],
                  }}
                >
                  <Typography>他のユーザーとして操作</Typography>
                  {/* ヘルプページができたら、クリックして遷移する */}
                  {/* <HelpOutlineOutlinedIcon sx={{ ml: 1 }} /> */}
                </Box>
              </ListItem>
              {!currentProxyUser && (
                <ListItem>
                  <Box sx={{ width: '100%' }}>
                    <SelectProxyRole />
                  </Box>
                </ListItem>
              )}
              {currentProxyUser && (
                <>
                  <ListItem sx={{ py: 0 }}>
                    <Typography variant="subtitle1">{currentProxyUser.name}</Typography>
                  </ListItem>
                  <ListItem sx={{ pt: 0.5, pb: 0 }}>
                    <Typography variant="body2_2">ID: {currentProxyUser.employeeId}</Typography>
                  </ListItem>
                  {currentProxyUser.division && (
                    <ListItem sx={{ pt: 0.5 }}>
                      <Typography variant="body2_2">部門: {currentProxyUser.division}　</Typography>
                    </ListItem>
                  )}
                  <ListItem>
                    <DiscardProxyRole />
                  </ListItem>
                </>
              )}
            </>
          )}
        </List>
      </Popover>
    </>
  )
}
