import * as Cognito from '@/lib/cognito'

/**
 * アプリ連携のためのブリッジ
 * アプリはWebViewでページがロードやリロードが完了したタイミングで以下の関数が呼ばれる
 * iosアプリは window.toModeIos()　が呼ばれる
 * androidアプリは window.toModeAndroid()　が呼ばれる
 */
const w = window as any | undefined

w.toModeIos = () => {
  Cognito.toModeIos()
  w?.dispatchEvent(new CustomEvent('nativeMode', { detail: { mode: 'ios' } }))
}

w.toModeAndroid = () => {
  Cognito.toModeAndroid()
  w?.dispatchEvent(new CustomEvent('nativeMode', { detail: { mode: 'android' } }))
}
