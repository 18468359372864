import CloseIcon from '@mui/icons-material/Close'
import type { DialogContentProps } from '@mui/material'
import { DialogTitle as MuiDialogTitle, Box, IconButton } from '@mui/material'

import { Divider } from '@/components/Divider'

interface DialogTitleProps extends DialogContentProps {
  onClose?: () => void
  title: string
}

export function DialogTitle({ onClose, title, ...props }: DialogTitleProps) {
  return (
    <>
      <Box
        data-cy="cy-dialog-title"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: '24px',
          ...props.sx,
        }}
      >
        <MuiDialogTitle sx={{ p: 0 }} color="textPrimary">
          {title}
        </MuiDialogTitle>
        {onClose && (
          <IconButton onClick={onClose} data-cy="cy-close-dialog">
            <CloseIcon />
          </IconButton>
        )}
      </Box>
      <Divider />
    </>
  )
}
