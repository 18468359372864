import { Amplify, Auth } from 'aws-amplify'

import { backdoor } from '@/api/backdoor'

if (!backdoor.isEnabled) {
  Amplify.configure({
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: process.env.REACT_APP_COGNITO_REGION,
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
      // OPTIONAL - Configuration for cookie storage
      cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: process.env.REACT_APP_COGNITO_COOKIE_DOMAIN,
      },

      oauth: {
        domain: process.env.REACT_APP_COGNITO_USER_POOL_AUTH_DOMAIN,
        scope: ['email', 'openid', 'profile'],
        redirectSignIn: `${window.location.protocol}//${process.env.REACT_APP_SERVICE_ROOT_DOMAIN}`,
        redirectSignOut: `${window.location.protocol}//${process.env.REACT_APP_SERVICE_ROOT_DOMAIN}`,
        responseType: 'code',
      },
    },
  })
}

type IdToken = {
  payload: { [key: string]: any }
  getJwtToken: () => string
}

export const getCurrentSession = () => Auth.currentSession()

let getIdTokenProc = async (): Promise<IdToken> => {
  const session = await getCurrentSession()
  return session.getIdToken()
}

let getIdTokenJwtProc = async (): Promise<string> => {
  const idToken = await getIdToken()
  return idToken.getJwtToken()
}

/**
 * getIdTokenJwtProc, getIdTokenProcの実装をios向けに変更
 */
export const toModeIos = () => {
  getIdTokenJwtProc = async (): Promise<string> => {
    return (window as any).webkit.messageHandlers['getJwt'].postMessage('')
  }
  getIdTokenProc = async (): Promise<IdToken> => {
    const json = await (window as any).webkit.messageHandlers['getIdToken'].postMessage('')
    return JSON.parse(json) as IdToken
  }
}

/**
 * getIdTokenJwtProc, getIdTokenProcの実装をandroid向けに変更
 */
export const toModeAndroid = () => {
  getIdTokenJwtProc = async (): Promise<string> => {
    // @ts-expect-error
    return MyApp['getJwt']()
  }
  getIdTokenProc = async (): Promise<IdToken> => {
    // @ts-expect-error
    const json = MyApp['getIdToken']()
    return JSON.parse(json) as IdToken
  }
}

export const getIdToken = async () => {
  return getIdTokenProc()
}

export const getIdTokenJwt = async (): Promise<string> => {
  return getIdTokenJwtProc()
}
