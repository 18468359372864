import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

import { ReactComponent as SvgSaveF0W400S24 } from '@/icons/svg/save_FILL0_wght400_GRAD0_opsz24.svg'

export default function Save_F0_W400_S24(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <SvgSaveF0W400S24 />
    </SvgIcon>
  )
}
