import { useCallback } from 'react'

import type { UseQueryOptions } from '@tanstack/react-query'
import { useQueryClient } from '@tanstack/react-query'
import { groupBy, isEmpty, isUndefined } from 'lodash'

import { fetcher } from '@/api/config'
import type { UploadedFile } from '@/components/receipt/dropzone/ReceiptDropZone'
import type {
  ExpenseListSearchCondition,
  RequestListSearchCondition,
  ApproveExpenseListSearchCondition,
  ApproveRequestListSearchCondition,
  ApproveRoutesListSearchCondition,
  ReceiptListSearchCondition,
  FilterPaymentSearchCondition,
  FilterTransportationCardListSearchCondition,
  ApproveCommutingListSearchCondition,
} from '@/components/search/types'
import { useBadgeCounterContext } from '@/contexts/BadgeCounter'
import type {
  CreateReceiptInput,
  FileLocation,
  SaveApplicationInput,
  UpdateCommutingPathInput,
  ApproveApplicationPageQuery,
  ApplicationActionInput,
  ReceiptsInput,
  FilterPaymentParams,
  PaymentListPageQuery,
  SaveGroupInput,
  CommutingPastDailyPageQueryVariables,
  CommutingDatePageQueryVariables,
  ApplicationGroupActionInput,
  ApplicationsForApproverInput,
  UserImporterInput,
  MyApplicationsInput,
  ApplicationTypeImporterInput,
  MoveApplicationGroupInput,
  NewTransportationCardStatement,
  DivisionImporterInput,
  NPaymentQuery,
  ApplicationsOfCommutingPathInput,
  UserByEmployeeIdQuery,
  UserByEmployeeIdQueryVariables,
} from '@/gen/graphql'
import {
  useRoutesPageQuery,
  useDeleteReceiptMutation,
  useDebuggerDeleteApplicationMutation,
  useCreateFileLocationMutation,
  useCreateFileLocationCustomNameMutation,
  useCreateReceiptMutation,
  useCreateReceiptOcrAnalysisMutation,
  useApproveAllowancePageQuery,
  usePaymentListPageQuery,
  useApplicationCreatePageGetApprovalFlowQuery,
  useFilterReceiptsQuery,
  usePaymentListPageSyncBplusResultMutation,
  useApplicationCreatePageGetBplusReservationQuery,
  useApplicationListQuery,
  useApproveApplicationsMutation,
  useApproveApplicationPageQuery,
  useApplicationQuery,
  useApplicationByApproverQuery,
  useApplicationGroupIDsQuery,
  useDeleteApplicationMutation,
  useApprovedCashAdvancesQuery,
  useRoutesCreatePageCreateCommutingPathMutation,
  useConsumableOffsetsQuery,
  useRoutePageUpdateCommutingPathMutation,
  useRoutesEditPageQuery,
  useSubmitApplicationsMutation,
  useRestoreDeletedReceiptMutation,
  useSaveAndSubmitGroupedApplicationsMutation,
  useSaveGroupedApplicationsMutation,
  useExpenseTypesForPlanndPayeeQuery,
  useAllowanceTypesQuery,
  useCommutingMonthlyPageQuery,
  useCommutingEditPageQuery,
  useCommutingPastPageQuery,
  useCommutingPastDailyPageQuery,
  useCommutingDatePageQuery,
  useApproveAllowanceCommutingGroupPageQuery,
  useCommutingDetailPageQuery,
  useRejectApplicationsMutation,
  useAddApplicationCommentMutation,
  AuditSeverity,
  useWithdrawApplicationsMutation,
  useSubmitApplicationGroupMutation,
  useAuditApplicationsBeforeSubmitQuery,
  useAuditGroupedApplicationsBeforeSubmitQuery,
  useCommutingMonthlyWorkLogsQuery,
  useImportUsersMutation,
  useUserByEmployeeIdQuery,
  useDeleteTransportCardStatementMutation,
  useRestoreTransportCardStatementMutation,
  useImportApplicationTypesMutation,
  useFilterTransportationCardStatementsQuery,
  useApproveApplicationGroupsMutation,
  useWithdrawApplicationGroupsMutation,
  useRejectApplicationGroupsMutation,
  useUpdateCommutingPathEndDateMutation,
  useMoveApplicationGroupMutation,
  ApplicationGroupKind,
  useSuicaFromBytesMutation,
  useSaveTransportCardStatementsMutation,
  useSaveDivisionsMutation,
  useCreateDivisionMutation,
  useNReceiptQuery,
  useNPaymentQuery,
  useNApplicationQuery,
  UserByEmployeeIdDocument,
} from '@/gen/graphql'
import type { Month } from '@/lib/month'
import { addToMonth, formatMonth } from '@/lib/month'
import { ApplicationPageKind, kindsOf } from '@/lib/types'
import type { GroupedApplicationsPerUser } from '@/lib/types'
import {
  fromExpenseListSearchCondition,
  fromReceiptListSearchCondition,
  fromRequestListSearchCondition,
  intoFilterPaymentParams,
} from '@/utils/admin/convert'
import { toYYYYMMDD } from '@/utils/date'

// queries
interface useApproveCommutingPageArgs {
  searchCondition: ApproveCommutingListSearchCondition
  month: Month
  page: number
}
export const useApproveCommutingPage = ({
  searchCondition,
  month,
  page,
}: useApproveCommutingPageArgs) => {
  const { approval_status, audit_severity, applicant } = searchCondition ?? {}

  const searchQuery: Pick<
    ApplicationsForApproverInput,
    'statuses' | 'severities' | 'applicant' | 'closingDateFrom' | 'closingDateTo'
  > = {
    statuses: approval_status,
    severities: isEmpty(audit_severity) ? undefined : audit_severity,
    applicant: isEmpty(applicant) ? undefined : applicant,
    closingDateFrom: `${formatMonth(month)}-01`,
    closingDateTo: `${formatMonth(addToMonth(month, 1))}-01`,
  }

  return useApproveCommutingGroupPage({
    kinds: kindsOf(ApplicationPageKind.Commuting),
    ...searchQuery,
    page,
    perPage: 5,
  })
}

export const useUserByEmployeeId = (employeeId: string) => {
  const { data } = useUserByEmployeeIdQuery({ employeeId })
  if (!data) return null
  return data.userByEmployeeId
}

export const fetchUserByEmployeeId = (employeeId: string) => {
  return fetcher<UserByEmployeeIdQuery, UserByEmployeeIdQueryVariables>(UserByEmployeeIdDocument, {
    employeeId,
  })()
}

export const useApproveCommutingGroupPage = (input: ApplicationsForApproverInput) => {
  const {
    data: rawData,
    isSuccess,
    refetch,
    isFetching,
  } = useApproveAllowanceCommutingGroupPageQuery({ input }, { keepPreviousData: true })
  if (!isSuccess || !rawData) return null
  // 検索API実装時、経費グループの数でpaginationしたいため、backendからVec<PagedApplication>ではなくPagedApplication
  // がかえってくるようになった。とりあえずここでこれまでの型 (userごとにgroupedApplicationsが分かれている) に変換
  const dataGroupedByUser = groupBy(
    rawData.monthlyApplicationsForApprover.data,
    (groupedApplication) => {
      return groupedApplication.applications[0].user.id
    }
  )

  const groupedApplicationsPerUser = Object.entries(dataGroupedByUser).map(
    ([user_id, groupedApplications]) => {
      const applicationsByUser = groupedApplications
        .map((groupedApp) => groupedApp.applications)
        .flat()

      const user = applicationsByUser[0].user
      if (user.id !== user_id) {
        throw new Error('invalid grouping logic.')
      }

      const auditCounts: GroupedApplicationsPerUser['auditCounts'] = applicationsByUser.reduce(
        (accum, current) => {
          const severities = current.audits.map((a) => a.severity).flat()

          accum[AuditSeverity.Warn].totalWarnings += severities.filter(
            (s) => s === AuditSeverity.Warn
          ).length
          accum[AuditSeverity.Error].totalErrors += severities.filter(
            (s) => s === AuditSeverity.Error
          ).length

          if (severities.includes(AuditSeverity.Warn)) {
            accum[AuditSeverity.Warn].daysWithWarnings++
          }
          if (severities.includes(AuditSeverity.Error)) {
            accum[AuditSeverity.Error].daysWithErrors++
          }

          return accum
        },
        {
          [AuditSeverity.Warn]: {
            daysWithWarnings: 0,
            totalWarnings: 0,
          },
          [AuditSeverity.Error]: {
            daysWithErrors: 0,
            totalErrors: 0,
          },
        }
      )
      const result: GroupedApplicationsPerUser = {
        user,
        groupedApplications,
        auditCounts,
      }
      return result
    }
  )

  const { totalPages, currentPage, totalCount } = rawData.monthlyApplicationsForApprover

  return {
    data: groupedApplicationsPerUser,
    refetch,
    totalPages,
    currentPage,
    totalCount,
    isFetching,
  }
}

interface useApproveAllowancePageArgs {
  employeeId: string | undefined
  date: Date | undefined
}
export const useApproveAllowancePage = ({ employeeId, date }: useApproveAllowancePageArgs) => {
  const { data, refetch, isSuccess } = useApproveAllowancePageQuery(
    date && employeeId
      ? { params: { employeeId, date: toYYYYMMDD(date) }, employeeId }
      : { params: { employeeId: '', date: '' }, employeeId: '' }
  )

  if (!data) return null
  if (!isSuccess) return null
  return { data: data.commutings, user: data.userByEmployeeId, refetch }
}

export const useApproveAllowanceDetailPage = ({
  employeeId,
  date,
}: useApproveAllowancePageArgs) => {
  const { data, refetch, isSuccess } = useCommutingDetailPageQuery(
    date && employeeId
      ? {
          commuting_params: { date: toYYYYMMDD(date), employeeId: employeeId },
          params: { date: toYYYYMMDD(date), employeeId: employeeId },
          date: toYYYYMMDD(date),
          employeeId: employeeId,
        }
      : {
          commuting_params: { date: '', employeeId: '' },
          params: { date: '', employeeId: '' },
          date: '',
          employeeId: '',
        },
    { enabled: !!date }
  )

  if (!data) return null
  if (!isSuccess) return null
  return { data, user: data.userByEmployeeId, refetch }
}

export const useApproveApplicationPage = (input: ApplicationsForApproverInput) => {
  const {
    data: rawData,
    isSuccess,
    refetch,
    isFetching,
  } = useApproveApplicationPageQuery({ input }, { keepPreviousData: true })

  if (!isSuccess || !rawData) return null
  // 検索API実装時、経費グループの数でpaginationしたいため、backendからVec<PagedApplication>ではなくPagedApplication
  // がかえってくるようになった。とりあえずここでこれまでの型 (userごとにgroupedApplicationsが分かれている) に変換
  const data = rawData.monthlyApplicationsForApprover.data.reduce(
    (groups, item) => {
      if (
        groups.length === 0 ||
        groups[groups.length - 1].user.id !== item.applications[0]?.user.id
      ) {
        groups.push({ user: item.applications[0].user, groupedApplications: [item] })
      } else {
        groups[groups.length - 1].groupedApplications.push(item)
      }
      return groups
    },
    [] as {
      user: ApproveApplicationPageQuery['monthlyApplicationsForApprover']['data'][number]['applications'][number]['user']
      groupedApplications: ApproveApplicationPageQuery['monthlyApplicationsForApprover']['data']
    }[]
  )

  const { totalPages, currentPage, totalCount } = rawData.monthlyApplicationsForApprover

  return { data, refetch, totalPages, currentPage, totalCount, isFetching }
}

export const useRoutesPage = (input: ApplicationsOfCommutingPathInput) => {
  const { data, isSuccess, refetch } = useRoutesPageQuery({ input }, { keepPreviousData: true })

  if (!isSuccess) return null
  return { data, refetch }
}

export const useFilterReceipts = (input: ReceiptsInput) => {
  const { data, refetch } = useFilterReceiptsQuery({ input }, { keepPreviousData: true })

  if (!data) return null
  return { data, refetch }
}

export const useRoutesEditPage = (applicationId?: string) => {
  const { data, isSuccess } = useRoutesEditPageQuery(
    { applicationId: applicationId! },
    { enabled: !!applicationId }
  )

  if (!isSuccess) return null
  return data
}

export const useApplicationListPage = (input: MyApplicationsInput) => {
  const { data, refetch } = useApplicationListQuery({ input }, { keepPreviousData: true })

  if (!data) return null
  return { data, refetch }
}

export const useNApplication = (input: MyApplicationsInput) => {
  const { data, refetch } = useNApplicationQuery({ input }, { keepPreviousData: true })

  if (!data) return null
  return { data, refetch }
}

interface useExpenseListPageArgs {
  searchCondition: ExpenseListSearchCondition
}

export const useExpenseListPage = ({ searchCondition }: useExpenseListPageArgs) => {
  const input = fromExpenseListSearchCondition(searchCondition)

  const { data, refetch } = useApplicationListQuery({ input }, { keepPreviousData: true })

  if (!data) return null
  return { data, refetch }
}

interface useRequestListPageArgs {
  searchCondition: RequestListSearchCondition
}

export const useRequestListPage = ({ searchCondition }: useRequestListPageArgs) => {
  const input = fromRequestListSearchCondition(searchCondition)

  const { data, refetch } = useApplicationListQuery({ input }, { keepPreviousData: true })

  if (!data) return null
  return { data, refetch }
}

interface useApproveExpensePageArgs {
  searchCondition: ApproveExpenseListSearchCondition
  page: number
}

export const useApproveExpensePage = ({ searchCondition, page }: useApproveExpensePageArgs) => {
  const { approval_status, min_amount, max_amount, from, to, audit_severity, applicant } =
    searchCondition ?? {}

  const searchQuery: Pick<
    ApplicationsForApproverInput,
    'statuses' | 'ge' | 'le' | 'from' | 'to' | 'severities' | 'applicant'
  > = {
    statuses: approval_status,
    ge: isUndefined(min_amount) ? undefined : String(min_amount),
    le: isUndefined(max_amount) ? undefined : String(max_amount),
    from,
    to,
    severities: isEmpty(audit_severity) ? undefined : audit_severity,
    applicant,
  }

  return useApproveApplicationPage({
    kinds: kindsOf(ApplicationPageKind.Expense),
    ...searchQuery,
    page,
    perPage: 20,
  })
}

interface useApproveRequestPageArgs {
  searchCondition: ApproveRequestListSearchCondition
  page: number
}

export const useApproveRequestPage = ({ searchCondition, page }: useApproveRequestPageArgs) => {
  const { approval_status, min_amount, max_amount, from, to, audit_severity, applicant } =
    searchCondition ?? {}

  const searchQuery: Pick<
    ApplicationsForApproverInput,
    'statuses' | 'ge' | 'le' | 'from' | 'to' | 'severities' | 'applicant'
  > = {
    statuses: approval_status,
    ge: isUndefined(min_amount) ? undefined : String(min_amount),
    le: isUndefined(max_amount) ? undefined : String(max_amount),
    from,
    to,
    severities: isEmpty(audit_severity) ? undefined : audit_severity,
    applicant,
  }

  return useApproveApplicationPage({
    kinds: kindsOf(ApplicationPageKind.Request),
    ...searchQuery,
    page,
    perPage: 20,
  })
}

interface useApproveRoutesPageArgs {
  searchCondition: ApproveRoutesListSearchCondition
  page: number
}

export const useApproveRoutesPage = ({ searchCondition, page }: useApproveRoutesPageArgs) => {
  const { approval_status, from, to, audit_severity, applicant } = searchCondition ?? {}

  const searchQuery: Pick<
    ApplicationsForApproverInput,
    'statuses' | 'from' | 'to' | 'severities' | 'applicant'
  > = {
    statuses: approval_status,
    from,
    to,
    severities: isEmpty(audit_severity) ? undefined : audit_severity,
    applicant,
  }

  return useApproveApplicationPage({
    kinds: kindsOf(ApplicationPageKind.CommutingPath),
    ...searchQuery,
    page,
    perPage: 5,
  })
}

interface useReceiptListPageArgs {
  searchCondition: ReceiptListSearchCondition
  page: number
}

export const useReceiptListPage = ({ searchCondition, page }: useReceiptListPageArgs) => {
  const input = fromReceiptListSearchCondition(searchCondition)

  return useFilterReceipts({
    ...input,
    page,
    perPage: 10,
  })
}

interface usePaymentListPageArgs {
  searchCondition: FilterPaymentSearchCondition
}

export const useFilterPaymentListPage = ({ searchCondition }: usePaymentListPageArgs) => {
  const params = intoFilterPaymentParams(searchCondition)
  return usePaymentListPage(params)
}

interface useFilterNPaymentArgs {
  searchCondition: FilterPaymentSearchCondition
}
export const useFilterNPayment = ({ searchCondition }: useFilterNPaymentArgs) => {
  const params = intoFilterPaymentParams(searchCondition)
  return useNPayment(params)
}

interface useFilterTransportationCardListArgs {
  searchCondition: FilterTransportationCardListSearchCondition
}
export const useFilterTransportationCardStatements = ({
  searchCondition,
}: useFilterTransportationCardListArgs) => {
  const params = intoFilterPaymentParams(searchCondition)
  const res = useFilterTransportationCardStatementsQuery({ params }, { keepPreviousData: true })
  if (!res) return null

  return res.data?.filterTransportationCardStatements
}

export const useApprovedCashAdvances = () => {
  const { data, isSuccess } = useApprovedCashAdvancesQuery({}, { keepPreviousData: true })

  if (!isSuccess) return null
  return { data }
}

export const useConsumableOffsets = () => {
  const { data, isSuccess } = useConsumableOffsetsQuery({}, { keepPreviousData: true })

  if (!isSuccess) return null
  return { data }
}

export const useApprovalFlow = (typeId?: string) => {
  const { data, isSuccess } = useApplicationCreatePageGetApprovalFlowQuery(
    { typeId },
    { enabled: !!typeId }
  )

  if (!isSuccess) return null
  return data
}

export const useApplicationCreatePageGetBplusReservation = (operationNumber: string) => {
  const { data, isSuccess, refetch } = useApplicationCreatePageGetBplusReservationQuery({
    operationNumber,
  })
  return { data, isSuccess, refetch }
}

export const useApplicationWithDetail = (applicationId?: string) => {
  return useApplicationQuery({ id: applicationId! }, { enabled: !!applicationId })
}

export const useApplicationWithDetailForApprover = (expenseId: string) => {
  return useApplicationByApproverQuery({ id: expenseId })
}

export const useDetailPagination = (input: MyApplicationsInput) => {
  const {
    data: rawData,
    isSuccess,
    refetch,
  } = useApplicationGroupIDsQuery({ input }, { keepPreviousData: true })

  if (!isSuccess || !rawData) return null
  if (rawData.myApplications.data.length === 0) return null

  return { data: rawData.myApplications.data[0], refetch: refetch }
}

export const useCommutingMonthlyPage = (date: Date) => {
  const { data, refetch, isSuccess } = useCommutingMonthlyPageQuery({
    params: { date: toYYYYMMDD(date) },
  })

  if (!data) return null
  if (!isSuccess) return null
  return { data: data.commutings, refetch }
}

export const useCommutingWorkLogs = ({
  from,
  to,
  employeeId,
  enabled = true,
}: {
  from?: Date
  to?: Date
  employeeId?: string
  enabled?: boolean
}) => {
  const fromStr = from ? toYYYYMMDD(from) : undefined
  const toStr = to ? toYYYYMMDD(to) : undefined
  const input = { from: fromStr, to: toStr, employeeId }
  const { data, refetch, isSuccess } = useCommutingMonthlyWorkLogsQuery({ input }, { enabled })

  if (!data) return null
  if (!isSuccess) return null
  return { data: data.commutingWorkLogs, refetch }
}

export const useCommutingEditPage = (date: Date | undefined) => {
  const { data, refetch, isSuccess, isFetching } = useCommutingEditPageQuery(
    date
      ? {
          group_params: { date: toYYYYMMDD(date) },
          params: { date: toYYYYMMDD(date) },
          date: toYYYYMMDD(date),
        }
      : { group_params: { date: '' }, params: { date: '' }, date: '' },
    { enabled: !!date }
  )

  if (isFetching) return null
  if (!data) return null
  if (!isSuccess) return null
  return { data, refetch }
}

export const useCommutingDatePage = (date: Date | undefined) => {
  const { data, refetch, isSuccess } = useCommutingDatePageQuery(
    date ? { date: toYYYYMMDD(date) } : ({} as CommutingDatePageQueryVariables),
    { enabled: !!date }
  )

  if (!data) return null
  if (!isSuccess) return null
  return { data, refetch }
}

export const useCommutingPastDailyPage = (date: Date | undefined) => {
  const { data, refetch, isSuccess } = useCommutingPastDailyPageQuery(
    date ? { date: toYYYYMMDD(date) } : ({} as CommutingPastDailyPageQueryVariables),
    { enabled: !!date }
  )

  if (!data) return null
  if (!isSuccess) return null
  return { data, refetch }
}

export const useCommutingPastPage = (year: number) => {
  const { data, refetch, isSuccess } = useCommutingPastPageQuery({ year })

  if (!data) return null
  if (!isSuccess) return null
  return { data: data.allCommutings, refetch }
}

// mutations
export const useCreateFileLocation = () => {
  const { mutateAsync } = useCreateFileLocationMutation()
  return useCallback(
    async (file: UploadedFile) => {
      const fileLocation = (await mutateAsync({})).createFileLocation
      await fetch(fileLocation.uploader, { method: 'PUT', body: file })
      return fileLocation
    },
    [mutateAsync]
  )
}

// google storage にファイル名を付けて保存するための mutation です。
export const useCreateFileLocationCustomName = () => {
  const { mutateAsync } = useCreateFileLocationCustomNameMutation()

  const callback = useCallback(
    async (filename: string) => await mutateAsync({ filename }),
    [mutateAsync]
  )
  return callback
}

export const useCreateReceipt = () => {
  const { refetch: badgeRefetch } = useBadgeCounterContext()
  const { mutateAsync } = useCreateReceiptMutation()
  return useCallback(
    async (input: CreateReceiptInput) => {
      const result = await mutateAsync({ input })
      badgeRefetch()
      return result
    },
    [mutateAsync, badgeRefetch]
  )
}

export const useCreateReceiptOcrAnalysis = () => {
  const { mutateAsync } = useCreateReceiptOcrAnalysisMutation()
  return useCallback(
    async (fileLocationId: FileLocation['id']) => await mutateAsync({ fileLocationId }),
    [mutateAsync]
  )
}

export const useDeleteReceipt = () => {
  const { refetch: badgeRefetch } = useBadgeCounterContext()
  const { mutateAsync } = useDeleteReceiptMutation()
  return useCallback(
    async (uuid: string) => {
      const result = await mutateAsync({ uuid })
      badgeRefetch()
      return result
    },
    [mutateAsync, badgeRefetch]
  )
}

export const useRestoreDeletedReceipt = () => {
  const { refetch: badgeRefetch } = useBadgeCounterContext()
  const { mutateAsync } = useRestoreDeletedReceiptMutation()
  return useCallback(
    async (uuid: string) => {
      const result = await mutateAsync({ uuid })
      badgeRefetch()
      return result
    },
    [mutateAsync, badgeRefetch]
  )
}

export const useNReceipt = (input: ReceiptsInput) => {
  const { data, refetch } = useNReceiptQuery({ input }, { keepPreviousData: true })

  if (!data) return null
  return { data, refetch }
}

export const useDeleteTransportationCardStatement = () => {
  const { refetch: badgeRefetch } = useBadgeCounterContext()
  const { mutateAsync } = useDeleteTransportCardStatementMutation()
  return useCallback(
    async (transportationCardStatementId: string) => {
      const result = await mutateAsync({ transportationCardStatementId })
      badgeRefetch()
      return result
    },
    [mutateAsync, badgeRefetch]
  )
}

export const useRestoreTransportationCardStatement = () => {
  const { refetch: badgeRefetch } = useBadgeCounterContext()
  const { mutateAsync } = useRestoreTransportCardStatementMutation()
  return useCallback(
    async (transportationCardStatementId: string) => {
      const result = await mutateAsync({ transportationCardStatementId })
      badgeRefetch()
      return result
    },
    [mutateAsync, badgeRefetch]
  )
}

export const useSaveTransportCardStatements = () => {
  const { mutateAsync } = useSaveTransportCardStatementsMutation()
  return useCallback(
    async (input: NewTransportationCardStatement[]) => {
      const result = await mutateAsync({ input })
      return result
    },
    [mutateAsync]
  )
}

export const useSuicaFromBytes = () => {
  const { mutateAsync } = useSuicaFromBytesMutation()
  return useCallback(
    async (data: Uint8Array[]) => {
      const result = await mutateAsync({ input: data.map((d) => Array.from(d)) })
      return result.suicaFromBytes
    },
    [mutateAsync]
  )
}

export const useRoutesCreatePageCreateCommutingPath = () => {
  const { mutateAsync } = useRoutesCreatePageCreateCommutingPathMutation()
  return useCallback(
    async (input: SaveApplicationInput) =>
      mutateAsync({
        input: {
          applications: [input],
          group: { create: { name: '', kind: ApplicationGroupKind.CommutingPath } },
        },
      }),
    [mutateAsync]
  )
}

export const useSubmitApplications = () => {
  const { refetch: badgeRefetch } = useBadgeCounterContext()
  const { mutateAsync } = useSubmitApplicationsMutation()
  return useCallback(
    async (inputs: ApplicationActionInput[]) => {
      const result = await mutateAsync({ inputs })
      badgeRefetch()
      return result
    },
    [mutateAsync, badgeRefetch]
  )
}

export type GroupInput = GroupInputCreate | GroupInputUpdate

export interface GroupInputCreate {
  name: string
  kind: ApplicationGroupKind
}

export interface GroupInputUpdate {
  id: string
  name: string
}

function isGroupInputUpdate(g: GroupInput): g is GroupInputUpdate {
  return 'id' in g && !!g.id
}

const intoSaveGroupInput = (g: GroupInput): SaveGroupInput => {
  return isGroupInputUpdate(g)
    ? { update: { id: g.id, name: g.name } }
    : { create: { name: g.name, kind: g.kind } }
}

export type SaveGroupedApplicationsInput = {
  group: GroupInput
  applications: Array<SaveApplicationInput>
}

export const useSaveGroupedApplications = () => {
  const { mutateAsync } = useSaveGroupedApplicationsMutation()
  const { refetch: badgeRefetch } = useBadgeCounterContext()
  return useCallback(
    async (saveGroupedApplicationInput: SaveGroupedApplicationsInput) => {
      const g = saveGroupedApplicationInput.group
      const group = intoSaveGroupInput(g)

      const input = {
        applications: saveGroupedApplicationInput.applications,
        group,
      }
      const result = await mutateAsync({ input })
      badgeRefetch()
      return result
    },
    [mutateAsync, badgeRefetch]
  )
}

export const useSaveAndSubmitGroupedApplications = () => {
  const { mutateAsync } = useSaveAndSubmitGroupedApplicationsMutation()
  const { refetch: badgeRefetch } = useBadgeCounterContext()
  return useCallback(
    async (saveGroupedApplicationInput: SaveGroupedApplicationsInput) => {
      const g = saveGroupedApplicationInput.group
      const group = intoSaveGroupInput(g)

      const input = {
        applications: saveGroupedApplicationInput.applications,
        group,
      }
      const result = await mutateAsync({ input })
      badgeRefetch()
      return result
    },
    [mutateAsync, badgeRefetch]
  )
}

export const useWithdrawApplications = () => {
  const { mutateAsync } = useWithdrawApplicationsMutation()
  const { refetch: badgeRefetch } = useBadgeCounterContext()
  return useCallback(
    async (inputs: ApplicationActionInput[]) => {
      const result = await mutateAsync({ inputs })
      badgeRefetch()
      return result
    },
    [mutateAsync, badgeRefetch]
  )
}

export const useWithdrawApplicationGroups = () => {
  const { mutateAsync } = useWithdrawApplicationGroupsMutation()
  const { refetch: badgeRefetch } = useBadgeCounterContext()
  return useCallback(
    async (input: ApplicationGroupActionInput[]) => {
      const result = await mutateAsync({ input })
      badgeRefetch()
      return result
    },
    [mutateAsync, badgeRefetch]
  )
}

export const useRejectApplicationGroups = () => {
  const { mutateAsync } = useRejectApplicationGroupsMutation()
  const { refetch: badgeRefetch } = useBadgeCounterContext()
  return useCallback(
    async (input: ApplicationGroupActionInput[]) => {
      const result = await mutateAsync({ input })
      badgeRefetch()
      return result
    },
    [mutateAsync, badgeRefetch]
  )
}

export const useApproveApplicationGroups = () => {
  const { mutateAsync } = useApproveApplicationGroupsMutation()
  const { refetch: badgeRefetch } = useBadgeCounterContext()
  return useCallback(
    async (input: ApplicationGroupActionInput[]) => {
      const result = await mutateAsync({ input })
      badgeRefetch()
      return result
    },
    [mutateAsync, badgeRefetch]
  )
}

export const useMoveApplicationGroup = () => {
  const queryClient = useQueryClient()
  const { mutateAsync } = useMoveApplicationGroupMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['ApplicationList'])
    },
  })
  return useCallback(
    async (input: MoveApplicationGroupInput) => await mutateAsync({ input }),
    [mutateAsync]
  )
}

export const useDeleteApplication = () => {
  const { mutateAsync } = useDeleteApplicationMutation()
  return useCallback(
    async (input: ApplicationActionInput) => await mutateAsync({ input }),
    [mutateAsync]
  )
}

export const useDebuggerDeleteApplication = () => {
  const { mutateAsync } = useDebuggerDeleteApplicationMutation()
  return useCallback(
    async (input: ApplicationActionInput) => await mutateAsync({ input }),
    [mutateAsync]
  )
}

export const useUpdateCommutingPathEndDate = () => {
  const { mutateAsync } = useUpdateCommutingPathEndDateMutation()
  return useCallback(
    async (commutingPathId: string) => mutateAsync({ commutingPathId }),
    [mutateAsync]
  )
}

export const useApproveApplications = () => {
  const { mutateAsync } = useApproveApplicationsMutation()
  const { refetch: badgeRefetch } = useBadgeCounterContext()
  return useCallback(
    async (inputs: ApplicationActionInput[]) => {
      const result = await mutateAsync({ inputs })
      badgeRefetch()
      return result
    },
    [mutateAsync, badgeRefetch]
  )
}
export const useRejectApplications = () => {
  const { mutateAsync } = useRejectApplicationsMutation()
  const { refetch: badgeRefetch } = useBadgeCounterContext()
  return useCallback(
    async (inputs: ApplicationActionInput[]) => {
      const result = await mutateAsync({ inputs })
      badgeRefetch()
      return result
    },
    [mutateAsync, badgeRefetch]
  )
}

export const useAddApplicationComment = () => {
  const { mutateAsync } = useAddApplicationCommentMutation()
  const { refetch: badgeRefetch } = useBadgeCounterContext()
  return useCallback(
    async (input: ApplicationActionInput) => {
      const result = await mutateAsync({ input })
      badgeRefetch()
      return result
    },
    [mutateAsync, badgeRefetch]
  )
}

export const usePaymentListPage = (
  params: FilterPaymentParams,
  options?: UseQueryOptions<PaymentListPageQuery>
) => {
  const { data, refetch } = usePaymentListPageQuery(
    { params },
    { keepPreviousData: true, ...options }
  )
  if (!data) return null
  return { data, refetch }
}

export const useNPayment = (
  params: FilterPaymentParams,
  options?: UseQueryOptions<NPaymentQuery>
) => {
  const { data, refetch } = useNPaymentQuery({ params }, { keepPreviousData: true, ...options })
  if (!data) return null
  return { data, refetch }
}

export const usePaymentListPageSyncBplusResult = () => {
  const { mutateAsync } = usePaymentListPageSyncBplusResultMutation()

  return useCallback(async (employeeId: string) => await mutateAsync({ employeeId }), [mutateAsync])
}

export const useRoutePageUpdateCommutingPath = () => {
  const { mutateAsync } = useRoutePageUpdateCommutingPathMutation()
  return useCallback(
    async (input: UpdateCommutingPathInput) => await mutateAsync({ input }),
    [mutateAsync]
  )
}

export const useImportApplicationTypes = () => {
  const { mutateAsync, isLoading } = useImportApplicationTypesMutation()
  const { refetch: badgeRefetch } = useBadgeCounterContext()
  const mutate = useCallback(
    async (input: ApplicationTypeImporterInput[]) => {
      const result = await mutateAsync({ input })
      badgeRefetch()
      return result
    },
    [mutateAsync, badgeRefetch]
  )
  return { mutate, isLoading }
}

export const useSaveDivisions = () => {
  const { mutateAsync, isLoading } = useSaveDivisionsMutation()
  const mutate = useCallback(
    async (input: DivisionImporterInput[]) => {
      const result = await mutateAsync({ input })
      return result
    },
    [mutateAsync]
  )
  return { mutate, isLoading }
}

export const useCreateDivisions = () => {
  const { mutateAsync, isLoading } = useCreateDivisionMutation()
  const mutate = useCallback(
    async (input: DivisionImporterInput[]) => {
      const result = await mutateAsync({ input })
      return result
    },
    [mutateAsync]
  )
  return { mutate, isLoading }
}

export const useImportUsers = () => {
  const { mutateAsync } = useImportUsersMutation()
  const { refetch: badgeRefetch } = useBadgeCounterContext()
  return useCallback(
    async (input: UserImporterInput[]) => {
      const result = await mutateAsync({ input })
      badgeRefetch()
      return result
    },
    [mutateAsync, badgeRefetch]
  )
}

export const useExpenseTypesForPlannedPayee = (typeId: number) => {
  const { data, isSuccess } = useExpenseTypesForPlanndPayeeQuery(
    { typeId },
    { keepPreviousData: true, enabled: !!typeId }
  )
  return { data, isSuccess }
}

export const useAllowanceTypes = () => {
  const { data, isSuccess } = useAllowanceTypesQuery()
  return { data, isSuccess }
}

export const useSubmitApplicationGroup = () => {
  const { refetch: badgeRefetch } = useBadgeCounterContext()
  const { mutateAsync } = useSubmitApplicationGroupMutation()
  return useCallback(
    async (input: ApplicationGroupActionInput) => {
      const result = await mutateAsync({ input })
      badgeRefetch()
      return result
    },
    [mutateAsync, badgeRefetch]
  )
}

export const useAuditApplicationBeforeSubmit = (inputs?: ApplicationActionInput[]) => {
  const { data, isSuccess, isLoading } = useAuditApplicationsBeforeSubmitQuery(
    { inputs: inputs! },
    { keepPreviousData: true, enabled: !!inputs }
  )

  return {
    auditResult:
      isSuccess && data?.auditApplicationsBeforeSubmit
        ? data.auditApplicationsBeforeSubmit[0]
        : null,
    isLoading,
  }
}

export const useAuditGroupedApplicationsBeforeSubmit = (input?: ApplicationGroupActionInput) => {
  const { data, isSuccess, isLoading } = useAuditGroupedApplicationsBeforeSubmitQuery(
    { input: input! },
    { keepPreviousData: true, enabled: !!input }
  )

  return {
    auditResults:
      isSuccess && data?.auditGroupedApplicationsBeforeSubmit
        ? data.auditGroupedApplicationsBeforeSubmit
        : null,
    isLoading,
  }
}
