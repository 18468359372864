import type { FC, ReactNode } from 'react'

import { List, ListSubheader } from '@mui/material'
import type { ListProps } from '@mui/material'
import { matchPath } from 'react-router-dom'

import { L_C1_80 } from '@/lib/colors'

import NavItem from './NavItem'

interface Item {
  path: string
  icon?: ReactNode
  info?: ReactNode
  children?: Item[]
  hasNotifications?: boolean
  title: string
  badgeCount: number
  excludeOnPathMatch?: string[]
  subTitle?: string
}

interface NavSectionProps extends ListProps {
  items: Item[]
  pathname: string
  title: string
}

const renderNavItems = ({
  depth = 0,
  items,
  pathname,
}: {
  items: Item[]
  pathname: string
  depth?: number
}): JSX.Element => (
  <List disablePadding>
    {items.reduce(
      (acc, item) =>
        reduceChildRoutes({
          acc,
          item,
          pathname,
          depth,
        }),
      [] as JSX.Element[]
    )}
  </List>
)

const reduceChildRoutes = ({
  acc,
  pathname,
  item,
  depth,
}: {
  acc: JSX.Element[]
  pathname: string
  item: Item
  depth: number
}): JSX.Element[] => {
  const key = `${item.title}${item.subTitle ? '-' + item.subTitle : ''}-${depth}`

  const partialMatch = item.path
    ? !!matchPath(
        {
          path: item.path,
          end: false,
        },
        pathname
      )
    : false
  const isExcluded = item.excludeOnPathMatch?.some((ex) =>
    matchPath({ path: ex, end: false }, pathname)
  )

  if (item.children) {
    acc.push(
      <NavItem
        active={partialMatch && !isExcluded}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        hasNotifications={!!item.hasNotifications}
        title={item.title}
        badgeCount={item.badgeCount}
        subTitle={item.subTitle}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          pathname,
        })}
      </NavItem>
    )
  } else {
    acc.push(
      <NavItem
        active={partialMatch && !isExcluded}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={false}
        path={item.path}
        hasNotifications={!!item.hasNotifications}
        title={item.title}
        badgeCount={item.badgeCount}
        subTitle={item.subTitle}
      />
    )
  }

  return acc
}

const NavSection: FC<NavSectionProps> = (props) => {
  const { items, pathname, title, ...other } = props

  return (
    <List
      data-cy="cy-nav-section"
      subheader={
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: L_C1_80['20'],
            fontSize: '12px',
            lineHeight: 2.5,
            fontWeight: 700,
            textTransform: 'uppercase',
          }}
        >
          {title}
        </ListSubheader>
      }
      {...other}
    >
      {renderNavItems({
        items,
        pathname,
      })}
    </List>
  )
}

export default NavSection
