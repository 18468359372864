import createSvgIcon from '@mui/material/utils/createSvgIcon'

const InquiryDatasetLinked = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M5.92578 13.4758V17.2581H6.96598C7.09198 16.4859 7.36788 15.7727 7.79328 15.1187C8.21878 14.4647 8.76248 13.917 9.42448 13.4758H5.92578Z"
      fill="#6F738E"
    />
    <path
      d="M6.96498 19.1493H4.03368V5.91122H7.83798C7.87328 5.25052 8.00238 4.61732 8.22278 4.02002H4.03368C3.51358 4.02002 3.06838 4.20522 2.69808 4.57552C2.32768 4.94582 2.14258 5.39112 2.14258 5.91112V19.1492C2.14258 19.6692 2.32768 20.1145 2.69808 20.4848C3.06848 20.8552 3.51368 21.0403 4.03368 21.0403H7.62688C7.45358 20.7567 7.31568 20.4572 7.21318 20.142C7.11078 19.8268 7.02798 19.496 6.96498 19.1493Z"
      fill="#6F738E"
    />
    <path
      d="M5.92578 11.5846H9.70808V10.8549C8.84368 9.98665 8.27118 8.94825 8.00198 7.80225H5.92578V11.5846Z"
      fill="#6F738E"
    />
    <path d="M18.2165 17.2581H12.543V19.1493H18.2165V17.2581Z" fill="#6F738E" />
    <path
      d="M11.2084 19.5394C10.838 19.169 10.6529 18.7238 10.6529 18.2038C10.6529 17.6838 10.8381 17.2385 11.2084 16.8682C11.5787 16.4978 12.024 16.3127 12.544 16.3127H14.4352V14.4216H12.544C11.5039 14.4216 10.6135 14.792 9.87282 15.5326C9.13212 16.2732 8.76172 17.1638 8.76172 18.2039C8.76172 19.244 9.13212 20.1344 9.87282 20.8752C10.6135 21.6159 11.5039 21.9863 12.544 21.9863H14.4352V20.0951H12.544C12.024 20.0951 11.5787 19.9097 11.2084 19.5394Z"
      fill="#6F738E"
    />
    <path
      d="M20.804 16.1524C20.241 16.1524 19.678 15.9376 19.2483 15.5079L18.1618 14.4214H16.3262V16.3125H18.2174C18.7374 16.3125 19.1827 16.4977 19.553 16.868C19.9233 17.2384 20.1085 17.6836 20.1085 18.2036C20.1085 18.7236 19.9233 19.1689 19.553 19.5392C19.1826 19.9096 18.7374 20.0947 18.2174 20.0947H16.3262V21.9859H18.2174C19.2575 21.9859 20.1479 21.6155 20.8886 20.8748C21.6293 20.1341 21.9997 19.2437 21.9997 18.2035C21.9997 17.3995 21.7819 16.6906 21.3525 16.0739C21.1721 16.1203 20.989 16.1524 20.804 16.1524Z"
      fill="#6F738E"
    />
    <path
      d="M20.8046 13.9519L16.6944 9.8417C16.3624 10.0643 15.9962 10.2397 15.5957 10.368C15.1952 10.4962 14.7641 10.5604 14.3023 10.5604C13.1167 10.5604 12.1069 10.1433 11.2727 9.3092C10.4386 8.4751 10.0215 7.4654 10.0215 6.2802C10.0215 5.095 10.4386 4.0853 11.2727 3.2512C12.1068 2.4171 13.1165 2 14.3017 2C15.4869 2 16.4966 2.4171 17.3307 3.2512C18.1648 4.0853 18.5819 5.0952 18.5819 6.2808C18.5819 6.749 18.5178 7.1801 18.3895 7.5742C18.2613 7.9683 18.0859 8.3282 17.8632 8.6538L21.983 12.7831C21.983 12.7831 20.8047 13.9519 20.8046 13.9519ZM14.3017 8.9024C15.0338 8.9024 15.6539 8.6484 16.1619 8.1404C16.6699 7.6324 16.9239 7.0123 16.9239 6.2802C16.9239 5.5481 16.6699 4.928 16.1619 4.42C15.6539 3.912 15.0338 3.658 14.3017 3.658C13.5696 3.658 12.9495 3.912 12.4415 4.42C11.9335 4.928 11.6795 5.5481 11.6795 6.2802C11.6795 7.0123 11.9335 7.6324 12.4415 8.1404C12.9495 8.6484 13.5696 8.9024 14.3017 8.9024Z"
      fill="#6F738E"
    />
  </svg>,
  'InquiryDatasetLinked'
)

export default InquiryDatasetLinked
