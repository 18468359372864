/*
 * フロントのみで利用する型や型に関する処理を記述するファイルです
 * 肥大化した場合は types/xxx.ts などに分割してください。
 */
import type {
  AddressesInput,
  ApproveApplicationPageQuery,
  AuditSeverity,
  BplusResultCardFragment,
  CreditCardStatementCardFragment,
  JrExpressStatementCardFragment,
  ModeSpecificOptions,
  TransportationCardStatementCardFragment,
} from '@/gen/graphql'
import {
  ApplicationKind,
  ApplicationStatusLabel,
  ApplicationStatusLabelForApprover,
  CarCondition,
  CustomFieldType,
  ReceiptType,
  TaxType,
} from '@/gen/graphql'

export enum RouteFetchMode {
  Bicycle = 'BICYCLE',
  Car = 'CAR',
  Taxi = 'TAXI',
  Transit = 'TRANSIT',
  Walk = 'WALK',
}

export const CarConditionLabel: { [s in CarCondition]: string } = {
  [CarCondition.Recommend]: '推奨',
  [CarCondition.TollTime]: '有料道路優先、時間優先',
  [CarCondition.TollDistance]: '有料道路優先、距離優先',
  [CarCondition.TollGas]: '有料道路優先、ガソリン節約優先',
  [CarCondition.FreeTime]: '無料道路優先、時間優先',
  [CarCondition.FreeDistance]: '無料道路優先、距離優先',
  [CarCondition.FreeGas]: '無料道路優先、ガソリン節約優先',
  [CarCondition.FreeOnly]: '無料道路利用',
}
export function routeFetchModeOf(modeSpecificOption: ModeSpecificOptions): RouteFetchMode {
  switch (modeSpecificOption) {
    case modeSpecificOption.transit:
      return RouteFetchMode.Transit
    case modeSpecificOption.bicycle:
      return RouteFetchMode.Bicycle
    case modeSpecificOption.car:
      return RouteFetchMode.Car
    case modeSpecificOption.taxi:
      return RouteFetchMode.Taxi
    case modeSpecificOption.walk:
      return RouteFetchMode.Walk
    default:
      throw new Error('unreachable')
  }
}

export function modeSpecificOptionsOf(
  routeFetchMode: RouteFetchMode,
  modeOverride?: Partial<ModeSpecificOptions>
): ModeSpecificOptions {
  switch (routeFetchMode) {
    case RouteFetchMode.Bicycle: {
      const override = modeOverride?.bicycle
      return { bicycle: override ? override : { todo: 0 } }
    }
    case RouteFetchMode.Car: {
      const override = modeOverride?.car
      return { car: override ? override : { condition: undefined } }
    }
    case RouteFetchMode.Taxi: {
      const override = modeOverride?.taxi
      return { taxi: override ? override : { condition: undefined } }
    }
    case RouteFetchMode.Transit: {
      const override = modeOverride?.transit
      return { transit: override ? override : { transportationToFirstPoint: undefined } }
    }
    case RouteFetchMode.Walk: {
      const override = modeOverride?.walk
      return { walk: override ? override : { todo: 0 } }
    }
    default:
      throw new Error('unexpected')
  }
}

export enum ApplicationPageKind {
  Expense,
  Request,
  CommutingPath,
  Commuting,
}

export function pageOf(applicationKind: ApplicationKind): ApplicationPageKind {
  if (kindsOf(ApplicationPageKind.Expense).includes(applicationKind)) {
    return ApplicationPageKind.Expense
  } else if (kindsOf(ApplicationPageKind.Request).includes(applicationKind)) {
    return ApplicationPageKind.Request
  } else if (kindsOf(ApplicationPageKind.CommutingPath).includes(applicationKind)) {
    return ApplicationPageKind.CommutingPath
  } else if (kindsOf(ApplicationPageKind.Commuting).includes(applicationKind)) {
    return ApplicationPageKind.Commuting
  } else {
    throw new Error('unreachable')
  }
}

export function kindsOf(applicationPageKind: ApplicationPageKind): ApplicationKind[] {
  switch (applicationPageKind) {
    case ApplicationPageKind.Expense:
      return [
        ApplicationKind.Expense,
        ApplicationKind.CashAdvanceRefund,
        ApplicationKind.CashAdvance,
        ApplicationKind.Offset,
      ]
    case ApplicationPageKind.Request:
      return [ApplicationKind.Request]
    case ApplicationPageKind.CommutingPath:
      return [ApplicationKind.CommutingPath]
    case ApplicationPageKind.Commuting:
      return [ApplicationKind.Commuting]
  }
}

export enum ApproverProcessStatus {
  Unprocessed = 'UNPROCESSED',
  Processed = 'PROCESSED',
  Approvable = 'APPROVABLE',
  Unapprovable = 'UNAPPROVABLE',
}

export enum PaidWith {
  JrExpress = 'JrExpressStatement',
  CreditCard = 'CreditCardStatement',
  TransportationCard = 'TransportationCardStatement',
  BplusResult = 'BplusResult',
}

export type PaymentStatements =
  | CreditCardStatementCardFragment
  | JrExpressStatementCardFragment
  | TransportationCardStatementCardFragment
  | BplusResultCardFragment

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P]
}
export type AuditCounts = {
  [AuditSeverity.Warn]: {
    daysWithWarnings: number
    totalWarnings: number
  }
  [AuditSeverity.Error]: {
    daysWithErrors: number
    totalErrors: number
  }
}
export type GroupedApplicationsPerUser = {
  user: ApproveApplicationPageQuery['monthlyApplicationsForApprover']['data'][number]['applications'][number]['user']
  groupedApplications: ApproveApplicationPageQuery['monthlyApplicationsForApprover']['data']
  auditCounts?: AuditCounts
}

export type AddressesQueryWithoutTypeInput = Omit<AddressesInput, 'typeIn'>

export type ApplicableStatus =
  | ApplicationStatusLabel.Unapplied
  | ApplicationStatusLabel.Rejected
  | ApplicationStatusLabel.Withdrawn

export function isApplicableStatus(status: ApplicationStatusLabel): status is ApplicableStatus {
  return (
    status === ApplicationStatusLabel.Unapplied ||
    status === ApplicationStatusLabel.Rejected ||
    status === ApplicationStatusLabel.Withdrawn
  )
}

export const AppProcessRoles = {
  Applicant: 'Applicant',
  Approver: 'Approver',
} as const

export type ProcessRole = keyof typeof AppProcessRoles

export const customFieldTypeLabel: Record<CustomFieldType, string> = {
  [CustomFieldType.Boolean]: 'チェックボックス',
  [CustomFieldType.Date]: '日付',
  [CustomFieldType.DateRange]: '日付範囲',
  [CustomFieldType.DateTime]: '日時',
  [CustomFieldType.Hhmm]: '時間',
  [CustomFieldType.HhmmRange]: '時間範囲',
  [CustomFieldType.Integer]: '数字',
  [CustomFieldType.Select]: 'セレクト',
  [CustomFieldType.String]: '文字列',
}

export const applicationKindToLabel: Record<ApplicationKind, string> = {
  [ApplicationKind.Expense]: '経費',
  [ApplicationKind.Request]: '事前申請',
  [ApplicationKind.CashAdvance]: '仮払い',
  [ApplicationKind.CashAdvanceRefund]: '仮払返納',
  [ApplicationKind.Offset]: '返金',
  [ApplicationKind.CommutingPath]: '通勤経路',
  [ApplicationKind.Commuting]: '通勤手当',
}

export const applicationStatusLabelToLabel: Record<ApplicationStatusLabel, string> = {
  [ApplicationStatusLabel.Unapplied]: '未申請',
  [ApplicationStatusLabel.Submitted]: '申請中',
  [ApplicationStatusLabel.Approved]: '承認済み',
  [ApplicationStatusLabel.Rejected]: '差し戻し',
  [ApplicationStatusLabel.Withdrawn]: '取り下げ',
  [ApplicationStatusLabel.Deleted]: '削除済み',
}
export const applicationStatusLabelForApproverToLabel: Record<
  ApplicationStatusLabelForApprover,
  string
> = {
  [ApplicationStatusLabelForApprover.Unapplied]: '未申請',
  [ApplicationStatusLabelForApprover.BeforeYourTurn]: '前段階の承認待ち',
  [ApplicationStatusLabelForApprover.YourTurn]: 'あなたの承認待ち',
  [ApplicationStatusLabelForApprover.AfterYourTurn]: '後続の承認待ち',
  [ApplicationStatusLabelForApprover.Approved]: '承認済み',
  [ApplicationStatusLabelForApprover.Rejected]: '差し戻し',
  [ApplicationStatusLabelForApprover.Withdrawn]: '取り下げ',
}

export const ReceiptTypeLabel: Record<ReceiptType, string> = {
  [ReceiptType.Paper]: '紙の領収書',
  [ReceiptType.Electoronic]: '電子領収書',
}

export const TaxTypeLabel: Record<TaxType, string> = {
  [TaxType.Unknown]: '不明',
  [TaxType.Tax_10]: '標準税率(10%)',
  [TaxType.Tax_8]: '標準税率(8%)',
  [TaxType.Tax_0]: '非課税',
}
