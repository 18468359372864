import { useState } from 'react'
import type { ReactElement } from 'react'

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { Autocomplete, TextField, Popper, InputAdornment, CircularProgress } from '@mui/material'

import { applicationCreatePageSearchUserQuery } from '@/api/gql'
import { useBool, useMounted } from '@/hooks'
import useDebounce from '@/hooks/useDebounce'

export interface User {
  id: string
  employeeId: string
  name: string
  nameEn: string
  division: string
}

interface UserAutoCompleteProps {
  value: { employeeId: string } | undefined
  onSelect: (u?: User) => void
  defaultValue?: Partial<User>
  onInputChange?: (str: string) => Promise<User[]>
  minWordLength?: number
  includeInactive?: boolean
  matcher?: (u: User, value: string) => boolean
}

export function UserAutoComplete({
  value,
  onSelect,
  onInputChange,
  minWordLength = 0,
  includeInactive,
  defaultValue,
  matcher = () => true,
}: UserAutoCompleteProps): ReactElement {
  const [opened, open, close] = useBool()
  const [inputValue, setInputValue] = useState('')

  const [loading, start, finish] = useBool()
  const [options, setOptions] = useState<User[]>([])

  const debounce = useDebounce(1000)
  const handleInputChange = (words: string) => {
    setInputValue(words)
    if (words.length < minWordLength) {
      setOptions([])
      return
    }

    start()
    debounce(async () => {
      // ここでstartしてもこのfunctionを抜けるまで状態反映されない（call stack内を抜けるまで反映しない）
      // のでこの関数の外でcallする
      // start()
      const f =
        onInputChange ??
        (async (words: string): Promise<User[]> => {
          const res = await applicationCreatePageSearchUserQuery({
            words,
            includeInactive: includeInactive ?? false,
          })
          return res.autocompleteUsers.map((d) => ({
            id: d.id,
            employeeId: d.employeeId,
            name: d.name,
            nameEn: d.nameEn || '',
            division: d.division?.isActive ? d.division.name : '',
          }))
        })
      setOptions(await f(words))
      finish()
    })
  }

  useMounted(() => handleInputChange(''))

  const labelGenerator = (u: User) => {
    const values = []
    if (u.division) {
      values.push(`所属: ${u.division}`)
    }
    if (u.employeeId) {
      values.push(`ID: ${u.employeeId}`)
    }

    return u.name + (values.length !== 0 ? ` (${values.join(' / ')})` : '')
  }

  return (
    <Autocomplete
      disablePortal
      data-cy="cy-account-popover-select"
      options={options}
      defaultValue={defaultValue as User}
      sx={{ width: '100%' }}
      PopperComponent={(props) => <Popper {...props} disablePortal={false} />}
      renderInput={(params) => (
        <TextField
          {...params}
          data-cy="cy-account-popover-select-input"
          placeholder="ユーザーを検索"
          InputProps={{
            ...params.InputProps,
            startAdornment: !value && (
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {!defaultValue && loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </InputAdornment>
            ),
          }}
        />
      )}
      getOptionLabel={labelGenerator}
      onChange={(_e, v) => {
        const found = options.find((u) => u.employeeId === v?.employeeId)
        onSelect(found)
        close()
      }}
      open={opened}
      filterOptions={(opts, state) => {
        if (inputValue.length < minWordLength) return []
        return opts.filter((o) => {
          // labelと完全一致していればoptionsに入れる
          if (state.getOptionLabel(o) === inputValue) return true
          return matcher(o, inputValue)
        })
      }}
      inputValue={inputValue}
      onInputChange={(_, newInputValue, reason) => {
        if (reason === 'reset') {
          setInputValue(newInputValue)
          return
        }
        newInputValue.length >= minWordLength ? open() : close()
        handleInputChange(newInputValue)
      }}
      noOptionsText="検索結果がありません"
      isOptionEqualToValue={(o) => o.employeeId === value?.employeeId}
      onBlur={close}
      onFocus={() => (inputValue.length >= minWordLength ? open() : close())}
    />
  )
}
