import { useEffect } from 'react'

import TagManager from 'react-gtm-module'

import type { GoogleTagManagerAuth } from '@/gen/graphql'
import { useAuth } from '@/hooks/auth'

export function useGoogleTagManager(gtmAuth?: GoogleTagManagerAuth | null) {
  const auth = useAuth()

  useEffect(() => {
    // process.env.REACT_APP_IS_GTM_ENABLED が true でない場合は、GTM を設定しない
    if (process.env.REACT_APP_IS_GTM_ENABLED !== 'true') return
    if (!gtmAuth) return
    const { id: gtm_id, auth: gtm_auth } = gtmAuth
    if (!gtm_id || !gtm_auth) {
      initializeDefaultTagManager()
    } else {
      initializeTagManager(gtm_id, gtm_auth)
    }
  }, [auth?.provider, gtmAuth?.id, process.env]) // eslint-disable-line react-hooks/exhaustive-deps
}

const initializeDefaultTagManager = () => {
  const gtmId = process.env.REACT_APP_DEFAULT_GTM_ID
  const auth = process.env.REACT_APP_DEFAULT_GTM_AUTH
  if (!gtmId || !auth) return
  initializeTagManager(gtmId, auth)
}

const initializeTagManager = (id: string, auth: string) => {
  const tagManagerArgs = {
    gtmId: id,
    auth: auth,
  }
  TagManager.initialize(tagManagerArgs)
}
