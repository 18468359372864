import Cookies from 'js-cookie'

export interface StoredUser {
  employeeId: string
  name: string
  division?: string
}

type Store =
  | { key: 'actAs'; value: StoredUser | undefined }
  | { key: 'provider'; value: string | undefined }
  | { key: 'forceReloaded'; value: 'true' }
  | { key: 'YSAT'; value: string | undefined }

type StoreValue<T extends Store['key']> = Extract<Store, { key: T }>['value']

// expire: 1日単位
export const write = (s: Store, expire?: number) => {
  if (!s.value) {
    Cookies.remove(s.key)
    return
  }

  const v = s.key === 'actAs' ? JSON.stringify(s.value) : s.value
  // expires: default 1day
  const expires = !expire ? expire : s.key === 'provider' ? undefined : 1

  Cookies.set(s.key, v, { expires, secure: process.env.NODE_ENV === 'production' })
}

export const read = <K extends Store['key']>(k: K): StoreValue<K> | undefined => {
  const v = Cookies.get(k)

  if (!v) return undefined
  return k === 'actAs' ? JSON.parse(v) : v
}
