// フォームの入力で必須項目の値チェックを判定する、jsのboolean判定の仕様を以下のとき上書きしている
// 0が入力されたとき true
const isInputValuePresent = (value: unknown) => {
  if (value === 0) return true
  return Boolean(value)
}

export function requiredSx<T>(required: boolean | undefined, value: T) {
  const boxShadowColor = isInputValuePresent(value) ? '#F5BCB3' : '#E65840'

  return required ? { boxShadow: `inset 6px 0px ${boxShadowColor}` } : {}
}
