import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import type { UserImporterInput } from '@/gen/graphql'
import type { DeepPartial } from '@/lib/types'

const schema = yup.object({
  data: yup.object().shape({
    employeeId: yup.string().required(),
    email: yup.string().required().email(),
    nameJp: yup.string().required(),
    nameEn: yup
      .string()
      .required()
      .matches(/^[A-Za-z\s]+$/, 'ローマ字で入力してください（半角スペースは許可されます）'),
  }),
  divisions: yup.array().required().min(1),
})

export const defaultUserImporterFormValues: DeepPartial<UserImporterInput> = {
  divisions: [],
  approvalFlows: [],
  permissions: [],
}
export const useUserImporterForm = (initialValues?: DeepPartial<UserImporterInput>) =>
  useForm<UserImporterInput>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...initialValues,
    },
  })
