import type { ReactElement } from 'react'
import { useState } from 'react'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import type { TextFieldProps } from '@mui/material'
import { Autocomplete, Stack, TextField, Typography } from '@mui/material'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import type { InputCustomFieldProps } from '@/components/application/ApplicationForm/RHFCustomFormInput'
import { requiredSx } from '@/lib/styles'

// ["0", "1", "2", "3", ... "23"]
const HHOption = Array.from(new Array(24)).map((_, index) => String(index))
// ["0", "5", "10", ... "55"]
const mmOption = Array.from(new Array(12)).map((_, index) => String(index * 5))

export function addLeadingZero(numArray: string[]): string[] {
  return numArray.map((num) => (num.length === 1 ? `0${num}` : num))
}

// ["01", "02", "03", ... "23", "24"]
const paddedHourOption = addLeadingZero(HHOption)
// ["00", "05", "10", ... "55"]
const paddedMinuteOption = addLeadingZero(mmOption)

interface HHmm_obj_type {
  hour: number
  minute: number
}

// 数値としてHHmmを扱いたくなったときにHHmmからhourとminuteを取り出してください
export function parseHHmm(HHmm_str: string): HHmm_obj_type | null {
  if (!HHmm_str.includes(':')) {
    console.error(`HHmm stringのparseには : が必要です。入力された値: ${HHmm_str}`)
    return null
  }

  const TIME_REGEX = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/
  if (!TIME_REGEX.test(HHmm_str)) {
    console.error(`HHmmとしてparse不可能な文字(列)が入力されました。入力された値: ${HHmm_str}`)
    return null
  }

  const [h, m] = HHmm_str.split(':')
  const HHmm_obj = {
    hour: Number(h),
    minute: Number(m),
  }

  return HHmm_obj
}

interface HHmmAutoCompleteProps extends Pick<InputCustomFieldProps, 'required'> {
  fieldEntryValue: string
  fieldTitle: string
  updateHHmm: (newHHmm: string) => void
  dynamicShrinkFlag: boolean //InputHHmmならfalse, InputHHmmRangeならtrueにする想定
}

export function HHmmAutoComplete({
  required,
  fieldEntryValue,
  fieldTitle,
  updateHHmm,
  dynamicShrinkFlag,
}: HHmmAutoCompleteProps): ReactElement {
  const initialValues = fieldEntryValue.split(':')

  const [hour, setHour] = useState<string | null>(initialValues[0] ? initialValues[0] : null)
  const [minute, setMinute] = useState<string | null>(initialValues[1] ? initialValues[1] : null)

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const hourMinuteCommonProps = {
    disableClearable: true,
    autoHighlight: true,
    noOptionsText: '選択不可',
    popupIcon: dynamicShrinkFlag && isSmallScreen ? null : <ArrowDropDownIcon />,
  }

  return (
    <Stack direction="row" alignItems="center">
      <Autocomplete
        value={hour}
        {...hourMinuteCommonProps}
        options={paddedHourOption}
        sx={{
          // '100px' でなく、12.5 と設定してしまうと vwとの兼ね合いでスタイルが崩れてしまう
          width: dynamicShrinkFlag ? (isSmallScreen ? '6vw' : '100px') : '100px',
        }}
        onChange={(_e, h) => {
          setHour(h)
          if (!!minute) {
            updateHHmm(`${h}:${minute}`)
          }
        }}
        renderInput={(params: TextFieldProps) => (
          <TextField
            {...params}
            variant="outlined"
            data-cy={`cy-schema-hour-form-${fieldTitle}`}
            required={required}
            sx={{ '& .MuiInputBase-root': requiredSx(required, hour) }}
          />
        )}
      />
      <Typography sx={{ pl: dynamicShrinkFlag ? (isSmallScreen ? 3.75 : 1.5) : 1.5 }}>
        時
      </Typography>
      <Autocomplete
        value={minute}
        {...hourMinuteCommonProps}
        options={paddedMinuteOption}
        sx={{
          width: dynamicShrinkFlag ? (isSmallScreen ? '6vw' : '100px') : '100px', // '100px' でなく、12.5 と設定してしまうと vwとの兼ね合いでスタイルが崩れてしまう
          ml: dynamicShrinkFlag ? (isSmallScreen ? 0.5 : 2) : 2,
        }}
        onChange={(_e, m) => {
          setMinute(m)
          if (!!hour) {
            updateHHmm(`${hour}:${m}`)
          }
        }}
        renderInput={(params: TextFieldProps) => (
          <TextField
            {...params}
            variant="outlined"
            data-cy={`cy-schema-minute-form-${fieldTitle}`}
            required={required}
            sx={{ '& .MuiInputBase-root': requiredSx(required, minute) }}
          />
        )}
      />
      <Typography sx={{ pl: dynamicShrinkFlag ? (isSmallScreen ? 3.75 : 1.625) : 1.625 }}>
        分
      </Typography>
    </Stack>
  )
}
