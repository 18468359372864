import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { Autocomplete, CircularProgress, InputAdornment, Popper, TextField } from '@mui/material'

import type { ApproverGroupSelectFragment } from '@/gen/graphql'
import { useExportApproverGroups } from '@/hooks/graphql/admin'

interface ApproverGroupAutoCompleteProps {
  value?: { code: string }
  defaultValue?: ApproverGroupSelectFragment
  onSelect: (u: ApproverGroupSelectFragment | null) => void
}
export function ApproverGroupAutoComplete({
  value,
  defaultValue,
  onSelect,
}: ApproverGroupAutoCompleteProps) {
  const groups = useExportApproverGroups()

  return (
    <Autocomplete
      disablePortal
      options={groups?.data || []}
      defaultValue={defaultValue}
      PopperComponent={(props) => <Popper {...props} disablePortal={false} />}
      sx={{ width: '100%' }}
      onChange={(_e, v) => {
        onSelect(v)
      }}
      getOptionLabel={(opt) => `${opt.data.name}  (Code: ${opt.data.code})`}
      isOptionEqualToValue={(opt) => opt.data.code === value?.code}
      noOptionsText="承認グループがありません"
      renderInput={(params) => (
        <TextField
          {...params}
          data-cy="cy-approver-group-popover-select-input"
          placeholder="承認グループを選択"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {!defaultValue && groups?.isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  )
}
