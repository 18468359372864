import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

import { ReactComponent as SvgDatasetLinked } from '@/icons/svg/dataset_linked.svg'

export function DatasetLinked(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <SvgDatasetLinked />
    </SvgIcon>
  )
}
