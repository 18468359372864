import * as yup from 'yup'
import type { Message } from 'yup/lib/types'

declare module 'yup' {
  interface ArraySchema<T> {
    unique(mapper?: (a: T) => T, message?: Message): ArraySchema<T>
  }
}

export const initYup = () => {
  yup.addMethod(yup.array, 'unique', function (mapper = (a: any) => a, message) {
    return this.test('unique', message, function (list) {
      return list?.length === new Set(list?.map(mapper)).size
    })
  })
}
