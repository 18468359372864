import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

import { ReactComponent as SvgShoninJizenCheck } from '@/icons/svg/shonin_jizen_check.svg'

export function ShoninJizenCheck(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <SvgShoninJizenCheck />
    </SvgIcon>
  )
}
