import type { DividerProps as MuiDividerProps } from '@mui/material'
import { Divider as MuiDivider } from '@mui/material'

import { grey } from '@/lib/colors'

interface DividerProps extends MuiDividerProps {
  type?: 'Line1' | 'Line2' | 'Line3'
}

export function Divider({ type = 'Line1', ...props }: DividerProps) {
  const { children, sx, flexItem, orientation, ...rest } = props

  const Line1Sx = {
    borderColor: grey[10],
    borderBottomWidth: '2px',
  }

  const Line2Sx = {
    borderColor: grey[20],
    borderWidthRight: '1px',
  }

  const Line3Sx = {
    borderColor: grey[50],
    borderBottomWidth: '1px',
  }

  const variantMap = {
    Line1: Line1Sx,
    Line2: Line2Sx,
    Line3: Line3Sx,
  }

  return (
    <MuiDivider
      sx={{
        ...sx,
        ...variantMap[type],
      }}
      {...rest}
      flexItem={type === 'Line2' ? true : false}
      orientation={type === 'Line2' ? 'vertical' : 'horizontal'}
    >
      {children}
    </MuiDivider>
  )
}
