import { useEffect } from 'react'

import * as Sentry from '@sentry/react'

import { useAuthContext } from '@/contexts/Auth'

const useInitSentryEffect = () => {
  const { user } = useAuthContext()
  useEffect(() => {
    if (!user) return
    const { employeeId, name } = user
    Sentry.setUser({ employeeId, username: name })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}

export default useInitSentryEffect
