import type { ReactNode } from 'react'
import { useCallback } from 'react'

import type { BlockerFunction } from 'react-router-dom'

import { useConfirm } from '@/hooks/usePropmt'

type ReactRouterPromptProps = {
  when: boolean | BlockerFunction
  children: (data: { isActive: boolean; onCancel(): void; onConfirm(): void }) => ReactNode
  onCancel?: () => Promise<unknown>
  beforeConfirm?: () => Promise<unknown>
}

function ReactRouterPrompt({ when, children, onCancel, beforeConfirm }: ReactRouterPromptProps) {
  const { isActive, onConfirm, resetConfirmation } = useConfirm(when)

  const onConfirmAction = useCallback(async () => {
    if (beforeConfirm) await beforeConfirm()
    onConfirm()
  }, [beforeConfirm, onConfirm])

  const onResetAction = useCallback(async () => {
    if (onCancel) await onCancel()
    resetConfirmation()
  }, [onCancel, resetConfirmation])

  if (isActive) {
    return (
      <div>
        {children({
          isActive: true,
          onConfirm: onConfirmAction,
          onCancel: onResetAction,
        })}
      </div>
    )
  }
  return null
}

export default ReactRouterPrompt
