import type { SvgIconProps } from '@mui/material/SvgIcon'
import SvgIcon from '@mui/material/SvgIcon'

import { ReactComponent as SvgDownload } from '@/icons/svg/download.svg'

export function Download(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <SvgDownload />
    </SvgIcon>
  )
}
