/* 数値入力のためのTextField Component */
import type { ReactElement } from 'react'
import React, { useEffect, useState } from 'react'

import type { TextFieldProps } from '@mui/material'
import { TextField } from '@mui/material'
import { isUndefined, isEmpty } from 'lodash'

type Mode = 'integer' | 'float'

// 例えば "-0.1.2-3" みたいなものを "-0.123" に変える
function convertUserInputToEligibleInnerValue(mode: Mode, value: unknown): string {
  if (value === null || value === undefined || value === '.') return ''

  const trimmed = String(value)
    .replace(/[０-９．]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0)) // 全角の数字と小数点を半角に変換
    .replace(/[-－﹣−‐⁃‑‒–—﹘―⎯⏤ーｰ─━]/g, '-') //マイナスっぽいものを全て`-`に統一
    .replace(/(?!^)-/g, '') // 先頭の一文字目以外のマイナスを消す
    .replace(/[^0-9.-]/g, '') // 数字とマイナス以外の文字列を除去

  switch (mode) {
    case 'integer': {
      const firstDotIndex = trimmed.indexOf('.') //最初の'.'が現れる位置を探す。

      // 小数点があれば切り捨てる 123.45 -> 123
      if (firstDotIndex !== -1) {
        return trimmed.slice(0, firstDotIndex)
      }
      return trimmed
    }
    case 'float': {
      const firstDotIndex = trimmed.indexOf('.') //最初の'.'が現れる位置を探す。
      var newStr = trimmed.replace(/\./g, '')
      if (firstDotIndex !== -1) {
        newStr = newStr.slice(0, firstDotIndex) + '.' + newStr.slice(firstDotIndex)
      }
      return newStr
    }
  }
}

// 数値に変換できない場合はundefinedを返す
// 例えば入力中の値 "-0." などは、正しいが親に伝搬すべきでない
function convertToDeterminedOutput(mode: Mode, value: string): number | undefined {
  if (!value || isEmpty(value)) return

  const parsed = mode === 'integer' ? parseInt(value) : Number(value)
  if (Number.isNaN(parsed)) return

  return parsed
}

function limitNumber(
  { min, max }: { min?: number; max?: number },
  value?: number
): number | undefined {
  if (isUndefined(value)) return
  if (isUndefined(min) && isUndefined(max)) return value
  if (!isUndefined(min) && value < min) return min
  if (!isUndefined(max) && value > max) return max
  return value
}

interface BaseInputProps {
  value: string | number | undefined
  onChangeWrapper?: (value?: number) => void
  mode: Mode
  min?: number
  max?: number
}

const BaseInput = React.forwardRef<HTMLInputElement, BaseInputProps>(function BaseInput(
  props,
  ref
) {
  const { value, onChangeWrapper, mode, min, max, ...others } = props
  const initText = (mode: Mode, v: unknown, { min, max }: { min?: number; max?: number } = {}) => {
    const innerValue = convertUserInputToEligibleInnerValue(mode, v)
    const valueToPropagate = convertToDeterminedOutput(mode, innerValue)
    const limitedByDigits = limitNumber({ min, max }, valueToPropagate)
    return !isUndefined(limitedByDigits) ? limitedByDigits.toString() : ''
  }

  const [innerValue, setInnerValue] = useState<string>(initText(mode, value, { min, max })) // 表示されている値ではなく、内部で保持している値
  const [composing, setComposing] = useState<boolean>(false) // 日本語入力とかでまとめて入れるやつ
  const [emittedInput, setEmittedInput] = useState<boolean>(true)
  useEffect(() => setInnerValue(initText(mode, value, { min, max })), [mode, value, min, max])

  const emitChange = (value: string) => {
    const converted = convertUserInputToEligibleInnerValue(mode, value)
    // 入力されたテキストは内部状態として保持
    setInnerValue(converted)
    // 親に伝えるのは数値として正しいもの
    onChangeWrapper?.(convertToDeterminedOutput(mode, converted)) // 親に伝えるのここ
    setEmittedInput(true)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = event.target.value
    if (event.target.validity.patternMismatch) {
      return
    }
    if (!composing) {
      emitChange(v)
    } else {
      setInnerValue(v)
      setEmittedInput(false)
    }
  }

  const handleComposition = (event: React.CompositionEvent<HTMLInputElement>) => {
    if (event.type === 'compositionstart') {
      setComposing(true)
      setEmittedInput(false)
    } else if (event.type === 'compositionend') {
      setComposing(false)
      // Chrome fires "compositionend" but doesn't update the input
      if (!emittedInput) {
        emitChange(innerValue)
      }
    }
  }

  return (
    <input
      {...others}
      ref={ref as any}
      value={innerValue}
      onChange={handleChange}
      onCompositionStart={handleComposition}
      onCompositionEnd={handleComposition}
    />
  )
})

type Props = Omit<TextFieldProps, 'onChange'> & {
  mode: 'integer' | 'float'
  onChange?: (value?: number) => void
}

// 数値入力のみを許可するTextField です
// 全角数字入力時は変換が確定したタイミングで半角に変換されます (未変換のテキストを変換しようとすると入力が壊れるため)
// 数字以外の文字列は先頭のマイナス`-`以外入力できません
export function NumericalTextField({
  inputProps,
  mode,
  value,
  onChange,
  ...props
}: Props): ReactElement {
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: BaseInput as any,
        inputProps: {
          ...inputProps,
          // null or undefined の場合、コンポーネントが非制御（uncontrolled）になるため
          value: value ?? '',
          onChangeWrapper: onChange,
          mode: mode,
          // maxLength はデフォルトで15文字に設定（Number.MAX_SAFE_INTEGER を超えると精度が落ちるため）
          maxLength: inputProps?.maxLength ?? 15,
        },
        ...props.InputProps,
      }}
    />
  )
}
