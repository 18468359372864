import type { ReactElement } from 'react'

import {
  AccountBalanceOutlined,
  DatasetLinkedOutlined,
  DescriptionOutlined,
  FactCheckOutlined,
  PriceCheckOutlined,
  ReceiptLongOutlined,
} from '@mui/icons-material'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import type { TypographyProps } from '@mui/material'
import { Box, Typography, Tooltip, Stack } from '@mui/material'

import type { ApplicationAssociation } from '@/components/AssociationIcon'
import { AssociationIcon } from '@/components/AssociationIcon'
import { SelectActions } from '@/components/SelectActions'
import type { StatusVariant } from '@/components/StatusChip'
import { StatusChip } from '@/components/StatusChip'
import type { ValidationStatus } from '@/components/ValidationIcon'
import { ValidationIcon } from '@/components/ValidationIcon'
import { useBrowserState } from '@/hooks/useBrowserState'
import { textColor } from '@/lib/colors'
import type { ProcessRole } from '@/lib/types'
import { toYYYYMMDD_slash } from '@/utils/date'

type CardHeaderGenreContent = {
  icon: ReactElement
  text: string
}

export enum CardHeaderGenre {
  CASH_ADVANCE = 'CASH_ADVANCE',
  REQUEST = 'REQUEST',
  ATTACHED_FILE = 'ATTACHED_FILE',
  RECEIPT = 'RECEIPT',
  PAYMENT = 'PAYMENT',
  OFFSET = 'OFFSET',
}
const cardHeaderGenreContents: { [key in CardHeaderGenre]: CardHeaderGenreContent } = {
  CASH_ADVANCE: {
    icon: <AccountBalanceOutlined fontSize="small" sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />,
    text: '仮払い',
  },
  REQUEST: {
    icon: <FactCheckOutlined fontSize="small" sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />,
    text: '事前申請',
  },
  ATTACHED_FILE: {
    icon: <DescriptionOutlined fontSize="small" sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />,
    text: '資料',
  },
  RECEIPT: {
    icon: <ReceiptLongOutlined fontSize="small" sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />,
    text: '領収書・レシート',
  },
  PAYMENT: {
    icon: <DatasetLinkedOutlined fontSize="small" sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />,
    text: '連携データ',
  },
  OFFSET: {
    icon: <PriceCheckOutlined fontSize="small" sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />,
    text: '立替相殺用の返金',
  },
}

interface CardGenreDisplayProps {
  cardHeaderGenre: CardHeaderGenre
}
export const CardGenreDisplay = ({ cardHeaderGenre }: CardGenreDisplayProps) => {
  const content = cardHeaderGenreContents[cardHeaderGenre]
  return (
    <Stack direction={'row'} spacing={0.5}>
      <Tooltip title="コメントあり">{content.icon}</Tooltip>
      <Typography
        sx={{
          fontSize: { xs: '16px', md: '14px' },
          lineHeight: { xs: '22px', md: '20px' },
          color: textColor['secondry'],
          fontWeight: 500,
          letterSpacing: '0.5px',
        }}
      >
        {content.text}
      </Typography>
    </Stack>
  )
}

interface DateTextProps extends TypographyProps {
  date: Date
}
// FIXME: variant="data_caption" にする。 responsive な theme の定義に時間がかかるので、一旦保留
export function DateText({ date, sx, ...props }: DateTextProps) {
  return (
    <Typography
      sx={{
        fontSize: { xs: '16px', md: '14px' },
        lineHeight: { xs: '22px', md: '20px' },
        color: textColor['secondry'],
        fontWeight: 500,
        letterSpacing: '0.5px',
        ...sx,
      }}
      {...props}
    >
      {toYYYYMMDD_slash(date)}
    </Typography>
  )
}

export interface CardHeaderProps {
  onClick?: () => void
  approvalStatus: StatusVariant
  validationStatuses?: ValidationStatus[]
  date: Date
  associations?: ApplicationAssociation[]
  hasComment?: boolean
  paymentIcon?: JSX.Element
  actions?: JSX.Element[]
  mobileView?: boolean
  processRole?: ProcessRole
  isApprovable?: boolean
  displayGenre: CardHeaderGenre | null
}

export function CardHeader({
  approvalStatus,
  validationStatuses,
  date,
  hasComment = false,
  associations,
  paymentIcon,
  onClick,
  actions,
  mobileView,
  processRole,
  isApprovable,
  displayGenre,
}: CardHeaderProps) {
  const { isMobile } = useBrowserState()
  const icons = [
    ...(hasComment
      ? [
          <Tooltip title="コメントあり">
            <ChatOutlinedIcon fontSize="small" sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />
          </Tooltip>,
        ]
      : []),
    ...(validationStatuses
      ? validationStatuses.map((status) => <ValidationIcon status={status} />)
      : []),
    ...(associations
      ? associations.map((association) => <AssociationIcon association={association} />)
      : []),
  ]

  const isMobileView = mobileView || isMobile

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box display={isMobileView ? 'block' : 'flex'} alignItems="center" gap={1}>
        <Box display="flex" alignItems="center" gap={1}>
          <StatusChip
            status={approvalStatus}
            role={processRole}
            isApprovable={isApprovable}
            size="small"
          />
          {displayGenre && <CardGenreDisplay cardHeaderGenre={displayGenre} />}
          <DateText date={date} />
          {actions && paymentIcon && (
            <Box display="flex" alignItems="center">
              {paymentIcon}
            </Box>
          )}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          gap={0.5}
          sx={{
            ...(isMobileView ? { mt: '6px' } : { mt: 0 }),
          }}
        >
          {icons.map((icon, i) => (
            <Box key={`icons-${i}`} sx={{ lineHeight: '0px' }}>
              {icon}
            </Box>
          ))}
        </Box>
      </Box>
      <Box alignSelf="flex-start">
        {!actions && paymentIcon}
        {actions && <SelectActions actions={actions} />}
      </Box>
    </Box>
  )
}
