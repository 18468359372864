import { fetcher } from '@/api/config'
import type {
  ApplicationCreatePageAuditApplicationFromInputsQuery,
  ApplicationCreatePageAuditApplicationFromInputsQueryVariables,
  ApplicationCreatePageSearchUserQuery,
  ApplicationCreatePageSearchUserQueryVariables,
  ApplicationsForBatchSubmitAuditQuery,
  ApplicationsForBatchSubmitAuditQueryVariables,
  FilterApplicantsByNameQuery,
  FilterApplicantsByNameQueryVariables,
  PathsByNavitimeInput,
  PathsByNavitimeQuery,
  PathsByNavitimeQueryVariables,
  ReceiptBundleQuery,
  ReceiptBundleQueryVariables,
  SaveApplicationInput,
  FilterReceiptsQuery,
  FilterReceiptsQueryVariables,
  ReceiptsInput,
  ProxyUsersQuery,
  ProxyUsersQueryVariables,
  CurrencyRateInput,
  CurrencyRateQuery,
  CurrencyRateQueryVariables,
  DivisionScopesByCharacterQuery,
  DivisionScopesByCharacterQueryVariables,
  AutocompleteUsersInput,
  ExportApproverGroupsQuery,
  ExportApproverGroupsQueryVariables,
  BuildApplicationPresetInput,
  ApplicationCreatePresetQuery,
  ApplicationCreatePresetQueryVariables,
  ExportDivisionCsvQuery,
  ExportDivisionCsvQueryVariables,
  ExportApplicationTypeCsvQuery,
  ExportApplicationTypeCsvQueryVariables,
  ExportUserCsvQuery,
  ExportUserCsvQueryVariables,
} from '@/gen/graphql'
import {
  ApplicationCreatePageAuditApplicationFromInputsDocument,
  ApplicationCreatePageSearchUserDocument,
  ApplicationsForBatchSubmitAuditDocument,
  FilterApplicantsByNameDocument,
  PathsByNavitimeDocument,
  ReceiptBundleDocument,
  FilterReceiptsDocument,
  ProxyUsersDocument,
  CurrencyRateDocument,
  DivisionScopesByCharacterDocument,
  ExportApproverGroupsDocument,
  ApplicationCreatePresetDocument,
  ExportDivisionCsvDocument,
  ExportApplicationTypeCsvDocument,
  ExportUserCsvDocument,
} from '@/gen/graphql'
import { toNaive } from '@/utils/date'

export interface Coord {
  lat: string
  lng: string
}

export interface PathByNavitimeQueryArgs {
  coord: [Coord, Coord, Coord?]
  options?: Omit<PathsByNavitimeInput, 'departure' | 'arrival' | 'via'>
}

export const pathByNavitimeQuery = ({ coord, options }: PathByNavitimeQueryArgs) => {
  let goalTime = new Date()
  goalTime.setHours(9, 0, 0)

  const [departure, arrival, via] = coord
  return fetcher<PathsByNavitimeQuery, PathsByNavitimeQueryVariables>(PathsByNavitimeDocument, {
    input: {
      departure: { coord: departure },
      arrival: { coord: arrival },
      via: via ? { coord: via } : undefined,
      goalTime: toNaive(goalTime),
      ...options,
    },
  })()
}

export const applicationCreatePageSearchUserQuery = async (input: AutocompleteUsersInput) => {
  return await fetcher<
    ApplicationCreatePageSearchUserQuery,
    ApplicationCreatePageSearchUserQueryVariables
  >(ApplicationCreatePageSearchUserDocument, { input })()
}

export const exportApproverGroupQuery = async () => {
  return await fetcher<ExportApproverGroupsQuery, ExportApproverGroupsQueryVariables>(
    ExportApproverGroupsDocument,
    {}
  )()
}

export const divisionScopesByCharacterQuery = async (character: string) => {
  return await fetcher<DivisionScopesByCharacterQuery, DivisionScopesByCharacterQueryVariables>(
    DivisionScopesByCharacterDocument,
    { character }
  )()
}

export const applicationsForBatchSubmitAuditQuery = async (input: { groupIds: string[] }) => {
  return await fetcher<
    ApplicationsForBatchSubmitAuditQuery,
    ApplicationsForBatchSubmitAuditQueryVariables
  >(ApplicationsForBatchSubmitAuditDocument, { input })()
}

export const applicationCreatePageAuditApplicationFromInputsQuery = async (
  inputs: SaveApplicationInput[]
) => {
  return await fetcher<
    ApplicationCreatePageAuditApplicationFromInputsQuery,
    ApplicationCreatePageAuditApplicationFromInputsQueryVariables
  >(ApplicationCreatePageAuditApplicationFromInputsDocument, { inputs })()
}

export const filterApplicantsByNameQuery = async (name: string) => {
  return await fetcher<FilterApplicantsByNameQuery, FilterApplicantsByNameQueryVariables>(
    FilterApplicantsByNameDocument,
    { name }
  )()
}

export const receiptBundleCardQuery = async (receiptUuid: string) => {
  return await fetcher<ReceiptBundleQuery, ReceiptBundleQueryVariables>(ReceiptBundleDocument, {
    receiptUuid,
  })()
}

export const filterReceiptsQuery = async (input: ReceiptsInput) => {
  return await fetcher<FilterReceiptsQuery, FilterReceiptsQueryVariables>(FilterReceiptsDocument, {
    input,
  })()
}

export const filterProxyUsersQuery = async (q: string) => {
  return await fetcher<ProxyUsersQuery, ProxyUsersQueryVariables>(ProxyUsersDocument, { q })()
}

export const currencyRateQuery = async (input: CurrencyRateInput) => {
  return await fetcher<CurrencyRateQuery, CurrencyRateQueryVariables>(CurrencyRateDocument, {
    input,
  })()
}

export const applicationCreatePresetQuery = async (input: BuildApplicationPresetInput) => {
  return await fetcher<ApplicationCreatePresetQuery, ApplicationCreatePresetQueryVariables>(
    ApplicationCreatePresetDocument,
    {
      input,
    }
  )()
}

export const auditApplicationFromInputsQuery = async (inputs: SaveApplicationInput) => {
  return await fetcher<
    ApplicationCreatePageAuditApplicationFromInputsQuery,
    ApplicationCreatePageAuditApplicationFromInputsQueryVariables
  >(ApplicationCreatePageAuditApplicationFromInputsDocument, {
    inputs,
  })()
}

export const exportDivisionCsvQuery = async () => {
  return await fetcher<ExportDivisionCsvQuery, ExportDivisionCsvQueryVariables>(
    ExportDivisionCsvDocument,
    {}
  )()
}

export const exportApplicationTypeCsvQuery = async () => {
  return await fetcher<ExportApplicationTypeCsvQuery, ExportApplicationTypeCsvQueryVariables>(
    ExportApplicationTypeCsvDocument,
    {}
  )()
}

export const exportUserCsvQuery = async () => {
  return await fetcher<ExportUserCsvQuery, ExportUserCsvQueryVariables>(ExportUserCsvDocument, {})()
}
