import ReactRouterPrompt from '@/components/_common/ReactRouterPrompt'
import { WarningDialog } from '@/components/dialog/WarningDialog'

export enum FormActionType {
  Create,
  Update,
}
export function NavigationInterceptDialog({
  when,
  formType,
}: {
  when: boolean
  formType?: FormActionType
}) {
  return (
    <ReactRouterPrompt when={when}>
      {({ isActive, onConfirm, onCancel }) => (
        <DataLostWarningDialog
          open={isActive}
          onConfirm={onConfirm}
          onCancel={onCancel}
          formType={formType}
        />
      )}
    </ReactRouterPrompt>
  )
}

interface DataLostWarningDialogProps {
  open: boolean
  onConfirm: () => void
  onCancel: () => void
  formType?: FormActionType
}

export function DataLostWarningDialog({
  open,
  onConfirm,
  onCancel,
  formType = FormActionType.Create,
}: DataLostWarningDialogProps) {
  const getLabel = () => {
    switch (formType) {
      case FormActionType.Create:
        return '入力された内容は破棄されます。'
      case FormActionType.Update:
        return '編集された内容は破棄されます。'
    }
  }
  return (
    <WarningDialog
      open={open}
      onCancel={onCancel}
      onConfirm={onConfirm}
      text={getLabel()}
      title={'このページから移動しますか？'}
    />
  )
}
