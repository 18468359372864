import type React from 'react'
import { Suspense, lazy } from 'react'

import type { RouteObject } from 'react-router-dom'
import { Navigate } from 'react-router-dom'

import { PresetLayout } from '@/components/PresetLayout'
import { useMultiTenantContext } from '@/contexts/Multitenant'
import { UserAdminSavePage, UserAdminCreatePage } from '@/pages/admin/UserAdminSavePage'
import { LoadingPage } from '@/pages/LoadingPage'
import UnauthorizedError from '@/pages/UnauthorizedError'

import DashboardLayout from './components/dashboard/DashboardLayout'

const Loadable = (Component: React.FC) => (props: any) =>
  (
    <Suspense fallback={<LoadingPage />}>
      <Component {...props} />
    </Suspense>
  )

// Commuting pages
const CommutingMonthly = Loadable(lazy(() => import('./pages/commuting/MonthlyPage')))
const CommutingDate = Loadable(lazy(() => import('./pages/commuting/DatePage')))
const CommutingEdit = Loadable(lazy(() => import('./pages/commuting/EditPage')))
const CommutingPastYearly = Loadable(lazy(() => import('./pages/commuting/past/YearlyPage')))
const CommutingPastMonthly = Loadable(lazy(() => import('./pages/commuting/past/MonthlyPage')))
const CommutingPastDaily = Loadable(lazy(() => import('./pages/commuting/past/DailyPage')))
const CommutingPastEdit = Loadable(lazy(() => import('./pages/commuting/past/EditPage')))

// Expense pages
const ExpenseList = Loadable(lazy(() => import('./pages/expense/ExpenseListPage')))
const ExpenseDetail = Loadable(lazy(() => import('./pages/expense/ExpenseDetailPage')))
const ExpenseCreate = Loadable(lazy(() => import('./pages/expense/ExpenseCreatePage')))
const ExpenseEdit = Loadable(lazy(() => import('./pages/expense/ExpenseEditPage')))
const ExpenseEditForApprover = Loadable(
  lazy(() => import('./pages/expense/ExpenseEditForApproverPage'))
)

const ExpenseDetailForApprover = Loadable(
  lazy(() => import('./pages/expense/ExpenseDetailForApproverPage'))
)

// Request pages
const RequestList = Loadable(lazy(() => import('./pages/request/RequestListPage')))
const RequestCreate = Loadable(lazy(() => import('./pages/request/RequestCreatePage')))
const RequestEdit = Loadable(lazy(() => import('./pages/request/RequestEditPage')))
const RequestEditForApprover = Loadable(
  lazy(() => import('./pages/request/RequestEditForApproverPage'))
)
const RequestDetail = Loadable(lazy(() => import('./pages/request/RequestDetailPage')))
const RequestDetailForApprover = Loadable(
  lazy(() => import('./pages/request/RequestDetailForApproverPage'))
)

// Approve pages
const ApproveCommutingListPage = Loadable(
  lazy(() => import('./pages/approve/ApproveCommutingListPage'))
)
const ApproveCommutingWeeklyListPage = Loadable(
  lazy(() => import('./pages/approve/ApproveAllowanceList/ApproveCommutingWeeklyListPage'))
)
const ApproveCommutingDetailPage = Loadable(
  lazy(() => import('./pages/approve/ApproveAllowanceList/ApproveCommutingDetailPage'))
)
const ApproveExpensePage = Loadable(lazy(() => import('./pages/approve/ApproveExpensePage')))
const ApproveRequestPage = Loadable(lazy(() => import('./pages/approve/ApproveRequestPage')))
const ApproveRoutePage = Loadable(lazy(() => import('./pages/approve/ApproveRoutesPage')))

// Routes pages
const RoutesList = Loadable(lazy(() => import('./pages/route/RoutesListPage')))
const RoutesOfficesAdd = Loadable(lazy(() => import('./pages/route/RoutesOfficesAddPage')))

// Payment pages
const PaymentList = Loadable(lazy(() => import('./pages/payment/PaymentListPage')))

// Receipt pages
const ReceiptListPage = Loadable(lazy(() => import('./pages/receipt/ReceiptListPage')))

// Activities pages
const ExpenseActivitiesPage = Loadable(lazy(() => import('./pages/activity/ExpenseActivitiesPage')))
const RequestActivitiesPage = Loadable(lazy(() => import('./pages/activity/RequestActivitiesPage')))
const ReceiptActivitiesPage = Loadable(lazy(() => import('./pages/activity/ReceiptActivitiesPage')))
const PaymentActivitiesPage = Loadable(lazy(() => import('./pages/activity/PaymentActivitiesPage')))
const PaymentActivityDetailPage = Loadable(
  lazy(() => import('./pages/activity/PaymentActivityDetailPage'))
)
const ExpenseActivityDetailPage = Loadable(
  lazy(() => import('./pages/activity/ExpenseActivitiesDetailPage'))
)
const RequestActivityDetailPage = Loadable(
  lazy(() => import('./pages/activity/RequestActivitiesDetailPage'))
)
const ReceiptActivityDetailPage = Loadable(
  lazy(() => import('./pages/activity/ReceiptActivitiesDetailPage'))
)
const ToppanRouteActivitiesPage = Loadable(
  lazy(() => import('./pages/activity/ToppanRouteActivitiesPage'))
)
const ToppanCommutingActivitiesPage = Loadable(
  lazy(() => import('./pages/activity/ToppanCommutingActivitiesPage'))
)

// Admin pages
const UserAdminPage = Loadable(lazy(() => import('./pages/admin/UserAdminPage')))
const ApplicationTypeAdminPage = Loadable(
  lazy(() => import('./pages/admin/ApplicationTypeAdminPage'))
)
const CustomFormAdminPage = Loadable(lazy(() => import('./pages/admin/CustomFormAdminPage')))
const CustomFormAdminCreatePage = Loadable(
  lazy(() => import('./pages/admin/CustomFormAdminCreatePage'))
)

const CustomFormAdminSavePage = Loadable(
  lazy(() => import('./pages/admin/CustomFormAdminSavePage'))
)
const CustomFormAdminPreviewPage = Loadable(
  lazy(() => import('./pages/admin/CustomFormAdminPreviewPage'))
)
const DivisionAdminPreviewPage = Loadable(lazy(() => import('./pages/admin/DivisionAdminPage')))

const ApproverGroupAdminPage = Loadable(lazy(() => import('./pages/admin/ApproverGroupAdminPage')))

const AuditRuleAdminPage = Loadable(lazy(() => import('./pages/admin/AuditRuleAdminPage')))

// Login pages
const Login = Loadable(lazy(() => import('./pages/Login')))

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')))
const NotFound = Loadable(lazy(() => import('./pages/NotFound')))
const ServerError = Loadable(lazy(() => import('./pages/ServerError')))

function DefaultPage() {
  const mt = useMultiTenantContext()
  if (!mt) return null
  const defaultPage = mt.config?.defaultPage
  return <Navigate to={defaultPage ?? '/expense'} replace />
}

const routes: RouteObject[] = [
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: '*',
    element: <PresetLayout />,
    children: [
      {
        path: '',
        element: <DefaultPage />,
      },
      {
        path: 'commuting',
        element: <DashboardLayout />,
        children: [
          {
            index: true,
            element: <CommutingMonthly />,
          },
          {
            path: ':date',
            element: <CommutingDate />,
          },
          {
            path: ':date/edit',
            element: <CommutingEdit />,
          },
          {
            path: 'past',
            children: [
              {
                path: ':year',
                element: <CommutingPastYearly />,
              },
              {
                path: ':year/:month',
                element: <CommutingPastMonthly />,
              },
              {
                path: ':year/:month/:date',
                element: <CommutingPastDaily />,
              },
              {
                path: ':year/:month/:date/edit',
                element: <CommutingPastEdit />,
              },
            ],
          },
        ],
      },
      {
        path: 'expense',
        element: <DashboardLayout />,
        children: [
          {
            index: true,
            element: <ExpenseList />,
          },
          {
            path: 'create',
            element: <ExpenseCreate />,
          },
          {
            path: ':id',
            element: <ExpenseDetail />,
          },
          {
            path: ':id/edit',
            element: <ExpenseEdit />,
          },
        ],
      },
      {
        path: 'request',
        element: <DashboardLayout />,
        children: [
          {
            index: true,
            element: <RequestList />,
          },
          {
            path: 'create',
            element: <RequestCreate />,
          },
          {
            path: ':id',
            element: <RequestDetail />,
          },
          {
            path: ':id/edit',
            element: <RequestEdit />,
          },
        ],
      },
      {
        path: 'approve',
        element: <DashboardLayout />,
        children: [
          {
            path: 'commuting',
            element: <ApproveCommutingListPage />,
          },
          {
            path: 'commuting/list/',
            element: <ApproveCommutingWeeklyListPage />,
          },
          {
            path: 'commuting/detail/:date',
            element: <ApproveCommutingDetailPage />,
          },
          {
            path: 'expense',
            element: <ApproveExpensePage />,
          },
          {
            path: 'expense/:id',
            element: <ExpenseDetailForApprover />,
          },
          {
            path: 'expense/:id/edit',
            element: <ExpenseEditForApprover />,
          },
          {
            path: 'request',
            element: <ApproveRequestPage />,
          },
          {
            path: 'request/:id',
            element: <RequestDetailForApprover />,
          },
          {
            path: 'request/:id/edit',
            element: <RequestEditForApprover />,
          },
          {
            path: 'route',
            element: <ApproveRoutePage />,
          },
        ],
      },
      {
        path: 'route',
        element: <DashboardLayout />,
        children: [
          {
            index: true,
            element: <RoutesList />,
          },
          {
            path: 'office/add',
            element: <RoutesOfficesAdd />,
          },
        ],
      },
      {
        path: 'payment',
        element: <DashboardLayout />,
        children: [
          {
            index: true,
            element: <PaymentList />,
          },
        ],
      },
      {
        path: 'receipt',
        element: <DashboardLayout />,
        children: [
          {
            index: true,
            element: <ReceiptListPage />,
          },
        ],
      },
      {
        path: 'activity',
        element: <DashboardLayout />,
        children: [
          {
            path: 'expense/:id',
            element: <ExpenseActivityDetailPage />,
          },
          {
            path: 'payment/:id',
            element: <PaymentActivityDetailPage />,
          },
          {
            path: 'request/:id',
            element: <RequestActivityDetailPage />,
          },
          {
            path: 'receipt/:id',
            element: <ReceiptActivityDetailPage />,
          },
          {
            path: 'expense',
            element: <ExpenseActivitiesPage />,
          },
          {
            path: 'request',
            element: <RequestActivitiesPage />,
          },
          {
            path: 'receipt',
            element: <ReceiptActivitiesPage />,
          },
          {
            path: 'payment',
            element: <PaymentActivitiesPage />,
          },
          {
            path: 'route',
            element: <ToppanRouteActivitiesPage />,
          },
          {
            path: 'commuting',
            element: <ToppanCommutingActivitiesPage />,
          },
        ],
      },
      {
        path: 'admin',
        element: <DashboardLayout />,
        children: [
          {
            path: 'user',
            element: <UserAdminPage />,
          },
          {
            path: 'user/create',
            element: <UserAdminCreatePage />,
          },
          {
            path: 'user/edit/:id',
            element: <UserAdminSavePage />,
          },
          {
            path: 'type',
            element: <ApplicationTypeAdminPage />,
          },
          {
            path: 'approvergroup',
            element: <ApproverGroupAdminPage />,
          },
          {
            path: 'customform',
            element: <CustomFormAdminPage />,
          },
          {
            path: 'customform/create',
            element: <CustomFormAdminCreatePage />,
          },
          {
            path: 'customform/edit',
            element: <CustomFormAdminSavePage />,
          },
          {
            path: 'customform/preview',
            element: <CustomFormAdminPreviewPage />,
          },
          {
            path: 'division',
            element: <DivisionAdminPreviewPage />,
          },
          {
            path: 'audit',
            element: <AuditRuleAdminPage />,
          },
        ],
      },
      {
        path: '*',
        element: <DashboardLayout />,
        children: [
          {
            path: '401',
            element: <AuthorizationRequired />,
          },
          {
            path: '404',
            element: <NotFound />,
          },
          {
            path: '500',
            element: <ServerError />,
          },
          {
            path: '*',
            element: <NotFound />,
          },
        ],
      },
    ],
  },
  {
    path: 'unauthorized',
    element: <UnauthorizedError />,
  },
]

export default routes
