import {
  extraErrorDataIntegration,
  captureConsoleIntegration,
  contextLinesIntegration,
  debugIntegration,
  httpClientIntegration,
  reportingObserverIntegration,
  rewriteFramesIntegration,
} from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/react'

import { sentryDSN } from '@/api/config'

export const initSentry = () => {
  const integrations = [
    new BrowserTracing(),
    extraErrorDataIntegration({ depth: 4 }),
    captureConsoleIntegration(),
    contextLinesIntegration(),
    debugIntegration(),
    httpClientIntegration(),
    reportingObserverIntegration(),
    rewriteFramesIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
      networkDetailAllowUrls: [window.location.origin],
    }),
  ]
  sentryDSN &&
    Sentry.init({
      dsn: sentryDSN,
      debug: process.env.STAGE !== 'production',
      environment: process.env.STAGE,
      sampleRate: 0.01,
      attachStacktrace: true,
      // @TODO:
      // - ignoreErrors
      // - ignoreTransactions

      autoSessionTracking: true,
      normalizeDepth: 4,
      integrations,
      tracesSampleRate: 0.01,
      replaysSessionSampleRate: 0.01, // Capture Replay for 1% of all sessions,
      replaysOnErrorSampleRate: 0.05, // Capture Replay for 5% of all errors
    })
}
